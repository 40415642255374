import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { DitaChildrenOptions } from '../dita.model';
import {
  ElementType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  getSemanticClassNameFN,
} from '@mono/shared';

@RegisterClass('box')
export class DitaBox extends Dita {
  readonly boxSemantic: string;
  getClassName: (target: SemanticStyleTarget) => string;
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaBox.xmlKey });

    this.boxSemantic = this.semantic || SEMANTIC_DEFAULT;
    this.getClassName = getSemanticClassNameFN(
      ElementType.BOX,
      this.boxSemantic
    );
  }

  static cast(data: Dita) {
    return data as unknown as DitaBox;
  }
}
