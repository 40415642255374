import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../dita';

@RegisterClass('object')
export class DitaObject extends Dita {
  readonly type?: string;

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, {
      ...options,
      key: DitaObject.xmlKey,
    });
    this.type = this.attr('type');
  }
}
