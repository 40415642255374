import { Injectable } from '@angular/core';
import { Dita } from '@mono/dita';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs';
import { findKeyObject } from '../../shared/ngrx-helper';
import { parseSectionSuccess } from '../section/section.actions';
import { SectionFacade } from '../section/section.facade';

import { SectionEntity } from '../section/section.models';
import * as TopicActions from './topic.actions';
import { TopicEntity } from './topic.models';

@Injectable()
export class TopicEffects {
  onParseSectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parseSectionSuccess),
      withLatestFrom(this.sectionFacade.allSection$),
      map(([{ data }, allSections]) => {
        const topics = data.Topic.map(
          (topicEntity) => new Dita(findKeyObject(topicEntity.json), {}, true)
        ).filter((ditaTopic) => ditaTopic.id);

        const topicEntities = topics.map((ditaTopic) =>
          this.createTopicEntity(ditaTopic, allSections, topics)
        );
        return TopicActions.upsertTopics({ topics: topicEntities });
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sectionFacade: SectionFacade
  ) {}

  private createTopicEntity(
    ditaTopic: Dita,
    allSections: SectionEntity[],
    allTopics: Dita[]
  ): TopicEntity {
    let parentSection: SectionEntity | undefined;
    const id = ditaTopic.id;
    if (id) {
      parentSection = allSections.find((section) =>
        section.childTopicIds?.includes(id)
      );
    }
    // search all topics of a specific type designated by the topic key.
    const topicsOfType = allTopics
      .filter((t) => t.key === ditaTopic.key)
      .filter((t) => t.numbering !== 'skip-numbering');
    // this data set must be put into the ngrx store
    const idxOfType = topicsOfType.indexOf(ditaTopic);
    return {
      id: ditaTopic.id,
      topic: ditaTopic,
      idxOfType,
      parentId: parentSection ? parentSection.id : undefined,
    };
  }
}
