import { TextObject, TEXT_KEY } from '@mono/smart-view-content';
import { Dita } from '../../dita';

/**
 * Helper class which represents any mathml. For a complete list of elements see:
 * https://developer.mozilla.org/en-US/docs/Web/MathML/Element
 *
 * Any class that extends this base class must only contain MathMl children.
 */
export class DitaMathMlElement extends Dita {
  /**
   * It removes `mathml:` prefix from tags and stitches text fragments together.
   *
   * In the Dita spec, all MathML element tags have a prefix. For browser
   * rendering, the prefix must be removed. SiteFusion uses `mathml:` as its
   * prefix.
   *
   * Dita spec:
   * https://www.oxygenxml.com/dita/1.3/specs/langRef/technicalContent/equation-block.html
   *
   * @returns correctly formatted mathml tree
   */
  override text(): string {
    if (this.cachedText) return this.cachedText;
    this.cachedText = this.allChildren()
      .map((child) => {
        if (child.isTextNode()) return (child.json as TextObject)[TEXT_KEY];
        if (child.key.includes('mathml:')) {
          const tag = child.key.replace('mathml:', '');
          return `<${tag}>${child.text()}</${tag}>`;
        }
        return child.text();
      })
      .join('');
    return this.cachedText;
  }

  /**
   * Maps all children to {@link DitaMathMlElement}.
   */
  override allChildren(): Dita[] {
    if (!this.cacheChildren) {
      // it is possible that all raw children returns undefined. We cancel the execution and return an empty array
      if (!Array.isArray(this.allRawChildren())) return [];
      this.cacheChildren = this.allRawChildren().map((childJson) => {
        return new DitaMathMlElement(
          childJson,
          {
            parent: this,
          },
          true
        );
      });
    }
    return this.cacheChildren;
  }
}
