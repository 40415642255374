import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaAttributeKeys } from '../../dita.model';

/**
 * There are two classed defined in this file due to auto sorting imports in the
 * index.ts file of the dita library. If dita-note and dita-speechbubble are
 */

@RegisterClass('note')
export class DitaNote extends Dita {
  static TEMPLATES = {
    NO_TEMPLATE: 0,
    SPEECH_BUBBLE: 1,
    THOUGHT_BUBBLE: 2,
  };
  static ATTRIBUTE_VALUES = {
    PROPS: {
      SPEECHBUBBLE: 'speechbubble',
      THOUGHTBUBBLE: 'thoughtbubble',
    },
    TYPE: {
      SPEECHBUBBLE: 'speechbubble',
    },
  };
  template: number;

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaNote.xmlKey });
    this.template = this.getTemplate();
  }

  getTemplate() {
    if (
      this.props === DitaNote.ATTRIBUTE_VALUES.PROPS.SPEECHBUBBLE &&
      this.attr(DitaAttributeKeys.TYPE) ===
        DitaNote.ATTRIBUTE_VALUES.TYPE.SPEECHBUBBLE
    )
      return DitaNote.TEMPLATES.SPEECH_BUBBLE;
    if (
      this.props === DitaNote.ATTRIBUTE_VALUES.PROPS.THOUGHTBUBBLE &&
      this.attr(DitaAttributeKeys.TYPE) ===
        DitaNote.ATTRIBUTE_VALUES.TYPE.SPEECHBUBBLE
    )
      return DitaNote.TEMPLATES.THOUGHT_BUBBLE;
    return DitaNote.TEMPLATES.NO_TEMPLATE;
  }

  static cast = (item: Dita): DitaNote => item as DitaNote;
}

@RegisterClass(DitaNote.xmlKey, {
  precondition: (item) =>
    item.attr(DitaAttributeKeys.PROPS) ===
      DitaNote.ATTRIBUTE_VALUES.PROPS.SPEECHBUBBLE &&
    item.attr(DitaAttributeKeys.TYPE) ===
      DitaNote.ATTRIBUTE_VALUES.TYPE.SPEECHBUBBLE,
})
export class DitaSpeechbubble extends DitaNote {
  static override cast = (item: Dita): DitaSpeechbubble =>
    item as DitaSpeechbubble;
}

@RegisterClass(DitaNote.xmlKey, {
  precondition: (item) =>
    item.attr(DitaAttributeKeys.PROPS) ===
      DitaNote.ATTRIBUTE_VALUES.PROPS.THOUGHTBUBBLE &&
    item.attr(DitaAttributeKeys.TYPE) ===
      DitaNote.ATTRIBUTE_VALUES.TYPE.SPEECHBUBBLE,
})
export class DitaThoughtbubble extends DitaNote {
  static override cast = (item: Dita): DitaThoughtbubble =>
    item as DitaThoughtbubble;
}
