export function shared(): string {
  return 'shared';
}

export function enumKeys<T extends Record<string, unknown>>(e: T): (keyof T)[] {
  return Object.keys(e) as (keyof T)[];
}

export function enumValues<T extends Record<string, unknown>>(
  e: T
): (keyof T)[] {
  return Object.values(e) as (keyof T)[];
}
