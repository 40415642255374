import { DitaDefaultAttributes } from '@mono/dita';

/**
 * @enum {string}
 * @property {string} Topicgroup ParentType is DitaMap
 * @property {string} Scroll ParentType is Topicgroup
 * @property {string} Segment ParentType is Scroll
 */
export enum DitaSectionType {
  Topicgroup = 'topicgroup',
  Scroll = 'scroll',
  Segment = 'segment',
}

export interface SectionEntity extends DitaDefaultAttributes {
  parentId: string; // Reference to DitaSectionType - ParentType definition.
  id: string; // Primary ID
  navtitle: string;
  childSectionIds: string[];
  childTopicIds?: string[];
  type: DitaSectionType;

  outputclass?: string;
  didactic?: string;
}
