import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
import { DitaData } from './dita-data';

@RegisterClass('topicmeta', {
  precondition: (i) => !!i.getFirstChildByClass(DitaData),
})
export class DitaTopicmetaData extends Dita {
  dataList: DitaData[];
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaTopicmetaData.xmlKey });

    this.dataList =
      this.getFirstChildByClass(DitaData)
        ?.getFirstChildByClass(DitaData)
        ?.childrenByClass<DitaData>(DitaData) ?? [];
  }

  static cast(data: Dita) {
    return data as DitaTopicmetaData;
  }
}
