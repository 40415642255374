<div *ngIf="icon.length > 0 || label.length > 0" class="box-top">
  <span
    class="box-label"
    [ngClass]="data.getClassName(SemanticStyleTarget.LABEL)"
    *ngIf="label.length > 0"
    >{{ label }}</span
  >
</div>
<div
  class="box container"
  [ngClass]="data.getClassName(SemanticStyleTarget.CONTENT)"
  :id="{{ data.id }}"
>
  <picture *ngIf="icon.length > 0">
    <source [srcset]="getSrcSet('webp')" type="image/webp" />
    <img [src]="srcUrl()" alt="" />
  </picture>
  <h4 *ngIf="data.getTitle() !== undefined">{{ data.getTitle() }}</h4>
  <mono-generic-body [data]="data"></mono-generic-body>
</div>
