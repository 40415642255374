import { Component, HostListener, Input } from '@angular/core';
import { DitaImage } from '@mono/dita';
import {
  buildAwsResourceUrl,
  buildAwsWebPResourceUrl,
  getThumborParams,
} from '../../../../../../shared/util/cloudfront/buildAwsResourceUrl';
import { OverlayFacade } from '../../../../../../user-interface/overlay/ngrx/overlay.facade';

@Component({
  selector: 'mono-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() image!: DitaImage;
  // res set from https://kunden.orangefluid.de/smartview/styleguide/?ce=image#
  // resolution is in px
  resolutions = [1440, 780, 323];

  constructor(private readonly overlayFacade: OverlayFacade) {}

  srcUrl() {
    return buildAwsResourceUrl(this.image.getResourceName());
  }

  getSrcSet(format: string | undefined = undefined, quality = 80): string {
    return this.resolutions
      .map(
        (res) =>
          `${buildAwsResourceUrl(
            ...getThumborParams(format, quality, res),
            this.image.getResourceName()
          )} ${res}w`
      )
      .join(',');
  }

  getHeight() {
    if (this.isImagegroup() && !this.isImagegroupDivFigWithWidth())
      return undefined;
    return this.image.aspectRatio
      ? `calc(100% / ${this.image.aspectRatio})`
      : 'auto';
  }

  @HostListener('click')
  onClick() {
    if (!this.image.isClickable) return;
    const src = buildAwsWebPResourceUrl(this.image.getResourceName());
    return this.overlayFacade.openImageViewer(src, '');
  }

  // Imagegroup
  isImagegroup(): boolean {
    return !!this.image.getFig().imageGroup;
  }

  isImagegroupDivFigWithWidth() {
    return !!this.image.getFig().ditaDivFig?.width;
  }
}
