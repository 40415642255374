import { DitaP } from '../../paragraph/dita-p';

import { DitaLineCounterDiv } from './dita-line-counter-div';
import { Dita } from '../../../dita';
import { RegisterClass } from '@mono/smart-view-content';

@RegisterClass<Dita>(DitaP.xmlKey, {
  precondition: (i) =>
    i.parent?.props === DitaLineCounterDiv.PROPS_VALUES.LINE_NUMBERING,
})
export class DitaLineCounterP extends DitaP {
  static readonly OTHERPROPS_VALUES = {
    FORCEBREAK: 'forcebreak',
  };
}
