import { buildUrl, hosts } from '@mono/shared';
import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita, KEY_DitaTitle } from '../dita';
import { DitaChildrenOptions } from '../dita.model';
import { DitaFig } from './dita-fig';
import { DitaDT } from './lists';

export const IMPORTANT_PROPS = {
  HREF: 'href',
};

enum IMAGE_PROPS {
  HEIGHT = 'height',
  WIDTH = 'width',
  ASPECT_RATIO = 'aspectratio',
}

@RegisterClass<Dita>('image', {
  precondition: (i) =>
    !!i.attr(IMPORTANT_PROPS.HREF) && !!i.otherprops && !!i.product,
})
export class DitaImage extends Dita {
  readonly href: string;
  readonly height: number;
  readonly width: number;
  readonly aspectRatio: number;
  readonly isClickable: boolean;
  readonly isDecoration: boolean;
  static ATTRIBUTE_VALUES = {
    PRODUCT: {
      CONTENT_RELEVANT: 'content-relevant',
      DECORATION: 'decoration',
    },
  };

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaImage.xmlKey });

    this.href = this.attr(IMPORTANT_PROPS.HREF) as string;
    this.height = this.attrAsNumber(IMAGE_PROPS.HEIGHT);
    this.width = this.attrAsNumber(IMAGE_PROPS.WIDTH);
    this.aspectRatio = this.attrAsNumber(IMAGE_PROPS.ASPECT_RATIO);
    this.isClickable =
      this.product === DitaImage.ATTRIBUTE_VALUES.PRODUCT.CONTENT_RELEVANT;
    this.isDecoration =
      this.product === DitaImage.ATTRIBUTE_VALUES.PRODUCT.DECORATION;
  }

  getFig() {
    const fig = this.parent as DitaFig;
    if (fig.key !== DitaFig.xmlKey)
      console.error('The parent of Image is not a Figure!', fig.json);
    return fig;
  }

  getResourceName(): string {
    return this.href;
  }

  srcUrl(baseUrl: string) {
    return buildUrl(baseUrl, this.getResourceName());
  }

  getSrcSet(
    baseUrl: string,
    resolutions: number[],
    format: string | undefined = undefined,
    quality = 80
  ): string {
    return resolutions
      .map(
        (res) =>
          `${buildUrl(
            baseUrl,
            ...this.getThumborParams(format, quality, res),
            this.getResourceName()
          )} ${res}w`
      )
      .join(',');
  }

  /**
   * @remarks Generates parameters for Thumbor image service on aws. See Thumbor
   * docs for further manipulation options:
   * https://thumbor.readthedocs.io/en/latest/
   *
   * @param format any web compliant image format
   * @param quality number between 0 and 100.
   * @param resolution image width in pixels. Height is calculated according to
   * aspect ratio
   * @returns
   */
  getThumborParams(
    format: string | undefined,
    quality: number,
    resolution: number
  ) {
    const params = [
      this.setResolutionFilter(resolution),
      this.setQualityFilter(quality),
    ];
    if (format) params.push(this.setFormatFilter(format));
    return params;
  }

  /**
   * Returns resize URL param for thumbor image manipulation
   *
   * @remarks
   * Using 0 for one dimension enables proportional scaling
   * ref: https://thumbor.readthedocs.io/en/latest/usage.html#image-size
   *
   * @param resolution uniteless width
   * @returns "fit-in/${resolution}x0"
   */
  private setResolutionFilter(resolution: number | string) {
    return `fit-in/${resolution}x0`;
  }

  private setQualityFilter(quality: number | string) {
    return `filters:quality(${quality})`;
  }

  private setFormatFilter(format: string) {
    return `filters:format(${format})`;
  }

  // TODO LZ: This is a hack to make images inline
  // a different approach would be to refactor the text() method of dita.
  // It should be possible to the xml tag (like 'a', 'i', etc.) as an identifier in
  // generic body component which in turn renders plain html. That way we could
  // implement a inline-image component.
  override text() {
    const maxHeight = this.findFirstParent(
      (i) => i.key === KEY_DitaTitle || i.key === DitaDT.xmlKey
    )
      ? 1
      : 1.5;

    return `<img
    width="${this.width}"
    height="${this.height}"
    style="display: inline; max-height: ${maxHeight}em; width: auto;"
    src="${this.srcUrl(hosts.CLOUDFRONT)}" alt="${this.getResourceName()}" />`;
  }

  static cast(data: Dita) {
    return data as unknown as DitaImage;
  }
}
