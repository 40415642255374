import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Dita } from '@mono/dita';
import {
  ElementType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getSemanticClassName,
} from '@mono/shared';
import { Subscription } from 'rxjs';
import { SectionFacade } from '../../../ngrx/section/section.facade';
import { SectionEntity } from '../../../ngrx/section/section.models';

export type ElementNumberingType = 'alpha' | 'numeric';
export interface NumberDefinition {
  number: number;
  compsitNumber?: string;
}

export const NumberingClass = {
  COLOR_WHITE: 'color-white',
};

@Component({
  selector: 'mono-numbering',
  templateUrl: './numbering.component.html',
  styleUrls: ['./numbering.component.scss'],
})
export class NumberingComponent implements OnInit, OnDestroy {
  static readonly ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

  @Input() data!: Dita;
  @Input() isTopLevel = false;
  // strong defaults for optional properties
  @Input() numberingType: ElementNumberingType = 'numeric';
  @Input() forceNumber?: string | number = undefined;
  @Input() isHideNumber = false;
  @Input() addClasses: string[] = [];

  @Input() scroll?: SectionEntity;
  @Input() segment?: SectionEntity;

  subscriptions = new Subscription();

  number = -1;
  compositNumber = `${this.number}`;

  constructor(private readonly sectionFacade: SectionFacade) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.sectionFacade.selectedSection$.subscribe(
        (scroll) => (this.scroll = scroll)
      )
    );
    if (!this.isHideNumber) {
      // return empty string if numbering value is empty to leave counter empty but still increment index
      if (this.forceNumber !== undefined) this.setForceNumber(this.forceNumber);
      else {
        this.setNumber(this.getNumbering());
      }
    }
    if (this.compositNumber === '') this.addClasses = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scrollNumberingClass() {
    return getSemanticClassName(
      ElementType.SCROLL,
      this.scroll?.semantic || SEMANTIC_DEFAULT,
      VARIANT_DEFAULT,
      SemanticStyleTarget.NUMBERING,
      true
    );
  }
  segmentNumberingClass() {
    return getSemanticClassName(
      ElementType.SEGMENT,
      this.segment?.semantic || SEMANTIC_DEFAULT,
      this.segment?.variant || VARIANT_DEFAULT,
      SemanticStyleTarget.NUMBERING,
      true
    );
  }

  getAddClasses() {
    return [this.segmentNumberingClass(), ...this.addClasses];
  }

  shouldNumberBeDisplayed(): boolean {
    return !this.isHideNumber || this.number > 0;
  }

  setNumber({ number, compsitNumber }: NumberDefinition) {
    this.number = number;
    this.compositNumber = compsitNumber ?? '';
  }
  setForceNumber(compositNumber: string | number) {
    if (
      Number.isInteger(compositNumber) &&
      (compositNumber as unknown as number) < 1
    ) {
      this.number = -1;
      this.compositNumber = '';
    } else {
      this.number = 1; // dummy number to set number valid
      this.compositNumber = String(compositNumber);
    }
  }
  getNumbering(idx = this.data.getElementIndex()): NumberDefinition {
    if (idx === undefined || idx < 0) return { number: -1 };
    const number = idx + 1;
    if (this.numberingType === 'alpha') {
      return { number, compsitNumber: `${NumberingComponent.ALPHABET[idx]})` };
    }
    // append different suffix to numbers
    return { number, compsitNumber: `${number}.` };
  }
}
