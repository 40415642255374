import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../../../dita';

@RegisterClass('colspec')
export class DitaColspec extends Dita {
  readonly colname?: string;
  readonly colwidth?: number;
  readonly rowheader?: string;
  static readonly ATTRIBUTES = {
    BACKGROUND_COLOR: 'background-color',
    BORDER: 'border',
    COLNAME: 'colname',
    COLNUM: 'colnum',
    COLSEP: 'colsep',
    COL_WIDTH: 'colwidth',
    ROWSEP: 'rowsep',
    ROWHEADER: 'rowheader',
  };

  // TODO create properties for attributes which are filled at creation
  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaColspec.xmlKey });
    this.colname = this.attr(DitaColspec.ATTRIBUTES.COLNAME);
    this.rowheader = this.attr(DitaColspec.ATTRIBUTES.ROWHEADER);
    /**
     * colwidth values are suffixed with an asterisk which must be removed
     *
     * ref https://www.oxygenxml.com/doc/versions/25.1/ug-editor/topics/eppo-tables.html#eppo-tables__dlentry_m1y_bgk_54b
     */
    this.colwidth = Number(
      this.attr(DitaColspec.ATTRIBUTES.COL_WIDTH)?.replace('*', '')
    );
  }
}
