import { Component, Input, OnInit } from '@angular/core';
import {
  DitaLcOpenQuestion2,
  DitaLcQuestion2,
  DitaLceExplanation,
} from '@mono/dita';
import { SHOW_EXPLANATION } from './lc-open-question2-show-explanation.hack';

enum ExplanationButtonPrompt {
  visible = '-',
  hidden = '+',
}

@Component({
  selector: 'mono-lc-open-question2',
  templateUrl: './lc-open-question2.component.html',
  styleUrls: ['./lc-open-question2.component.scss'],
})
export class LcOpenQuestion2Component implements OnInit {
  DitaLcQuestion2 = DitaLcQuestion2;
  DitaLceExplanation = DitaLceExplanation;
  @Input() lcOpenQuestion2!: DitaLcOpenQuestion2;
  @Input() difficultySetIconId?: string;
  showExplanation = false;
  buttonPrompt = ExplanationButtonPrompt.hidden;

  ngOnInit() {
    if (!(this.lcOpenQuestion2.id in SHOW_EXPLANATION))
      SHOW_EXPLANATION[this.lcOpenQuestion2.id] = false;
    this.showExplanation = SHOW_EXPLANATION[this.lcOpenQuestion2.id];
  }

  toggleExplanation() {
    SHOW_EXPLANATION[this.lcOpenQuestion2.id] = !this.showExplanation;
    this.buttonPrompt = this.showExplanation
      ? ExplanationButtonPrompt.visible
      : ExplanationButtonPrompt.hidden;
  }
}
