import { Component, Input } from '@angular/core';
import { FloatTemplate } from '@mono/dita';

@Component({
  selector: 'mono-float',
  templateUrl: './float.component.html',
  styleUrls: ['./float.component.scss'],
})
export class FloatComponent {
  @Input() data!: FloatTemplate;
}
