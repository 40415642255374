<ol [style.counterReset]="counter" monoShowDataClass [dataClass]="data">
  <ng-container *ngFor="let item of data.allChildren(); index as idx">
    <ng-container [ngSwitch]="item.otherprops">
      <ng-container
        *ngSwitchCase="DitaLineCounterP.OTHERPROPS_VALUES.FORCEBREAK"
      >
        <br />
        <li monoShowDataClass [dataClass]="item">
          <span [innerHtml]="item.text()"></span>
        </li>
      </ng-container>
      <li *ngSwitchDefault monoShowDataClass [dataClass]="item">
        <span [innerHtml]="item.text()"></span>
      </li>
    </ng-container>
  </ng-container>
</ol>
