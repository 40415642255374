<div class="tab-titles">
  <span
    class="tab-title"
    *ngFor="let child of children; let i = index"
    (click)="selectTab(i)"
    [ngClass]="tabTitleClasses(i, child.addClasses)"
  >
    <ng-container *ngTemplateOutlet="child.titleReference"></ng-container>
  </span>
</div>
<div
  class="tab-content"
  *ngFor="let child of children; let i = index"
  [ngClass]="{ active: i === activeTab }"
  [ngClass]="tabClasses(i)"
  [ngStyle]="getContentStyle(i)"
>
  <ng-container *ngTemplateOutlet="child.contentReference"> </ng-container>
</div>
