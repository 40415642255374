import { Component, Input, OnInit, inject } from '@angular/core';

import {
  Dita,
  DitaAttributeValues,
  DitaBox,
  DitaDL,
  DitaDiv,
  DitaDivTemplate,
  DitaEquationBlock,
  DitaEquationFigure,
  DitaFig,
  DitaForeignText,
  DitaLcInteraction,
  DitaLcIntro,
  DitaLearningAssessment,
  DitaLearningAssessmentBody,
  DitaLearningContent,
  DitaLearningContentBody,
  DitaLearningOverview,
  DitaLearningOverviewBody,
  DitaLineCounterDiv,
  DitaMathMl,
  DitaNote,
  DitaOL,
  DitaObject,
  DitaP,
  DitaTable,
  DitaTitle,
  DitaUL,
} from '@mono/dita';
import { Observable } from 'rxjs';
import { DebugFacade } from '../../../../../debug/ngrx/debug.facade';
import { SectionEntity } from '../../../../../ngrx/section/section.models';

@Component({
  selector: 'mono-generic-body',
  templateUrl: './generic-body.component.html',
  styleUrls: ['./generic-body.component.scss'],
})
export class GenericBodyComponent implements OnInit {
  DitaP = DitaP;
  DitaDiv = DitaDiv;
  DitaFig = DitaFig;
  DitaBox = DitaBox;
  DitaForeignText = DitaForeignText;
  DitaUL = DitaUL;
  DitaOL = DitaOL;
  DitaMathMl = DitaMathMl;
  DitaDL = DitaDL;
  DitaEquationBlock = DitaEquationBlock;
  DitaEquationFigure = DitaEquationFigure;
  DitaLineCounterDiv = DitaLineCounterDiv;
  DitaTable = DitaTable;
  DitaNote = DitaNote;
  DitaLearningAssessmentBody = DitaLearningAssessmentBody;
  DitaLearningAssessment = DitaLearningAssessment;
  DitaLcInteraction = DitaLcInteraction;
  DitaLcIntro = DitaLcIntro;
  DitaTitle = DitaTitle;
  DitaLearningContentBody = DitaLearningContentBody;
  DitaLearningContent = DitaLearningContent;
  DitaLearningOverview = DitaLearningOverview;
  DitaLearningOverviewBody = DitaLearningOverviewBody;
  @Input() data!: Dita | Dita[];
  @Input() isTopLevel = false;
  @Input() numbering: string | number | undefined = undefined;
  @Input() withFlex = true;
  @Input() segment!: SectionEntity;
  buttons!: DitaObject[];
  isShowUnrecognizedItemsActive$!: Observable<boolean>;

  private readonly debugFacade = inject(DebugFacade);

  ngOnInit(): void {
    this.isShowUnrecognizedItemsActive$ =
      this.debugFacade.selectDebugUnrecognizedItemsGenericBody$;
    if (!this.isTopLevel) {
      return;
    }
    this.buttons = Array.isArray(this.data)
      ? this.data
          .map((element) =>
            element.findChildren<DitaObject>((i) => i.key === DitaObject.xmlKey)
          )
          .flat()
      : this.data.findChildren<DitaObject>((i) => i.key === DitaObject.xmlKey);
  }

  // TODO maybe extract into a setter. That way, all input can be transformed
  // into an array. This would be more consistent
  getElements(): Dita[] {
    if (Array.isArray(this.data)) {
      return this.data;
    }
    return this.data.allChildren();
  }

  isHeroImage(element: Dita) {
    if (element instanceof DitaDiv) {
      return (
        element.key === DitaDiv.xmlKey &&
        element.getDivTemplate() === DitaDivTemplate.HERO
      );
    }
    return false;
  }

  /**
   * Duplicated logic from element-numbering directive. Due to recent requirement
   * changes, the directive is deprecated and will be removed in the future. We
   * have to find a way to accomodate the frontend according to  the new
   * requirements.
   * @returns
   */
  getNumbering(element: Dita) {
    if (element.numbering === DitaAttributeValues.NUMBERING.MANUAL_NUMBERING) {
      return element.numberingValue;
    }
    return Number(this.numbering) + 1;
  }
}
