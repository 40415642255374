import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DitaSectionType, SectionEntity } from './section.models';
import {
  SECTION_FEATURE_KEY,
  SectionState,
  sectionAdapter,
} from './section.reducer';
import { Dita } from '@mono/dita';

// --- Default entity selector ---

// Lookup the 'Topicgroup' feature state managed by NgRx
export const getSectionState =
  createFeatureSelector<SectionState>(SECTION_FEATURE_KEY);

const { selectAll, selectEntities } = sectionAdapter.getSelectors();

export const getSectionLoaded = createSelector(
  getSectionState,
  (state: SectionState) => state.loaded
);

export const getSectionError = createSelector(
  getSectionState,
  (state: SectionState) => state.error
);

export const getAllSections = createSelector(
  getSectionState,
  (state: SectionState) => selectAll(state)
);

export const getSectionEntities = createSelector(
  getSectionState,
  (state: SectionState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getSectionState,
  (state: SectionState) => state.selectedId
);

// Shoud be the Scroll element
export const getSelected = createSelector(
  getSectionEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

/**
 * Selected sections are always of type Scroll. To get the respective Segments,
 * an additional selector is necessary
 * @returns {Array<SectionEntity>} SectionEntities
 */
export const getSelectedSegments = createSelector(
  getSectionEntities,
  getSelected,
  (entities, selected) => {
    if (!selected) return undefined;
    return selected.childSectionIds
      .filter((id) => id)
      .map((id) => entities[id])
      .filter((entity): entity is SectionEntity => !!entity);
  }
);

function getFirstTopicgroup(
  selected: SectionEntity,
  entities: Dictionary<SectionEntity>
): SectionEntity | undefined {
  if (selected.type === DitaSectionType.Topicgroup) return selected;
  const parent = entities[selected.parentId];
  if (parent) return getFirstTopicgroup(parent, entities);
  return;
}

export const getSelectedTopicgroup = createSelector(
  getSectionEntities,
  getSelected,
  (entities, selected) => {
    if (!selected) return;
    return getFirstTopicgroup(selected, entities);
  }
);

export const getScrollSectionHeading = createSelector(
  getAllSections,
  getSelected,
  (allSections, selectedSection) => {
    if (!selectedSection?.navtitle) {
      const parentSection = allSections.find(
        (section) => section.id === selectedSection?.parentId
      );
      if (!parentSection) {
        return undefined;
      }
      return parentSection.navtitle;
    }
    return selectedSection.navtitle;
  }
);

// --- Advanced entity selector - helper functions ---

function recursiveFindRootElement(
  currentSection: SectionEntity | undefined,
  entities: Dictionary<SectionEntity>
): SectionEntity | undefined {
  if (currentSection?.parentId) {
    if (currentSection.parentId in entities) {
      return recursiveFindRootElement(
        entities[currentSection.parentId],
        entities
      );
    } else {
      return currentSection;
    }
  } else {
    return undefined;
  }
}
function recursiveFindAllChildrenFromElement(
  element: SectionEntity | undefined,
  entities: Dictionary<SectionEntity>
): SectionEntity[] {
  let list: SectionEntity[] = [];
  if (element && element.childSectionIds && element.childSectionIds.length) {
    const allChildren = element.childSectionIds
      .map((id) => entities[id])
      .filter((entity) => entity)
      .map((entity) => entity as SectionEntity);
    for (const children of allChildren) {
      list.push(children);
      list = list.concat(
        recursiveFindAllChildrenFromElement(children, entities)
      );
    }
  }
  return list;
}

// --- Advanced entity selector ---

export const getAllChildElementsSortedFromSelected = createSelector(
  getSelected,
  getSectionEntities,
  (selected, entities) =>
    recursiveFindAllChildrenFromElement(selected, entities)
);

export const getAllSelectedChildTopicIdsSorted = createSelector(
  getSelected,
  getAllChildElementsSortedFromSelected,
  (selected, allChildrenFromSelected) => {
    if (!selected) return [];
    const topicIds: string[] = selected.childTopicIds
      ? [...selected.childTopicIds]
      : [];
    for (const section of allChildrenFromSelected) {
      if (section.childTopicIds !== undefined) {
        for (const topicId of section.childTopicIds) {
          if (topicId !== undefined) topicIds.push(topicId);
        }
      }
    }
    return topicIds;
  }
);

export const getRootSectionElementFromSelected = createSelector(
  getSelected,
  getSectionEntities,
  (selected, entities) => recursiveFindRootElement(selected, entities)
);

export const getAllElementsSortedOfSelectedRootElement = createSelector(
  getRootSectionElementFromSelected,
  getSectionEntities,
  (currentRootElement, entities) => {
    const allSegmentsFromRootDitaMap = Object.values(entities).filter(
      (segment) => segment?.parentId === currentRootElement?.parentId
    );
    let scrollElements: SectionEntity[] = [];
    for (const segment of allSegmentsFromRootDitaMap) {
      const childs = recursiveFindAllChildrenFromElement(segment, entities);
      scrollElements = scrollElements.concat(childs);
    }
    return scrollElements;
  }
);

export const getAllScrollElementsSortedOfSelectedRootElement = createSelector(
  getAllElementsSortedOfSelectedRootElement,
  (allElementsFromSelected) => {
    return allElementsFromSelected.filter(
      (element) => element.type === DitaSectionType.Scroll
    );
  }
);

export const getSectionByChildTopic = (topic: Dita) =>
  createSelector(getAllSections, (sections) =>
    sections.find((section) =>
      section.childTopicIds?.some((id) => id === topic.id)
    )
  );
