import { createAction, props } from '@ngrx/store';
import { actionNameBuilder } from '../../shared/ngrx-helper';
import { TopicEntity } from './topic.models';

const createActionName = actionNameBuilder('Topic');

export const upsertTopic = createAction(
  createActionName('Upsert Topic'),
  props<{ topic: TopicEntity }>()
);
export const upsertTopics = createAction(
  createActionName('Upsert Topics'),
  props<{ topics: TopicEntity[] }>()
);
