import { hosts } from '@mono/shared';

export const environment = {
  production: false,
  backend: {
    baseUrl: new URL(hosts.DEV_BACKEND),
  },
  cloudfront: {
    baseUrl: new URL(hosts.CLOUDFRONT),
  },
};
