import { FxpOutput, SmartViewContentOptions } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaTopicmeta } from './dita-topicmeta';

export abstract class DitaGenericSection extends Dita {
  static ATTR = {
    TOC: {
      KEY: 'toc',
      POSITIVE_VALUE: 'yes',
    },
  };

  topicmeta?: DitaTopicmeta;
  navTitle = '';
  toc: boolean;
  childSections: DitaGenericSection[] = [];

  constructor(
    json: FxpOutput,
    options: SmartViewContentOptions = {},
    replaceClass = false
  ) {
    super(json, {
      ...options,
      replaceClass,
    });
    this.topicmeta = this.getFirstChildByClass(DitaTopicmeta);
    this.navTitle = this.topicmeta?.navTitle ?? '';
    this.toc =
      this.attr(DitaGenericSection.ATTR.TOC.KEY) ===
      DitaGenericSection.ATTR.TOC.POSITIVE_VALUE;
  }

  getAllChildrenSections(): DitaGenericSection[] {
    const list = this.childSections
      .map((s) => s.getAllChildrenSections())
      .flat();
    return [...this.childSections, ...list];
  }
}
