import { Dita } from '../../../../dita';
import { DitaRow } from '../row/dita-row';
import {
  FxpOutput,
  SmartViewContentOptions,
  RegisterClass,
} from '@mono/smart-view-content';

@RegisterClass('thead')
export class DitaTHead extends Dita {
  readonly tableRows: DitaRow[];

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaTHead.xmlKey });
    this.tableRows = this.childrenByClass<DitaRow>(DitaRow);
  }
}
