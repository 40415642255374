<ng-container *ngFor="let item of data.entries">
  <dt
    monoShowDataClass
    [class]="data.props"
    [dataClass]="item.dt"
    [innerHTML]="item.dt.text() | safe : 'html'"
  ></dt>
  <dd [class]="data.props">
    <mono-generic-body
      [data]="item.dd"
      monoShowDataClass
      [dataClass]="item.dd"
    ></mono-generic-body>
  </dd>
</ng-container>
