import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DitaColspec, DitaEntry, DitaTGroup, DitaTable } from '@mono/dita';
import {
  ElementType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getSemanticClassName,
} from '@mono/shared';
@Component({
  selector: 'mono-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() data!: DitaTable;
  @ViewChild('wrapper') tableWrapper!: ElementRef<HTMLDivElement>;
  colspecs!: DitaColspec[];
  colWidths!: number[];

  ngOnInit(): void {
    this.colspecs = this.data
      .childrenByClass<DitaTGroup>(DitaTGroup)
      .map((tg) => tg.childrenByClass<DitaColspec>(DitaColspec))
      .flat();
    const filteredColspecWidths = this.colspecs
      .map((spec) => spec.colwidth)
      .filter(
        (colwidth): colwidth is number => !!colwidth || !Number.isNaN(colwidth)
      );
    const accumulatedColWidth = filteredColspecWidths.reduce(
      (acc, cur) => acc + cur,
      0
    );
    // values are used as precentages values and should not be fractional
    this.colWidths = filteredColspecWidths.map(
      (colwidth) => (colwidth / accumulatedColWidth) * 100
    );
  }

  ngAfterViewInit() {
    this.setBoxShadow();
  }

  getSemanticClass() {
    return getSemanticClassName(
      ElementType.TABLE,
      this.data.props ?? SEMANTIC_DEFAULT,
      VARIANT_DEFAULT,
      SemanticStyleTarget.CONTENT,
      true
    );
  }

  /**
   * There are differnt types of tables. Only the template table can have a
   * heading column. This method returns the classes for the table element.
   * Classes are derived from DITA XML spec for `@rowheader` attribute.
   * * https://www.oxygenxml.com/dita/1.3/specs/langRef/attributes/calsTableAttributes.html#oet-atts__rowheader
   *
   * @returns classes for table element
   */
  getTableClass() {
    if (
      this.data.otherprops === DitaTable.ATTRIBUTE_VALUES.OTHERPROPS.MATH_TABLE
    ) {
      if (this.data.tableGroup?.outputclass === 'none') {
        return 'mathtable no-raster';
      }
      return 'mathtable';
    }
    if (
      this.data.otherprops === DitaTable.ATTRIBUTE_VALUES.OTHERPROPS.TEMPLATE
    ) {
      if (
        this.data.rowheader === DitaTable.ATTRIBUTE_VALUES.ROWHEADER.FIRSTCOL
      ) {
        return 'template firstcol';
      }
      return 'template';
    }
    return '';
  }

  // has to be done at runtime because. Reverse search in the tree is not
  // possible in connstructors due to initiation taking place.
  getCellClass(cell: DitaEntry) {
    const colspec = this.colspecs.find((spec) => spec.colname === cell.colname);
    if (colspec?.rowheader === 'headers') {
      return 'header';
    }
    return '';
  }

  /**
   * Math tables do not support width definitions per cell. Every cell must be
   * the same width. See table.component.scss for more information on styling.
   * @param cell
   * @param idx
   * @returns width in percent or empty string which will be interpreted as no
   * defined width
   */
  getCellWidth(cell: DitaEntry, idx: number) {
    return cell.colspan ||
      this.data.otherprops === DitaTable.ATTRIBUTE_VALUES.OTHERPROPS.MATH_TABLE
      ? ''
      : this.colWidths[idx];
  }

  setBoxShadow() {
    // workaround because scrollLeftMax isn't a default property on HTML elements
    const scrollLeftMax =
      this.tableWrapper.nativeElement.scrollWidth -
      this.tableWrapper.nativeElement.clientWidth;
    if (!this.data.tableGroup?.isScrollable || scrollLeftMax === 0) {
      return;
    }
    switch (this.tableWrapper.nativeElement.scrollLeft) {
      case 0:
        this.tableWrapper.nativeElement.style.boxShadow =
          'inset grey -0.25em 0px 0.5em -0.5em';
        break;
      case scrollLeftMax:
        this.tableWrapper.nativeElement.style.boxShadow =
          'inset grey 0.25em 0px 0.5em -0.5em';
        break;
      default:
        this.tableWrapper.nativeElement.style.boxShadow =
          'inset grey -0.25em 0px 0.5em -0.5em, inset grey 0.25em 0px 0.5em -0.5em';
    }
  }

  onScroll() {
    this.setBoxShadow();
  }
}
