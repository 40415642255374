import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { DitaChildrenOptions } from '../dita.model';
import { DitaP } from './paragraph/dita-p';

@RegisterClass('desc')
export class DitaDesc extends Dita {
  parargraphs: DitaP[] = [];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDesc.xmlKey });
    this.parargraphs = this.children(DitaP.xmlKey).map((p) => DitaP.cast(p));
  }

  static cast(data: Dita) {
    return data as DitaDesc;
  }
}
