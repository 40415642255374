import { FxpOutput } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { RegisterClass } from '@mono/smart-view-content';
import { DitaChildrenOptions, DitaAttributeKeys } from '../dita.model';
import { DitaDiv, Templates } from './dita-div';
import { DitaDivFig } from './dita-div-fig';
import { DitaDivOverlay } from './dita-div-overlay';
import { DitaFig } from './dita-fig';
import { DitaImage } from './dita-image';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (i) => i.attr(DitaAttributeKeys.PROPS) === Templates.HERO,
})
export class DitaDivHero extends DitaDiv {
  divFig: DitaDivFig | undefined;
  fig: DitaFig | undefined;
  image: DitaImage | undefined;
  overlay: DitaDivOverlay | undefined;

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDivHero.xmlKey });

    this.divFig = this.children(DitaDivFig.xmlKey)
      .filter((child) => DitaDivFig.precondition(child))
      .map((child) => DitaDivFig.cast(child))
      .find((child) => child !== undefined);
    this.fig = this.divFig
      ?.children(DitaFig.xmlKey)
      .map((child) => DitaFig.cast(child))
      .find((fig) => fig != undefined);
    this.image = this.fig
      ?.children(DitaImage.xmlKey)
      .map((child) => DitaImage.cast(child))
      .find((img) => img != undefined);

    this.overlay = this.children(DitaDivOverlay.xmlKey)
      .filter((child) => DitaDivOverlay.precondition(child))
      .map((child) => DitaDivOverlay.cast(child))
      .find((child) => child !== undefined);
  }

  static override cast(data: Dita | DitaDiv) {
    return data as DitaDivHero;
  }
}
