import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('li')
export class DitaLI extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLI.xmlKey });
  }

  static cast(data: Dita) {
    return data as DitaLI;
  }
}
