import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('lcOpenAnswer2')
export class DitaLcOpenAnswer2 extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLcOpenAnswer2.xmlKey });
  }

  static cast(data: Dita) {
    return data as DitaLcOpenAnswer2;
  }
}
