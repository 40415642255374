import { ElementType } from '@mono/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CONFIG_FEATURE_KEY,
  ConfigState,
  configAdapter,
} from './config.reducer';

// Lookup the 'LoadConfig' feature state managed by NgRx
export const selectConfigState =
  createFeatureSelector<ConfigState>(CONFIG_FEATURE_KEY);

const { selectAll, selectEntities } = configAdapter.getSelectors();

export const selectAllConfig = createSelector(
  selectConfigState,
  (state: ConfigState) => selectAll(state)
);

export const selectConfigEntities = createSelector(
  selectConfigState,
  (state: ConfigState) => selectEntities(state)
);

export const selectSelectedId = createSelector(
  selectConfigState,
  (state: ConfigState) => state.selectedId
);

export const selectEntity = createSelector(
  selectConfigEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const selectEntitySemantic = createSelector(
  selectEntity,
  (entity) => entity?.json.semantic || []
);

export const selectEntitySemanticBoxes = createSelector(
  selectEntitySemantic,
  (semantic) => semantic?.filter((s) => s.element === ElementType.BOX)
);

export const selectEntitySemanticPhrases = createSelector(
  selectEntitySemantic,
  (semantic) => semantic?.filter((s) => s.element === ElementType.PHARSE)
);

export const selectCurrentSemanticSegments = createSelector(
  selectEntity,
  (currentConfig) => {
    return (
      currentConfig?.json.semantic.filter(
        (sem) => sem.element === ElementType.SEGMENT
      ) ?? []
    );
  }
);

export const selectDifficultyset = createSelector(
  selectEntity,
  (config) => config?.json.difficultyset ?? []
);

export const selectDifficultySystemType = createSelector(
  selectEntity,
  (config) => config?.json.difficultySystem
);
