import { FxpOutput } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { DitaChildrenOptions } from '../dita.model';
import { RegisterClass } from '@mono/smart-view-content';

@RegisterClass('lcInteraction')
export class DitaLcInteraction extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLcInteraction.xmlKey });
  }

  static cast(data: Dita) {
    return data as DitaLcInteraction;
  }
}
