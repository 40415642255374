<div
  class="fig container"
  :id="{{ data.id }}"
  [ngClass]="{ decoration: hideImageInMobileView() }"
  monoShowDataClass
  [dataClass]="data"
>
  <h6 *ngIf="data.getTitle() as title" [innerHTML]="title | safe : 'html'"></h6>
  <mono-image
    *ngFor="let img of data.getImages()"
    [id]="img.id"
    [image]="img"
  ></mono-image>
  <div *ngIf="!data.skipNumbering()" class="numbering">{{ index }}</div>
  <ng-container *ngIf="data.isCaptionAvailable">
    <figcaption
      *ngFor="let parargraph of data.desc?.parargraphs"
      [innerHTML]="parargraph.text() | safe : 'html'"
    ></figcaption>
  </ng-container>
</div>
