import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { defer } from 'rxjs';
import { PreviewEntity } from '../../preview/ngrx/preview.models';
import {
  GET_IMPORT,
  GET_IMPORTS_WITH_CONTENT,
  GET_TOPIC_BY_SF_ID,
} from './graphql.queries';

export interface ImportData {
  import: PreviewEntity;
}

@Injectable({
  providedIn: 'root',
})
export class GraphQlService {
  constructor(private readonly apollo: Apollo) {}

  getImport(exportID: string) {
    return this.apollo.watchQuery<ImportData>({
      query: GET_IMPORT,
      variables: { exportID },
    });
  }

  getImports() {
    return defer(() =>
      this.apollo.query<[]>({
        query: GET_IMPORTS_WITH_CONTENT,
      })
    );
  }

  getTopic(sf_id: string) {
    return defer(() =>
      this.apollo.query({
        query: GET_TOPIC_BY_SF_ID,
        variables: { sf_id },
      })
    );
  }
}
