import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigStyleElementService {
  private styleElement!: HTMLElement;
  set css(css: string) {
    this.styleElement.innerHTML = css;
  }
  get css() {
    return this.styleElement.innerHTML;
  }
  get styleId() {
    return this.styleElement.id;
  }
  constructor(@Inject(DOCUMENT) private document: Document) {
    const head = this.document.getElementsByTagName('head')[0];
    this.styleElement = this.document.createElement('style');
    this.styleElement.setAttribute('type', 'text/css');
    this.styleElement.id = 'config-css-style';
    head.appendChild(this.styleElement);
  }
}
