import { Component, Input, OnInit } from '@angular/core';
import {
  ElementType,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getSemanticClassName,
} from '@mono/shared';
import { Observable, map } from 'rxjs';
import {
  ConfigFacade,
  SemanticAndStyle,
} from '../../../../ngrx/config/config.facade';
import { SectionEntity } from '../../../../ngrx/section/section.models';
import { TopicFacade } from '../../../../ngrx/topic/topic.facade';

@Component({
  selector: 'dita-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnInit {
  @Input() segment!: SectionEntity;
  selectCurrentConfigSegment$!: Observable<SemanticAndStyle>;

  constructor(
    private readonly topicFacade: TopicFacade,
    private readonly configFacade: ConfigFacade
  ) {}

  ngOnInit(): void {
    this.selectCurrentConfigSegment$ = this.configFacade.getSemanticAndStyle(
      this.segment.semantic,
      this.segment.variant
    );
  }

  getTopicsBySegmentId(segmentId: string) {
    return this.topicFacade.selectedTopics$.pipe(
      map((topics) => topics.filter((topic) => topic.parentId === segmentId))
    );
  }

  getSegmentClass(name: string, variant?: string): string {
    return getSemanticClassName(
      ElementType.SEGMENT,
      name,
      variant ?? VARIANT_DEFAULT,
      SemanticStyleTarget.LABEL,
      true
    );
  }

  getHeadingId() {
    return `${this.segment.id}-heading`;
  }
}
