import { Component, Input, inject } from '@angular/core';
import {
  DitaLearningAssessment,
  DitaLearningAssessmentBody,
  DitaTitle,
} from '@mono/dita';
import { SectionFacade } from '../../../ngrx/section/section.facade';
import { SectionEntity } from '../../../ngrx/section/section.models';

@Component({
  selector: 'mono-learning-assessment',
  templateUrl: './learning-assessment.component.html',
  styleUrls: ['./learning-assessment.component.scss'],
})
export class LearningAssessmentComponent {
  DitaLearningAssessmentBody = DitaLearningAssessmentBody;
  DitaTitle = DitaTitle;
  private readonly sectionFacade = inject(SectionFacade);
  @Input() data!: DitaLearningAssessment;
  @Input() idxOfType = -1;
  @Input() difficultySetIconId?: string;
  @Input() segment!: SectionEntity;
}
