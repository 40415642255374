import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { chooseSection } from '../ngrx/section/section.actions';
import { SectionFacade } from '../ngrx/section/section.facade';
import { DitaMapFacade } from '../ngrx/dita-map/dita-map.facade';
import { updatePageTitle } from './ngrx-router.actions';

const DEFAULT_TITLE = 'SmartView';

@Injectable()
export class NGRXRouterEffects {
  onChooseSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chooseSection),
      withLatestFrom(
        this.sectionFacade.getScrollSectionHeading$,
        this.ditaMapFacade.selectedDitaMap$
      ),
      map(([, sectionHeading, selectedDitaMap]) => {
        let title = DEFAULT_TITLE;
        if (sectionHeading) title = sectionHeading;
        else if (selectedDitaMap?.title && selectedDitaMap.title.length > 0)
          title = selectedDitaMap.title;
        return updatePageTitle({ title });
      })
    )
  );

  onUpdatePageTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updatePageTitle),
        filter(({ title }) => title !== undefined),
        tap(({ title }) => this.title.setTitle(title))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sectionFacade: SectionFacade,
    private readonly ditaMapFacade: DitaMapFacade,
    private readonly title: Title
  ) {}
}
