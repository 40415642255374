import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { OverlayFacade } from '../ngrx/overlay.facade';

export interface ImageViewerPayload {
  src: string;
  alt: string;
}

@Component({
  selector: 'mono-image-viewer',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent {
  loading = true;
  src!: string;
  alt!: string;

  constructor(private readonly overlayFacade: OverlayFacade) {}

  close() {
    this.overlayFacade.close();
  }

  @HostListener('window:keyup.escape')
  onEscape() {
    this.close();
  }

  @HostListener('window:mouseup')
  onMouseUp() {
    this.close();
  }

  onImageLoad() {
    this.loading = !this.loading;
  }

  getImageClass() {
    return this.loading ? 'loading' : 'loaded';
  }
}
