import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ditaMapActions } from './dita-map.actions';
import { DitaMapEntity } from './dita-map.models';
import * as DitaMapSelectors from './dita-map.selectors';

@Injectable()
export class DitaMapFacade {
  loaded$ = this.store.pipe(select(DitaMapSelectors.getDitaMapLoaded));
  allDitaMap$ = this.store.pipe(select(DitaMapSelectors.getAllDitaMap));
  selectedDitaMap$ = this.store.pipe(select(DitaMapSelectors.getSelected));

  constructor(private readonly store: Store) {}

  upsertDitaMap(ditaMap: DitaMapEntity) {
    this.store.dispatch(ditaMapActions.upsertDitaMap({ ditaMap }));
  }
  chooseDitaMap(ditaMapId: string) {
    this.store.dispatch(ditaMapActions.chooseDitaMap({ ditaMapId }));
  }
}
