import { Component, Input } from '@angular/core';
import { DitaTabularSpacingP } from '@mono/dita';

@Component({
  selector: 'mono-tabular-spacing',
  templateUrl: './tabular-spacing.component.html',
  styleUrls: ['./tabular-spacing.component.scss'],
})
export class TabularSpacingComponent {
  @Input() data!: DitaTabularSpacingP;
  DitaTabularSpacingP = DitaTabularSpacingP;
}
