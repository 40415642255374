import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../../dita';
import { DitaTBody } from './tbody/dita-tbody';
import { DitaTHead } from './thead/dita-thead';

@RegisterClass('tgroup')
export class DitaTGroup extends Dita {
  readonly tableHead?: DitaTHead;
  readonly tableBody?: DitaTBody;
  readonly outputclass: string | undefined;
  readonly isScrollable: boolean = false;
  static readonly ATTRIBUTES = {
    COLS: 'cols',
    OUTPUT_CLASS: 'outputclass',
  };
  static readonly ATTRIBUTE_VALUES = {
    PROPS: {
      SCROLLABLE: 'scrollable',
    },
  };

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaTGroup.xmlKey });
    this.tableHead = this.getFirstChildByClass<DitaTHead>(DitaTHead);
    this.tableBody = this.getFirstChildByClass<DitaTBody>(DitaTBody);
    this.outputclass = this.attr(DitaTGroup.ATTRIBUTES.OUTPUT_CLASS);
    this.isScrollable =
      this.props === DitaTGroup.ATTRIBUTE_VALUES.PROPS.SCROLLABLE;
  }
}
