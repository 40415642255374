import { createCssVar, enumValues } from '@mono/shared';
import { FxpOutput, SmartViewContentOptions } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DITA_TABLE_KEY, TableBorderValues } from './dita-table.models';

export abstract class DitaTableStyle extends Dita {
  static readonly ATTR_BACKGROUND = 'background-color';
  static readonly STYLE_ATTRIBUTES = {
    BACKGROUND_COLOR: DitaTableStyle.ATTR_BACKGROUND,
    BORDER: 'border',
    ALIGN: 'align',
    VALIGN: 'valign',
  };
  static readonly BORDER = {
    COLOR: '#686867',
    WIDTH: '1px',
  };
  readonly border: TableBorderValues;
  readonly background?: string;
  readonly style!: Record<string, string | undefined | null>;

  constructor(
    json: FxpOutput,
    options: SmartViewContentOptions = {},
    replaceClass = false
  ) {
    super(json, {
      ...options,
      replaceClass,
    });

    this.border =
      (enumValues(TableBorderValues).find(
        (val) => val === this.attr(DitaTableStyle.STYLE_ATTRIBUTES.BORDER)
      ) as TableBorderValues) ?? TableBorderValues.NONE;
    this.background = this.attr(DitaTableStyle.ATTR_BACKGROUND);

    this.style = {
      ...this.translateSFBorderToCSS(this.border),
      ...this.getBackgroundColor(),
      ...this.getAlignments(),
    };
  }

  getBackgroundColor() {
    if (
      !this.background ||
      this.background === 'ohne' ||
      this.background === 'none'
    )
      return {};
    return { 'background-color': createCssVar(this.background) };
  }

  translateSFBorderToCSS(border: TableBorderValues) {
    if (this.key === DITA_TABLE_KEY) return {};
    switch (border) {
      case TableBorderValues.ALL:
        return {
          border: `${DitaTableStyle.BORDER.WIDTH} solid ${DitaTableStyle.BORDER.COLOR}`,
        };
      case TableBorderValues.LEFT:
        return {
          'border-left': `${DitaTableStyle.BORDER.WIDTH} solid ${DitaTableStyle.BORDER.COLOR}`,
        };
      case TableBorderValues.RIGHT:
        return {
          'border-right': `${DitaTableStyle.BORDER.WIDTH} solid ${DitaTableStyle.BORDER.COLOR}`,
        };
      case TableBorderValues.TOP:
        return {
          'border-top': `${DitaTableStyle.BORDER.WIDTH} solid ${DitaTableStyle.BORDER.COLOR}`,
        };
      case TableBorderValues.BOTTOM:
        return {
          'border-bottom': `${DitaTableStyle.BORDER.WIDTH} solid ${DitaTableStyle.BORDER.COLOR}`,
        };
      case TableBorderValues.BOTTOM_DOUBLE:
        return {
          'border-bottom': `8px double ${DitaTableStyle.BORDER.COLOR}`,
        };
      default:
        return {};
    }
  }

  getAlignments() {
    const align = this.attr(DitaTableStyle.STYLE_ATTRIBUTES.ALIGN);
    const valign = this.attr(DitaTableStyle.STYLE_ATTRIBUTES.VALIGN);
    return {
      'text-align': align,
      'vertical-align': valign,
    };
  }
}
