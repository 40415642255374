import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as SectionActions from './section.actions';
import { SectionEntity } from './section.models';
import * as SectionSelectors from './section.selectors';
import { Dita } from '@mono/dita';

@Injectable()
export class SectionFacade {
  loaded$ = this.store.pipe(select(SectionSelectors.getSectionLoaded));
  allSection$ = this.store.pipe(select(SectionSelectors.getAllSections));
  selectedSection$ = this.store.pipe(select(SectionSelectors.getSelected));
  selectedSegments$ = this.store.pipe(
    select(SectionSelectors.getSelectedSegments)
  );
  getScrollSectionHeading$ = this.store.pipe(
    select(SectionSelectors.getScrollSectionHeading)
  );
  getSelectedTopicgroup$ = this.store.pipe(
    select(SectionSelectors.getSelectedTopicgroup)
  );
  getSectionByChildTopic$(topic: Dita) {
    return this.store.pipe(
      select(SectionSelectors.getSectionByChildTopic(topic))
    );
  }

  constructor(private readonly store: Store) {}

  chooseSection(sectionId: string) {
    this.store.dispatch(SectionActions.chooseSection({ sectionId }));
  }
  upsertSection(section: SectionEntity) {
    this.store.dispatch(SectionActions.upsertSection({ section }));
  }
  upsertSections(sections: SectionEntity[]) {
    this.store.dispatch(SectionActions.upsertSections({ sections }));
  }
}
