import {
  ATTRIBUTE_KEY,
  AttributeObject,
  ChildrenObject,
  FxpOutput,
  TEXT_KEY,
  TextObject,
} from './fxp-output.type';
import { SmartViewContentClassMap } from './smart-view-class-map';
import {
  CheckFunction,
  SVOnInit,
  SmartViewContentOptions,
} from './smart-view-content.models';

export class SmartViewContent implements SVOnInit {
  static readonly CLASS_MAP = SmartViewContentClassMap;
  readonly key: string;
  readonly parent?: SmartViewContent;
  static xmlKey = '_SmartViewDefaultXmlKey';
  static precondition: CheckFunction<SmartViewContent> = () => false;

  constructor(readonly json: FxpOutput, options: SmartViewContentOptions = {}) {
    this.key = options.key ? options.key : this.getKey(this.json);
    this.parent = options.parent;
    this.onInit();

    if (options.replaceClass && this.key in SmartViewContentClassMap) {
      const possibleClasses = SmartViewContentClassMap[this.key];

      const smartViewClassEntry = possibleClasses
        .filter((entry) => !entry.precondition || entry.precondition(this))
        .find((entry) => !!entry);
      if (possibleClasses.length > 0 && !smartViewClassEntry)
        console.warn(
          `There are ${possibleClasses.length} class-definition-entries but none of them fits to `,
          json
        );
      else if (smartViewClassEntry)
        return new smartViewClassEntry.class(json, {
          ...options,
          replaceClass: false,
        });
    }
  }
  onInit(): void {
    // Placeholder
  }

  private getKey(json: object = this.json): string {
    const jsonKeys = Object.keys(json);
    const attributeKeyIndex = jsonKeys.indexOf(ATTRIBUTE_KEY);
    let key: string | undefined = undefined;
    if (attributeKeyIndex === -1 && jsonKeys.length === 1) {
      key = jsonKeys[0];
    } else if (attributeKeyIndex === 0 && jsonKeys.length === 2) {
      key = jsonKeys[1];
    } else if (attributeKeyIndex === 1 && jsonKeys.length === 2) {
      key = jsonKeys[0];
    } else {
      throw new Error('No key in object: ' + JSON.stringify(json, null, 2));
    }
    if (key === ATTRIBUTE_KEY) {
      throw new Error(
        'The key can not be the same as the attribute key: ' + ATTRIBUTE_KEY
      );
    }
    return key;
  }

  protected allAttr(): { [key: string]: string } {
    return (this.json as AttributeObject)[ATTRIBUTE_KEY] || {};
  }
  attr(key: string): string | undefined {
    return this.allAttr()[key];
  }

  /**
   * Thin wrapper for Number() method
   * @param key Attribute key
   * @returns number if attribute can be converted to a number, else returns NaN
   */
  attrAsNumber(key: string): number {
    return Number(this.allAttr()[key]);
  }

  protected allRawChildren(): FxpOutput[] {
    return (this.json as ChildrenObject)[this.key] || [];
  }
  allChildren(): SmartViewContent[] {
    return this.allRawChildren().map(
      (child) =>
        new SmartViewContent(child, { parent: this, replaceClass: true })
    );
  }

  children<T extends SmartViewContent>(
    key: string | undefined
  ): SmartViewContent[] | T[] {
    return key ? this.allChildren().filter((child) => child.key === key) : [];
  }

  /**
   * Find first parent with the check-function (checkFN).
   * @param checkFN check function if true, return the parent, if false try to check the next parent
   * @returns first matching parent or undefined if no parent match the checkFN
   */
  findFirstParent<T extends SmartViewContent>(
    checkFN: CheckFunction<T>
  ): SmartViewContent | undefined {
    // ugly workaround to maintain genericness
    if (checkFN(this as unknown as T)) return this;
    else {
      if (this.parent === undefined) return undefined;
      else return this.parent.findFirstParent(checkFN);
    }
  }

  text(): string {
    const textChildren = this.children(TEXT_KEY);
    if (textChildren.length !== 1) {
      return '';
    } else {
      const textChild = textChildren[0];
      return textChild.getTextNode();
    }
  }

  isTextNode() {
    return this.key === TEXT_KEY;
  }
  getTextNode() {
    return (this.json as TextObject)[TEXT_KEY];
  }

  findFirstChild<T extends SmartViewContent>(
    checkFN: CheckFunction<T>
  ): undefined | T {
    const haystack = this.findChildren(checkFN);
    return haystack.length > 0 ? haystack[0] : undefined;
  }

  findChildren<T extends SmartViewContent>(
    checkFN: CheckFunction<T>,
    collector: T[] = []
  ) {
    for (const child of this.allChildren()) {
      if (checkFN(child as T)) {
        collector.push(child as T);
      }
      if (Array.isArray(child.allRawChildren()))
        child.findChildren(checkFN, collector);
    }
    return collector;
  }

  childrenByClass<T extends SmartViewContent>(
    classDef: typeof SmartViewContent
  ): T[] {
    const key = classDef.xmlKey;
    return key
      ? (this.allChildren().filter((child) => child.key === key) as T[])
      : [];
  }

  getFirstChildByClass<T extends SmartViewContent>(
    classDef: typeof SmartViewContent
  ): T | undefined {
    const children = this.childrenByClass<T>(classDef);
    if (children.length > 0) return children[0];
    else return undefined;
  }

  getFirstChild<T extends SmartViewContent>(
    key: string | undefined
  ): T | SmartViewContent | undefined {
    if (key == undefined) return undefined;
    const children = this.children<T>(key);
    if (children.length > 0) return children[0];
    else return undefined;
  }

  getFormatedJson() {
    return JSON.stringify(this.json, null, 2);
  }
}
