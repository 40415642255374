import { Component, Input } from '@angular/core';
import { DitaFig, DitaDivImagegroup, DitaP } from '@mono/dita';

@Component({
  selector: 'mono-imagegroup',
  templateUrl: './imagegroup.component.html',
  styleUrls: ['./imagegroup.component.scss'],
})
export class ImagegroupComponent {
  DitaFig = DitaFig;
  DitaP = DitaP;
  @Input() data!: DitaDivImagegroup;
}
