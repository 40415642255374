import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TOPIC_FEATURE_KEY, TopicState, topicAdapter } from './topic.reducer';
import * as SectionSelectors from '../section/section.selectors';
import { TopicEntity } from './topic.models';

// Lookup the 'Topic' feature state managed by NgRx
export const getTopicState =
  createFeatureSelector<TopicState>(TOPIC_FEATURE_KEY);

const { selectAll, selectEntities } = topicAdapter.getSelectors();

export const getTopicLoaded = createSelector(
  getTopicState,
  (state: TopicState) => state.loaded
);

export const getTopicError = createSelector(
  getTopicState,
  (state: TopicState) => state.error
);

export const getAllTopics = createSelector(getTopicState, (state: TopicState) =>
  selectAll(state)
);

export const getTopicEntities = createSelector(
  getTopicState,
  (state: TopicState) => selectEntities(state)
);

export const getSelectedTopics = createSelector(
  getTopicEntities,
  SectionSelectors.getAllSelectedChildTopicIdsSorted,
  (entities, selectedTopicIds): TopicEntity[] => {
    if (
      selectedTopicIds !== undefined &&
      Array.isArray(selectedTopicIds) &&
      selectedTopicIds.length > 0
    ) {
      return selectedTopicIds
        .map((topicId) => entities[topicId])
        .filter((topic) => topic !== undefined)
        .map((topic) => topic as TopicEntity); // workaround for typescript
    } else {
      return [];
    }
  }
);
