import { Component, Input } from '@angular/core';
import { DitaSection } from '@mono/dita';

@Component({
  selector: 'mono-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() data!: DitaSection;
}
