import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as NgrxRouterSelectors from './ngrx-router.selector';

@Injectable()
export class NgrxRouterFacade {
  private readonly store = inject(Store);

  fragment$ = this.store.pipe(select(NgrxRouterSelectors.selectFragment));
}
