<div class="slider-container">
  <div
    class="nav-button"
    matRipple
    [ngClass]="{ disabled: !previousInRange }"
    (click)="previous()"
    [attr.aria-lable]="getNavigationAriaLable(slideIndex - 1, slides.length)"
    [attr.aria-disabled]="!previousInRange"
    monoShowDataClass
    [dataClass]="data"
  >
    <mat-icon color="primary" fontIcon="chevron_left"></mat-icon>
  </div>
  <div class="slider">
    <div
      class="slides"
      monoSwipe
      (swipeRight)="next()"
      (swipeLeft)="previous()"
      [style.transform]="'translate3d(' + offset + '%, 0px, 0px)'"
      [style.width]="slidesContainerWidth"
    >
      <mono-slider-image
        #slideImage
        *ngFor="let slide of slides; index as i"
        [data]="slide"
        [forcedAspectRatio]="data.getForcedAspectRatio()"
        [slideFormat]="data.slideFormat"
        [style.width]="slideIncrements + '%'"
        [attr.aria-label]="getSlideAriaLable(i, slides.length)"
      >
      </mono-slider-image>
    </div>
  </div>
  <div
    class="nav-button"
    matRipple
    [ngClass]="{ disabled: !nextInRange }"
    (click)="next()"
    [attr.aria-lable]="getNavigationAriaLable(slideIndex + 1, slides.length)"
    [attr.aria-disabled]="!nextInRange"
  >
    <mat-icon color="primary" fontIcon="chevron_right"></mat-icon>
  </div>
</div>
<div class="pagination">
  <button
    *ngFor="let slide of slides; index as i"
    class="pagination-indicator"
    [ngClass]="{ active: slide.active }"
    (click)="setSlideIndexAndComputeValues(i)"
    [attr.aria-lable]="getNavigationAriaLable(i, slides.length)"
  ></button>
</div>
<div class="description" *ngFor="let child of data.allChildren()">
  <mono-generic-body
    *ngIf="child.attr('props') === 'desc'"
    [data]="child"
  ></mono-generic-body>
</div>
