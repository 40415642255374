export enum TableBorderValues {
  NONE = 'none',
  ALL = 'all',
  TOP = 'top',
  BOTTOM = 'bottom',
  BOTTOM_DOUBLE = 'bottom-double',
  RIGHT = 'right',
  LEFT = 'left',
}

export const DITA_TABLE_KEY = 'table';
