import { SmartViewContent } from './smart-view-content';

export interface SmartViewContentOptions {
  key?: string;
  parent?: SmartViewContent;
  replaceClass?: boolean; // If true, search classes from classes map and replace if there is a match. If necessary, override the method `postConstructor`, if this option is true.
}
export interface SVOnInit {
  onInit(): void;
}

export type CheckFunction<T extends SmartViewContent> = (item: T) => boolean;
