<div class="dita-segment-container">
  <dita-segment
    *ngFor="let segment of selectedSegments$ | async"
    [id]="segment.id"
    [segment]="segment"
    [ngClass]="getLayoutSegmentClass(segment.semantic, segment.variant)"
    monoShowDataClass
    [dataClass]="segment"
  >
  </dita-segment>
</div>
