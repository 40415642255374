import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('ul')
export class DitaUL extends Dita {
  static PROPS_VALUE = {
    CUSTOM1: 'custom1',
    CUSTOM2: 'custom2',
  };

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaUL.xmlKey });
  }

  static cast(data: Dita) {
    return data as DitaUL;
  }
}
