import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
import { DitaScroll } from './dita-scroll';
import { DitaGenericSection } from './dita-generic-section';

@RegisterClass('topicgroup')
export class DitaTopicgroup extends DitaGenericSection {
  static ATTR_OUTPUTCLASS = 'outputclass';
  scrollList: DitaScroll[];
  topicgroupList: DitaTopicgroup[];
  outputclass?: string;

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaTopicgroup.xmlKey });
    this.scrollList = this.childrenByClass(DitaScroll);
    this.topicgroupList = this.childrenByClass(DitaTopicgroup);
    this.outputclass = this.attr(DitaTopicgroup.ATTR_OUTPUTCLASS);
    this.childSections = [this.scrollList, this.topicgroupList].flat();
  }

  static cast(data: Dita) {
    return data as DitaTopicgroup;
  }
}
