import { Component, Input } from '@angular/core';
import { DitaDiv, DitaLcIntro } from '@mono/dita';

@Component({
  selector: 'mono-lc-intro',
  templateUrl: './lc-intro.component.html',
  styleUrls: ['./lc-intro.component.scss'],
})
export class LcIntroComponent {
  DitaLcIntro = DitaLcIntro;
  DitaDiv = DitaDiv;
  @Input() data!: DitaLcIntro;
}
