<div [id]="data.id" class="wrapper">
  <div class="assessment">
    <ng-container *ngFor="let element of data.allChildren()">
      <ng-container [ngSwitch]="element.key">
        <dita-topic-heading
          *ngSwitchCase="DitaTitle.xmlKey"
          [data]="element"
          [difficultySetIconId]="difficultySetIconId"
        ></dita-topic-heading>
        <mono-learning-assessment-body
          *ngSwitchCase="DitaLearningAssessmentBody.xmlKey"
          [learningAssessmentBody]="element"
          [segment]="segment"
        >
        </mono-learning-assessment-body>
      </ng-container>
    </ng-container>
  </div>
</div>
