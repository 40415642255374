<ng-container [ngSwitch]="data.key">
  <ul
    *ngSwitchCase="DitaUL.xmlKey"
    monoShowDataClass
    [dataClass]="data"
    [ngClass]="getUlClasses()"
  >
    <li
      mono-list-item
      *ngFor="let item of data.children(DitaLI.xmlKey)"
      [data]="item"
      monoShowDataClass
      [dataClass]="item"
      [ngClass]="getUlLiClass()"
    ></li>
  </ul>
  <ol
    *ngSwitchCase="DitaOL.xmlKey"
    monoShowDataClass
    [dataClass]="data"
    [ngClass]="data.props"
  >
    <li
      mono-list-item
      *ngFor="let item of data.children(DitaLI.xmlKey)"
      [data]="item"
      monoShowDataClass
      [dataClass]="item"
    ></li>
  </ol>
  <dl
    *ngSwitchCase="DitaDL.xmlKey"
    mono-dl-entry
    [data]="DitaDL.cast(data)"
    [class]="data.props"
    monoShowDataClass
    [dataClass]="data"
  ></dl>
</ng-container>
