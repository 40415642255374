export enum DitaDivTemplate {
  IMAGE_GROUP,
  SINGLE_COLUMN,
  TWO_COLUMN,
  FLOAT,
  SLIDER,
  HERO,
  NO_TEMPLATE,
}

export const DITA_DIV_KEY = 'div' as const;
