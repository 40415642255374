import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { SafePipeModule } from 'safe-pipe';
import { SwipeDirective } from '../shared/directives/swipe.directive';
import { ConfigStyleElementService } from './config-style-element.service';
import { ContentComponent } from './content.component';
import { BoxComponent } from './elements/box/box.component';
import { DivComponent } from './elements/dita/generic/div/div.component';
import { EquationBlockComponent } from './elements/dita/generic/equation-block/equation-block.component';
import { MathMlComponent } from './elements/dita/generic/equation-block/math-ml/math-ml.component';
import { EquationFigureComponent } from './elements/dita/generic/equation-figure/equation-figure.component';
import { FigComponent } from './elements/dita/generic/fig/fig.component';
import { ImageComponent } from './elements/dita/generic/fig/image/image.component';
import { ForeignTextComponent } from './elements/dita/generic/foreign-text/foreign-text.component';
import { GenericBodyComponent } from './elements/dita/generic/generic-body/generic-body.component';
import { LineCounterComponent } from './elements/dita/generic/line-counter/line-counter.component';
import { DLEntryComponent } from './elements/dita/generic/lists/dl-entry/dl-entry.component';
import { ListItemComponent } from './elements/dita/generic/lists/list-item/list-item.component';
import { ListsComponent } from './elements/dita/generic/lists/lists.component';
import { NoteComponent } from './elements/dita/generic/note/note.component';
import { SpeechbubbleComponent } from './elements/dita/generic/note/speechbubble/speechbubble.component';
import { ThoughtbubbleComponent } from './elements/dita/generic/note/thoughtbubble/thoughtbubble.component';
import { PComponent } from './elements/dita/generic/p/p.component';
import { TabularSpacingComponent } from './elements/dita/generic/p/tabular-spacing/tabular-spacing.component';
import { SectionComponent } from './elements/dita/generic/section/section.component';
import { TableComponent } from './elements/dita/generic/tables/table/table.component';
import { LcInteractionComponent } from './elements/dita/learning-assessment/lc-interaction/lc-interaction.component';
import { LcIntroComponent } from './elements/dita/learning-assessment/lc-intro/lc-intro.component';
import { LcOpenAnswer2Component } from './elements/dita/learning-assessment/lc-open-answer2/lc-open-answer2.component';
import { LcOpenQuestion2Component } from './elements/dita/learning-assessment/lc-open-question2/lc-open-question2.component';
import { LcQuestion2Component } from './elements/dita/learning-assessment/lc-question2/lc-question2.component';
import { LceExplanationComponent } from './elements/dita/learning-assessment/lce-explanation/lce-explanation.component';
import { LearningAssessmentBodyComponent } from './elements/dita/learning-assessment/learning-assessment-body/learning-assessment-body.component';
import { LearningContentBodyComponent } from './elements/dita/learning-content/body/body.component';
import { LearningOverviewBodyComponent } from './elements/dita/learning-overview/learning-overview-body/learning-overview-body.component';
import { LearningOverviewComponent } from './elements/dita/learning-overview/learning-overview.component';
import { SegmentContentDidacticVariantsComponent } from './elements/dita/segment/segment-content-didactic-variants/segment-content-didactic-variants.component';
import { SegmentContentComponent } from './elements/dita/segment/segment-content/segment-content.component';
import { SegmentComponent } from './elements/dita/segment/segment.component';
import { TopicHeadingComponent } from './elements/dita/topic/topic-heading/topic-heading.component';
import { ScrollSectionComponent } from './scroll-section/scroll-section.component';
import { DifficultyClassService } from './services/difficulty-class.service';
import { ShowDataClassDirective } from './show-data-class/show-data-class.directive';
import { FloatComponent } from './templates/float/float.component';
import { HeroImageComponent } from './templates/hero-image/hero-image.component';
import { ImagegroupComponent } from './templates/imagegroup/imagegroup.component';
import { NumberingComponent } from './templates/numbering/numbering.component';
import { SingleColumnComponent } from './templates/single-column/single-column.component';
import { SliderImageComponent } from './templates/slider/slider-image/slider-image.component';
import { SliderComponent } from './templates/slider/slider.component';
import { TabComponent } from './templates/tabs/tab/tab.component';
import { TabsComponent } from './templates/tabs/tabs.component';
import { TwoColumnsComponent } from './templates/two-columns/two-columns.component';
import { LearningAssessmentComponent } from './topic/learning-assessment/learning-assessment.component';
import { LearningContentComponent } from './topic/learning-content/learning-content.component';

@NgModule({
  declarations: [
    HeroImageComponent,
    ImagegroupComponent,
    ScrollSectionComponent,
    ContentComponent,
    TwoColumnsComponent,
    LearningContentComponent,
    LearningAssessmentComponent,
    FloatComponent,
    BoxComponent,
    FigComponent,
    SingleColumnComponent,
    LearningContentBodyComponent,
    SectionComponent,
    PComponent,
    DivComponent,
    LearningAssessmentBodyComponent,
    LcIntroComponent,
    LcInteractionComponent,
    LcOpenQuestion2Component,
    LcQuestion2Component,
    LcOpenAnswer2Component,
    LceExplanationComponent,
    LearningOverviewComponent,
    LearningOverviewBodyComponent,
    TopicHeadingComponent,
    GenericBodyComponent,
    SegmentComponent,
    SegmentContentComponent,
    SegmentContentDidacticVariantsComponent,
    ImageComponent,
    SliderComponent,
    SliderImageComponent,
    ForeignTextComponent,
    LineCounterComponent,
    TabularSpacingComponent,
    ListsComponent,
    EquationBlockComponent,
    MathMlComponent,
    EquationFigureComponent,
    TableComponent,
    DLEntryComponent,
    ListItemComponent,
    ShowDataClassDirective,
    NoteComponent,
    SpeechbubbleComponent,
    ThoughtbubbleComponent,
    TabsComponent,
    TabComponent,
    NumberingComponent,
  ],
  providers: [ConfigStyleElementService, DifficultyClassService],
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    SwipeDirective,
    SafePipeModule,
  ],
  exports: [ContentComponent],
  bootstrap: [ContentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentModule {}
