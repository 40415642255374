import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DITA_MAP_FEATURE_KEY,
  DitaMapState,
  ditaMapAdapter,
} from './dita-map.reducer';

// Lookup the 'DitaMap' feature state managed by NgRx
export const getDitaMapState =
  createFeatureSelector<DitaMapState>(DITA_MAP_FEATURE_KEY);

const { selectAll, selectEntities } = ditaMapAdapter.getSelectors();

export const getDitaMapLoaded = createSelector(
  getDitaMapState,
  (state: DitaMapState) => state.loaded
);

export const getDitaMapError = createSelector(
  getDitaMapState,
  (state: DitaMapState) => state.error
);

export const getAllDitaMap = createSelector(
  getDitaMapState,
  (state: DitaMapState) => selectAll(state)
);

export const getDitaMapEntities = createSelector(
  getDitaMapState,
  (state: DitaMapState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getDitaMapState,
  (state: DitaMapState) => state.selectedId
);

export const getSelected = createSelector(
  getDitaMapEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
