<ng-container [ngSwitch]="data.template">
  <mono-speechbubble
    *ngSwitchCase="DitaNote.TEMPLATES.SPEECH_BUBBLE"
    [data]="DitaSpeechbubble.cast(data)"
  ></mono-speechbubble>
  <mono-thoughtbubble
    *ngSwitchCase="DitaNote.TEMPLATES.THOUGHT_BUBBLE"
    [data]="DitaThoughtbubble.cast(data)"
  >
  </mono-thoughtbubble>
  <mono-generic-body *ngSwitchDefault [data]="data"></mono-generic-body>
</ng-container>
