export enum hosts {
  DEV_BACKEND = 'https://dev.backend.smartview.westermann.de',
  PROD_BACKEND = 'https://backend.smartview.westermann.de',
  DEV_FRONTEND = 'https://dev.smartview.westermann.de',
  PROD_FRONTEND = 'https://smartview.westermann.de',
  CLOUDFRONT = 'https://d8jhvfbgen8im.cloudfront.net',

  API_GATEWAY_LOCAL = 'http://127.0.0.1:3334',
}

export enum backendPrefix {
  renderer = 'renderer',
  gateway = 'gateway',
}

enum apiGatewayVersions {
  v1 = 'v1',
}

export enum apiGatewayEndpoints {
  upload = 'upload',
  // GraphQL Gateway for federated endpoint
  graphQl = 'graph',
}

export enum rendererBackendEndpoints {
  graphQl = 'gql',
}

export const routes = {
  backend: {
    apiGateway: {
      uploadV1: buildUrl(
        backendPrefix.gateway,
        apiGatewayVersions.v1,
        apiGatewayEndpoints.upload
      ),
      upload: buildUrl(backendPrefix.gateway, apiGatewayEndpoints.upload),
      graphQl: buildUrl(backendPrefix.gateway, apiGatewayEndpoints.graphQl),
    },
    rendererBackend: {
      graphQl: buildUrl(
        backendPrefix.renderer,
        rendererBackendEndpoints.graphQl
      ),
    },
  },
};

export function buildUrl(...args: string[]) {
  return args.join('/');
}
