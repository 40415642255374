import { FxpOutput } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { RegisterClass } from '@mono/smart-view-content';
import { DitaChildrenOptions, DitaAttributeKeys } from '../dita.model';
import { DitaDiv, Templates } from './dita-div';
import { DitaDivDesc } from './dita-div-desc';
import { DitaDivGroup } from './dita-div-group';
import { DitaFig } from './dita-fig';

export const propsDescCondition = 'desc';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (item) =>
    item.attr(DitaAttributeKeys.PROPS) === Templates.IMAGE_GROUP,
})
export class DitaDivImagegroup extends DitaDiv {
  readonly divGroups: DitaDivGroup[];
  readonly descriptions: DitaDivDesc[];
  readonly figs: DitaFig[];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDivImagegroup.xmlKey });
    this.divGroups = this.children(DitaDivGroup.xmlKey)
      .filter((divGroup) => DitaDivGroup.precondition(divGroup))
      .map((divGroup) => DitaDivGroup.cast(divGroup));
    this.figs = this.divGroups
      .map((divGroup) => divGroup.divFigs.map((divFigs) => divFigs.figs).flat())
      .flat();
    this.descriptions = this.allChildren()
      .filter((child) => DitaDivDesc.precondition(child))
      .map((desc) => DitaDivDesc.cast(desc));
  }

  static override cast(data: Dita | DitaDiv) {
    return data as DitaDivImagegroup;
  }
}
