import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DitaLceExplanation } from '@mono/dita';

@Component({
  selector: 'mono-lce-explanation',
  templateUrl: './lce-explanation.component.html',
  styleUrls: ['./lce-explanation.component.scss'],
})
export class LceExplanationComponent {
  @Input() lceExplanation!: DitaLceExplanation;
  @Output() closeExplanation: EventEmitter<any> = new EventEmitter();

  closeExplanationBox() {
    this.closeExplanation.emit();
  }
}
