import {
  ElementType,
  SEMANTIC_KEY,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
} from './config.models';

function classOutput(classes: string[], asString = true) {
  if (asString) return classes.join('-');
  return classes;
}

// SemanticClassName
export function getSemanticClassName(
  element: ElementType,
  name: string,
  variant: string,
  target: SemanticStyleTarget,
  asString: true
): string;
export function getSemanticClassName(
  element: ElementType,
  name: string,
  variant: string,
  target: SemanticStyleTarget,
  asString: false
): string[];
/**
 * Use for semantic classes the same class name generator for backend and frontend.
 * @param element The base element (config: semantic element)
 * @param name The name of semenatic element (config: semantic name)
 * @param target The Frontend Target
 * @param asString if true as string, else as string arrray
 * @returns if as String true return concatenated with - string, if false return as array.
 */
export function getSemanticClassName(
  element: ElementType,
  name: string,
  variant: string,
  target: SemanticStyleTarget,
  asString: boolean
) {
  const array = [
    SEMANTIC_KEY,
    element,
    variant || VARIANT_DEFAULT,
    name,
    target,
  ];
  return classOutput(array, asString);
}
export function getSemanticClassNameFN(
  element: ElementType,
  name: string,
  variant: string = VARIANT_DEFAULT
) {
  return (target: SemanticStyleTarget) =>
    getSemanticClassName(element, name, variant, target, true);
}

// Product colors
export function getProductColorsClass(
  layoutArea: string,
  asString: true
): string;
export function getProductColorsClass(
  layoutArea: string,
  asString: false
): string[];
export function getProductColorsClass(
  layoutArea: string,
  asString: boolean
): string | string[] {
  const array = ['product', 'colors', layoutArea];
  return classOutput(array, asString);
}

// Chapter Colors
export function getChapterColorsClass(
  outputclass: string,
  layoutArea: string,
  asString: true
): string;
export function getChapterColorsClass(
  outputclass: string,
  layoutArea: string,
  asString: false
): string[];
export function getChapterColorsClass(
  outputclass: string,
  layoutArea: string,
  asString: boolean
) {
  const array = ['chapter', 'colors', outputclass, layoutArea];
  return classOutput(array, asString);
}

// Global chapter colors
export function getGlobalChapterColorsClass(
  outputclass: string,
  asString: true
): string;
export function getGlobalChapterColorsClass(
  outputclass: string,
  asString: false
): string[];
export function getGlobalChapterColorsClass(
  outputclass: string,
  asString: boolean
) {
  const array = ['chapter', 'colors', 'global', outputclass];
  return classOutput(array, asString);
}

// Global difficulty classes
export function getDifficultyClass(name: string, asString: true): string;
export function getDifficultyClass(name: string, asString: false): string[];
export function getDifficultyClass(name: string, asString = true) {
  const array = ['difficulty', name];
  return classOutput(array, asString);
}

/**
 * When outputclass is defined return chapter class name, else return product class name.
 */
export function getProductOrChapterColorsClass(
  layoutArea: string,
  outputclass: string | undefined
) {
  if (outputclass === undefined) return getProductColorsClass(layoutArea, true);
  return getChapterColorsClass(outputclass, layoutArea, true);
}

export function createCssVar(name: string) {
  return `var(--${name})`;
}
