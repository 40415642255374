import { Injectable, OnDestroy } from '@angular/core';
import { Dita, DitaLearningAssessment } from '@mono/dita';
import {
  DifficultySet,
  DifficultySystem,
  getDifficultyClass,
} from '@mono/shared';
import { Subscription } from 'rxjs';
import { ConfigFacade } from '../../ngrx/config/config.facade';
import { NumberingClass } from '../templates/numbering/numbering.component';

@Injectable({
  providedIn: 'root',
})
export class DifficultyClassService implements OnDestroy {
  subscriptions$ = new Subscription();

  difficultySetList!: DifficultySet[];
  difficultySystem?: DifficultySystem;

  constructor(private readonly configFacade: ConfigFacade) {
    this.subscriptions$.add(
      this.configFacade.selectDifficultySystemType$.subscribe(
        (difficultySystem) => (this.difficultySystem = difficultySystem)
      )
    );
    this.subscriptions$.add(
      this.configFacade.selectDifficultyset$.subscribe(
        (set) => (this.difficultySetList = set)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  getDifficultySet(element: Dita): DifficultySet | undefined {
    const setName = element.attr(DitaLearningAssessment.ATTR_DIFFICULTY);
    return setName
      ? this.difficultySetList.find((d) => d.name === setName)
      : undefined;
  }

  getdifficultyClassesForElement(element: Dita): string[] {
    if (this.difficultySystem === DifficultySystem.ICON) return [];

    const classes: string[] = [];
    const difficultySet = this.getDifficultySet(element);
    if (difficultySet) {
      classes.push(getDifficultyClass(difficultySet.name, true));
      if (difficultySet.color) classes.push(NumberingClass.COLOR_WHITE);
    }

    return classes;
  }

  getDifficultySetIdItem(element: Dita) {
    if (this.difficultySystem !== DifficultySystem.ICON) return undefined;
    return this.getDifficultySet(element)?.iconId;
  }
}
