<span
  *ngFor="let ele of data.characters"
  [ngClass]="{
    narrow: DitaTabularSpacingP.isNarrowCharacter(ele),
    wide: DitaTabularSpacingP.isWideCharacter(ele),
  }"
  monoShowDataClass
  [dataClass]="data"
  >{{ ele }}</span
>
