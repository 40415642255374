import {
  ElementType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getSemanticClassName,
} from '@mono/shared';
import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../dita';

@RegisterClass('ph')
export class DitaPhrase extends Dita {
  static VAL_SEMANTIC = {
    BLANKS: 'blanks',
  };

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaPhrase.xmlKey });
  }

  // phrase text must not be empty
  override text(withClass = true): string {
    let text = super.text();
    if (this.semantic === DitaPhrase.VAL_SEMANTIC.BLANKS)
      text = text.replace(/./g, '_');
    // semantic attribute serves as css class names
    let cssClass = '';
    if (withClass) cssClass = this.getCssClass();
    return `<${this.key} ${cssClass}>${text}</${this.key}>`;
  }

  /**
   * Generates a css class matching the config structure. The class name
   * consists of three elements which are glued together by hyphens:
   * 1. Attribute key
   * 2. HTML element tag
   * 3. Attribute value
   *
   * @returns HTML class declaration
   */
  getCssClass() {
    const semantic = this.semantic;
    if (!semantic) return '';
    let className = this.getClassName(semantic);
    if (semantic === DitaPhrase.VAL_SEMANTIC.BLANKS)
      className += ' semantic-ph-blanks';
    return `class="${className}"`;
  }

  getClassName(semantic = this.semantic) {
    if (!semantic) return undefined;
    return getSemanticClassName(
      ElementType.PHARSE,
      semantic || SEMANTIC_DEFAULT,
      VARIANT_DEFAULT,
      SemanticStyleTarget.CONTENT,
      true
    );
  }
}
