import { FxpOutput } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { DitaChildrenOptions } from '../dita.model';
import { RegisterClass } from '@mono/smart-view-content';

@RegisterClass('section')
export class DitaSection extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaSection.xmlKey });
  }

  static cast(data: Dita) {
    return data as unknown as DitaSection;
  }
}
