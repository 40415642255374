// ESLint complained about a circular dependency: shared -> models -> shared
// this shouldn't be this bad. In the end, you could technically move
// the generated PrismaClient to another directory or project
/**
 * - libs/shared/src/lib/transformation/transformation.interfaces.ts
    - [
      libs/models/src/lib/database/dita.map.service.ts,
      libs/models/src/lib/database/dita.topic.service.ts
    ]  @nrwl/nx/enforce-module-boundaries
 */

export interface TransformationOutput {
  metadata: DocumentMetadata;
  json: Record<string, unknown>[];
}

export type SchemaKeyValues =
  | 'learningAssessment'
  | 'learningContent'
  | 'learningOverview'
  | 'map';

export interface DocumentMetadata {
  // SiteFusion ID
  id: string;
  // DITA schema
  schemaKey: SchemaKeyValues;
}

export interface KafkaConfigTransformationMessage {
  css: string;
  json: string;
}
