<mono-numbering
  *ngFor="let lcOpenQuestion2 of data.children(DitaLcOpenQuestion2.xmlKey)"
  [data]="lcOpenQuestion2"
  [numberingType]="'alpha'"
  [forceNumber]="getForceNumber(lcOpenQuestion2)"
  [segment]="segment"
  [isHideNumber]="data.children(DitaLcOpenQuestion2.xmlKey).length <= 1"
>
  <mono-lc-open-question2
    [lcOpenQuestion2]="DitaLcOpenQuestion2.cast(lcOpenQuestion2)"
    monoShowDataClass
    [dataClass]="lcOpenQuestion2"
    [difficultySetIconId]="
      difficultyClass.getDifficultySetIdItem(lcOpenQuestion2)
    "
  ></mono-lc-open-question2>
</mono-numbering>
