<mono-lc-question2
  *ngIf="lcOpenQuestion2.getFirstChild(DitaLcQuestion2.xmlKey) as question"
  [lcQuestion2]="DitaLcQuestion2.cast(question)"
  monoShowDataClass
  [dataClass]="question"
  [difficultySetIconId]="difficultySetIconId"
></mono-lc-question2>
<button
  *ngIf="lcOpenQuestion2.getFirstChild(DitaLceExplanation.xmlKey)"
  (click)="toggleExplanation()"
>
  {{ buttonPrompt }}
</button>
<mono-lce-explanation
  *ngIf="
    showExplanation &&
    lcOpenQuestion2.getFirstChild(DitaLceExplanation.xmlKey) as explanation
  "
  (closeExplanation)="toggleExplanation()"
  [lceExplanation]="DitaLceExplanation.cast(explanation)"
  monoShowDataClass
  [dataClass]="explanation"
></mono-lce-explanation>
