import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

enum SwipeDirection {
  LEFT,
  RIGHT,
  UP,
  DOWN,
}

@Directive({
  selector: '[monoSwipe]',
  standalone: true,
})
export class SwipeDirective {
  @Output() swipeLeft = new EventEmitter<SwipeDirection>();
  @Output() swipeRight = new EventEmitter<SwipeDirection>();
  touchStart!: TouchEvent;

  @HostListener('touchstart', ['$event'])
  onTouchstart($event: TouchEvent) {
    this.touchStart = $event;
  }

  @HostListener('touchmove', ['$event'])
  onTouchmove($event: TouchEvent) {
    this.isSwipe(this.touchStart, $event);
  }

  private isSwipe(start: TouchEvent, move: TouchEvent) {
    const { clientX: moveX } = move.touches[0];
    const { clientX: startX } = start.touches[0];
    if (moveX - startX <= -200) this.swipeRight.emit(SwipeDirection.RIGHT);
    if (moveX - startX >= 200) this.swipeLeft.emit(SwipeDirection.LEFT);
  }
}
