import * as fromSection from './section/section.reducer';
import * as fromDitaMap from './dita-map/dita-map.reducer';
import * as fromTopic from './topic/topic.reducer';
import * as fromConfig from './config/config.reducer';

export const ngrxRootState = {
  [fromSection.SECTION_FEATURE_KEY]: fromSection.sectionReducer,
  [fromTopic.TOPIC_FEATURE_KEY]: fromTopic.topicReducer,
  [fromDitaMap.DITA_MAP_FEATURE_KEY]: fromDitaMap.ditaMapReducer,
  [fromConfig.CONFIG_FEATURE_KEY]: fromConfig.configReducer,
};
