import { Component, Input, OnInit } from '@angular/core';
import { Dita, DitaDesc, DitaImage, SliderTemplate } from '@mono/dita';

export interface Slide {
  active: boolean;
  dita: Dita;
  desc?: DitaDesc;
  image: DitaImage;
}

@Component({
  selector: 'mono-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @Input() data!: SliderTemplate;
  slides!: Slide[];
  offset!: number;
  slideIncrements!: number;
  slidesContainerWidth!: string;
  nextInRange = true;
  previousInRange = false;
  _slideIndex = 0;
  set slideIndex(idx: number) {
    if (!this.slideIndexInRange(idx)) return;
    this._slideIndex = idx;
  }
  get slideIndex() {
    return this._slideIndex;
  }

  ngOnInit() {
    this.buildSlides();
    // initialize styles
    this.slideIncrements = 100 / this.slides.length;
    this.slidesContainerWidth = `${this.slides.length * 100}%`;
    this.offset = this.slideIndex * -this.slideIncrements;
  }

  next() {
    this.setSlideIndexAndComputeValues(this.slideIndex + 1);
  }

  previous() {
    this.setSlideIndexAndComputeValues(this.slideIndex - 1);
  }

  getSlideAriaLable(idx: number, arrayLength: number) {
    return `Slide ${idx + 1} von ${arrayLength}`;
  }

  getNavigationAriaLable(idx: number, arrayLength: number) {
    const baseOneIndex = idx + 1;
    if (baseOneIndex === arrayLength) {
      return 'Gehe zur letzten Slide';
    }
    if (baseOneIndex > arrayLength) {
      return 'Du bist bereits auf der letzten Slide';
    }
    if (baseOneIndex <= 0) {
      return 'Du bist bereits auf der ersten Slide';
    }
    if (baseOneIndex === 1) {
      return 'Gehe zur ersten Slide';
    }
    return `Gehe zur ${baseOneIndex}. Slide`;
  }

  /**
   * Computes multiple values based on the slide index:
   * - sets nextInRange and previousInRange properties
   * - shifts slides by updating translate3d property
   * @param idx slide index
   */
  setSlideIndexAndComputeValues(idx: number) {
    this.slideIndex = idx;
    this.slides = this.slides.map((slide, i) => {
      return {
        ...slide,
        active: i === this.slideIndex,
      };
    });
    this.offset = this.slideIndex * -this.slideIncrements;
    this.nextInRange = this.slideIndexInRange(this.slideIndex + 1);
    this.previousInRange = this.slideIndexInRange(this.slideIndex - 1);
  }

  private slideIndexInRange(index: number) {
    return !!this.slides[index];
  }

  private buildSlides() {
    const slds = this.data.slides;
    if (!slds) return;
    this.slides = slds.map((value, idx) => {
      return {
        ...value,
        active: idx === this.slideIndex,
      };
    });
  }
}
