import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'mono-tab',
  template: '',
})
export class TabComponent {
  @ContentChild('title', { read: TemplateRef })
  public titleReference!: TemplateRef<HTMLElement>;
  @ContentChild('content', { read: TemplateRef })
  public contentReference!: TemplateRef<HTMLElement>;

  @Input() addClasses: string[] = [];
  @Input() activeColor?: string;
}
