import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as TopicActions from './topic.actions';
import { TopicEntity } from './topic.models';

export const TOPIC_FEATURE_KEY = 'topic';

export interface TopicState extends EntityState<TopicEntity> {
  loaded: boolean; // has the Topic list been loaded
  error?: string | null; // last known error (if any)
}

export interface TopicPartialState {
  readonly [TOPIC_FEATURE_KEY]: TopicState;
}

export const topicAdapter: EntityAdapter<TopicEntity> =
  createEntityAdapter<TopicEntity>({
    selectId: (entity) => entity.id,
  });

export const initialTopicState: TopicState = topicAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const reducer = createReducer(
  initialTopicState,
  on(TopicActions.upsertTopic, (state, { topic }) =>
    topicAdapter.upsertOne(topic, {
      ...state,
      loaded: true,
    })
  ),
  on(TopicActions.upsertTopics, (state, { topics }) =>
    topicAdapter.upsertMany(topics, {
      ...state,
      loaded: true,
    })
  )
);

export function topicReducer(state: TopicState | undefined, action: Action) {
  return reducer(state, action);
}
