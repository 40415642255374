<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Preview Einstellungen"
>
  <mat-icon>settings</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="showUnrecognizedItemsMenu">
    Nicht erkannte Elemente anzeigen
  </button>
  <button mat-menu-item [matMenuTriggerFor]="showAttributeValuesMenu">
    Attributwerte Anzeigen
  </button>
</mat-menu>
<mat-menu #showUnrecognizedItemsMenu="matMenu">
  <button
    *ngFor="let item of unrecognizedMenuKeys"
    (click)="$event.stopPropagation()"
    mat-menu-item
  >
    <mat-checkbox
      [ariaDescription]="getDescription('unrecognizedItems', item)"
      (change)="onClickUnrecognizedItems($event)"
      [name]="item"
      >{{ item | titlecase }}</mat-checkbox
    >
  </button>
</mat-menu>

<mat-menu #showAttributeValuesMenu="matMenu">
  <button
    *ngFor="let item of attributeMenuKeys"
    (click)="$event.stopPropagation()"
    mat-menu-item
  >
    <mat-checkbox
      [ariaDescription]="getDescription('attributeValues', item)"
      (change)="onClickAttributeValues($event)"
      [name]="item"
    >
      {{ item | titlecase }}
    </mat-checkbox>
  </button>
</mat-menu>
