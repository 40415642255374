import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { createCssVar } from '@mono/shared';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'mono-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @ContentChildren(TabComponent)
  children!: QueryList<TabComponent>;

  @Input() tabsType? = 'default-tabs';

  activeTab = 0;

  selectTab(tabNr: number) {
    if (tabNr < this.children.length && tabNr >= 0) this.activeTab = tabNr;
  }

  tabClasses(index: number) {
    const classes = [];
    if (index === this.activeTab) classes.push('active');
    else classes.push('inactive-bg-color');
    return [this.tabsType, ...classes];
  }

  tabTitleClasses(index: number, addClasses: string[]) {
    const classes = [];
    const tab = this.children.get(index);

    if (index === this.activeTab) classes.push('active');
    else {
      classes.push('inactive');
      if (!tab || !tab.activeColor) classes.push('inactive-bg-color');
    }

    return [this.tabsType, ...classes, ...addClasses];
  }

  getContentStyle(index: number) {
    const tab = this.children.get(index);
    if (tab?.activeColor)
      return {
        'border-top-color': createCssVar(tab.activeColor),
        'border-bottom-color': createCssVar(tab.activeColor),
      };
    return {};
  }
}
