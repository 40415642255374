import { Component, Input } from '@angular/core';
import { DitaLcIntro, DitaLearningOverviewBody, DitaSection } from '@mono/dita';

@Component({
  selector: 'mono-learning-overview-body',
  templateUrl: './learning-overview-body.component.html',
  styleUrls: ['./learning-overview-body.component.scss'],
})
export class LearningOverviewBodyComponent {
  DitaSection = DitaSection;
  DitaLcIntro = DitaLcIntro;
  @Input() data!: DitaLearningOverviewBody;
}
