import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ditaMapActions } from './dita-map.actions';
import { DitaMapEntity } from './dita-map.models';

export const DITA_MAP_FEATURE_KEY = 'ditaMap';

export interface DitaMapState extends EntityState<DitaMapEntity> {
  selectedId?: string | number; // which DitaMap record has been selected
  loaded: boolean; // has the DitaMap list been loaded
  error?: string | null; // last known error (if any)
}

export interface DitaMapPartialState {
  readonly [DITA_MAP_FEATURE_KEY]: DitaMapState;
}

export const ditaMapAdapter: EntityAdapter<DitaMapEntity> =
  createEntityAdapter<DitaMapEntity>({
    selectId: (entity) => entity.id,
  });

export const initialDitaMapState: DitaMapState = ditaMapAdapter.getInitialState(
  {
    // set initial required properties
    loaded: false,
  }
);

const reducer = createReducer(
  initialDitaMapState,
  on(ditaMapActions.upsertDitaMap, (state, { ditaMap }) =>
    ditaMapAdapter.upsertOne(ditaMap, { ...state, loaded: true })
  ),
  on(ditaMapActions.chooseDitaMap, (state, { ditaMapId }) => {
    if (ditaMapId in state.entities) {
      return {
        ...state,
        selectedId: ditaMapId,
      };
    } else {
      return state;
    }
  }),
  on(ditaMapActions.error, (state, { msg }) => ({
    ...state,
    loaded: false,
    error: msg,
  }))
);

export function ditaMapReducer(
  state: DitaMapState | undefined,
  action: Action
) {
  return reducer(state, action);
}
