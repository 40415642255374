<div [ngSwitch]="data.getDivTemplate()" monoShowDataClass [dataClass]="data">
  <ng-container *ngSwitchCase="DitaDivTemplate.SINGLE_COLUMN">
    <mono-single-column [data]="data"></mono-single-column>
  </ng-container>
  <ng-container *ngSwitchCase="DitaDivTemplate.TWO_COLUMN">
    <mono-two-columns [data]="data"></mono-two-columns>
  </ng-container>
  <ng-container *ngSwitchCase="DitaDivTemplate.NO_TEMPLATE">
    <mono-generic-body [data]="data"></mono-generic-body>
  </ng-container>
  <ng-container *ngSwitchCase="DitaDivTemplate.FLOAT">
    <mono-float [data]="FloatTemplate.cast(data)"></mono-float>
  </ng-container>
  <ng-container *ngSwitchCase="DitaDivTemplate.SLIDER">
    <mono-slider [data]="SliderTemplate.cast(data)"></mono-slider>
  </ng-container>
  <ng-container *ngSwitchCase="DitaDivTemplate.IMAGE_GROUP">
    <mono-imagegroup [data]="DitaDivImagegroup.cast(data)"></mono-imagegroup>
  </ng-container>
  <ng-container *ngSwitchCase="DitaDivTemplate.HERO">
    <mono-hero-image [data]="DitaDivHero.cast(data)"></mono-hero-image>
  </ng-container>
</div>
