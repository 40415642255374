import { buildUrl } from '@mono/shared';
import { environment } from '../../../../environments/environment';

export function buildAwsResourceUrl(...params: string[]) {
  return buildUrl(environment.cloudfront.baseUrl.origin, ...params);
}

export function buildAwsWebPResourceUrl(resource: string, ...params: string[]) {
  return buildAwsResourceUrl(...params, setFormatFilter('webp'), resource);
}

export function getThumborParams(
  format: string | undefined,
  quality: number,
  resolution: number
) {
  const params = [setResolutionFilter(resolution), setQualityFilter(quality)];
  if (format) params.push(setFormatFilter(format));
  return params;
}

/**
 * Returns resize URL param for thumbor image manipulation
 *
 * @remarks
 * Using 0 for one dimension enables proportional scaling
 * ref: https://thumbor.readthedocs.io/en/latest/usage.html#image-size
 *
 * @param resolution uniteless width
 * @returns "fit-in/${resolution}x0"
 */
export function setResolutionFilter(resolution: number | string) {
  return `fit-in/${resolution}x0`;
}

export function setQualityFilter(quality: number | string) {
  return `filters:quality(${quality})`;
}

export function setFormatFilter(format: string) {
  return `filters:format(${format})`;
}
