import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { SEMANTIC_STYLE_DEFAULT, Semantic, SemanticStyles } from '@mono/shared';
import { Observable, filter, from, map, mergeMap } from 'rxjs';
import * as ConfigSelectors from './config.selectors';

export interface SemanticAndStyle {
  semantic: Semantic;
  style: SemanticStyles;
}

@Injectable()
export class ConfigFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  allConfig$ = this.store.pipe(select(ConfigSelectors.selectAllConfig));
  selectedConfig$ = this.store.pipe(select(ConfigSelectors.selectEntity));
  selectedConfigSemantic$ = this.store.pipe(
    select(ConfigSelectors.selectEntitySemantic)
  );
  selectedConfigSemanticBoxes$ = this.store.pipe(
    select(ConfigSelectors.selectEntitySemanticBoxes)
  );
  selectedConfigSemanticPhrases$ = this.store.pipe(
    select(ConfigSelectors.selectEntitySemanticPhrases)
  );
  selectCurrentSegments$ = this.store.pipe(
    select(ConfigSelectors.selectCurrentSemanticSegments)
  ) as Observable<Semantic[]>;
  selectDifficultyset$ = this.store.pipe(
    select(ConfigSelectors.selectDifficultyset)
  );

  selectDifficultySystemType$ = this.store.pipe(
    select(ConfigSelectors.selectDifficultySystemType)
  );

  getDifficultyEntry(name: string) {
    return this.selectDifficultyset$.pipe(
      mergeMap((difficultySet) => from(difficultySet)),
      filter((d) => d.name === name)
    );
  }

  getSmanticBoxWithName(name: string) {
    return this.selectedConfigSemanticBoxes$.pipe(
      map((boxes) => boxes?.filter((box) => box.name === name).find((b) => !!b))
    );
  }

  getSemanticAndStyle(
    semantic?: string,
    variant?: string
  ): Observable<SemanticAndStyle> {
    return this.selectCurrentSegments$.pipe(
      mergeMap((segments) => from(segments)),
      filter((segment) => segment.name === semantic),
      mergeMap((segment) =>
        from(segment.styles.map((style) => ({ semantic: segment, style })))
      ),
      filter(
        ({ style }) =>
          (!!variant && variant === style.variant) ||
          (!variant && style.variant === SEMANTIC_STYLE_DEFAULT)
      )
    );
  }
}
