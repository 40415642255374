import {
  UnaryFunction,
  Observable,
  pipe,
  filter,
  OperatorFunction,
} from 'rxjs';

export default function isDefined<T>(): UnaryFunction<
  Observable<T | null | undefined>,
  Observable<T>
> {
  return pipe(filter((x) => !!x) as OperatorFunction<T | null | undefined, T>);
}
