import { createActionGroup, props } from '@ngrx/store';
import { DebugState } from './debug.models';

export const debugActions = createActionGroup({
  source: 'Debug',
  events: {
    setState: props<{ state: DebugState }>(),
    setShowUnrecognizedItems: props<{
      unrecognizedItems: Partial<DebugState['unrecognizedItems']>;
    }>(),
    setShowAttributeValues: props<{
      showAttributeValues: Partial<DebugState['attributeValues']>;
    }>(),
  },
});
