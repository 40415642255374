import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../../dita';
import { DitaP } from '../dita-p';

@RegisterClass<Dita>(DitaP.xmlKey, {
  precondition: (i) => {
    return (
      i.props === DitaP.PROPS_VALUES.TABULAR_SPACING ||
      i.props === DitaP.PROPS_VALUES.MONOSPACEFONT
    );
  },
})
export class DitaTabularSpacingP extends DitaP {
  readonly characters: string[] | undefined;
  static readonly WIDE_CHARACTERS = /[+\-=≠:<>∙\u00B1]/;
  // symbol for the designated character that has to be interpreted as a space
  static readonly SPACE_CHARACTER = '_';

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, options);
    this.characters = this.getCharacterArray();
  }

  // protected narrow spaces shall be interpreted as such and need no padding
  static isNarrowCharacter(char: string) {
    return char === '\u202F';
  }

  static isWideCharacter(char: string) {
    return DitaTabularSpacingP.WIDE_CHARACTERS.test(char);
  }

  /**
   * @description This method extracts characters from the elements text
   * content. Returns an array of characters. Usually each index holds one
   * character. However some characters have to be combined:
   *  * Closing braces and separators must be combined with the prior character
   *  * Opening braces must be combined with the next character
   * @returns An array of characters
   */
  private getCharacterArray() {
    const text = this.text();
    if (!text) return;
    const chars = text.split('').filter((char) => char.indexOf(' ') !== 0);
    const mappedCharacters = [];
    // for loop because we have to skip some characters in the array
    for (let i = 0; i < chars.length; i++) {
      const c = chars[i];
      if (/[)\]},.]/.test(chars[i + 1])) {
        i++;
        mappedCharacters.push(`${this.getChar(c)}${this.getChar(chars[i])}`);
      } else if (/[({[]/.test(c)) {
        i++;
        mappedCharacters.push(`${c}${this.getChar(chars[i])}`);
      } else {
        mappedCharacters.push(this.getChar(c));
      }
    }
    return mappedCharacters;
  }

  private getChar(c: string) {
    if (c === DitaTabularSpacingP.SPACE_CHARACTER) return ' ';
    return c;
  }

  static override cast(data: Dita) {
    return data as DitaTabularSpacingP;
  }
}
