import { RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaMathMlElement } from './dita-math-ml-element';

@RegisterClass('mathml')
export class DitaMathMl extends DitaMathMlElement {
  static cast(data: Dita) {
    return data as DitaMathMl;
  }
}
