import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ImageViewerPayload } from '../image-viewer/image-viewer.component';

export const overlayActions = createActionGroup({
  source: '[UI] Overlay',
  events: {
    opened: emptyProps(),
    close: emptyProps(),
    closed: emptyProps(),
    closing: emptyProps(),
    openImageViewer: props<{ image: ImageViewerPayload }>(),
  },
});
