import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('topicmeta')
export class DitaTopicmeta extends Dita {
  static CHILD_NAVTITLE = 'navtitle';
  navTitle?: string;
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaTopicmeta.xmlKey });
  }

  override onInit(): void {
    super.onInit();
    this.navTitle = this.getFirstChild(DitaTopicmeta.CHILD_NAVTITLE)?.text();
  }

  static cast(data: Dita) {
    return data as DitaTopicmeta;
  }
}
