import { Component, Input, OnInit } from '@angular/core';
import {
  ElementType,
  LayoutType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getProductOrChapterColorsClass,
  getSemanticClassName,
} from '@mono/shared';
import { Observable } from 'rxjs';
import { SectionFacade } from '../../ngrx/section/section.facade';
import { SectionEntity } from '../../ngrx/section/section.models';
import isDefined from '../../shared/util/ngrx/isDefinedOperator';

@Component({
  selector: 'dita-scroll-section',
  templateUrl: './scroll-section.component.html',
  styleUrls: ['./scroll-section.component.scss'],
})
export class ScrollSectionComponent implements OnInit {
  LayoutType = LayoutType;
  getProductOrChapterColorsClass = getProductOrChapterColorsClass;

  @Input() outputclass?: string;
  selectedSegments$!: Observable<SectionEntity[]>;

  constructor(private readonly sectionFacade: SectionFacade) {}

  ngOnInit(): void {
    this.selectedSegments$ = this.sectionFacade.selectedSegments$.pipe(
      isDefined()
    );
  }

  getLayoutSegmentClass(
    semantic: string | undefined,
    variant: string | undefined
  ) {
    return getSemanticClassName(
      ElementType.SEGMENT,
      semantic || SEMANTIC_DEFAULT,
      variant || VARIANT_DEFAULT,
      SemanticStyleTarget.CONTENT,
      true
    );
  }
}
