import {
  FxpOutput,
  SVOnInit,
  SmartViewContent,
  SmartViewContentOptions,
  TEXT_KEY,
  TextObject,
} from '@mono/smart-view-content';
import { DitaAttributeKeys, DitaDefaultAttributes } from './dita.model';
import { DitaDiv } from './generic';
import { DITA_DIV_KEY, DitaDivTemplate } from './generic/dita-div-templates';

export const KEY_DitaTitle = 'title';
export const DITA_ATTR_ID = 'id';

export class Dita
  extends SmartViewContent
  implements SVOnInit, DitaDefaultAttributes
{
  static ATTR_ID = 'id';

  id = '';
  props?: string;
  otherprops?: string;
  semantic?: string;
  product?: string;
  variant?: string;
  numbering?: string;
  numberingValue?: string;
  override readonly parent?: Dita | undefined;
  protected cachedText: string | undefined = undefined;
  protected cacheChildren: Dita[] | undefined;
  private index?: number;

  constructor(
    json: FxpOutput,
    options: SmartViewContentOptions = {},
    replaceClass = false
  ) {
    super(json, {
      ...options,
      replaceClass,
    });
    this.onInit(); // Is required because otherwise the id is not set when initiating dita-map.effects.ts -> onLoadPreviewData$. Another way should be found in the future.
  }
  override onInit(): void {
    this[DitaAttributeKeys.PROPS] = this.attr(DitaAttributeKeys.PROPS);
    this[DitaAttributeKeys.OTHERPROPS] = this.attr(
      DitaAttributeKeys.OTHERPROPS
    );
    this[DitaAttributeKeys.SEMANTIC] = this.attr(DitaAttributeKeys.SEMANTIC);
    this[DitaAttributeKeys.PRODUCT] = this.attr(DitaAttributeKeys.PRODUCT);
    this[DitaAttributeKeys.VARIANT] = this.attr(DitaAttributeKeys.VARIANT);
    this.id = this.attr(Dita.ATTR_ID) ?? '';
    this.numbering = this.attr(DitaAttributeKeys.NUMBERING);
    this.numberingValue = this.attr(DitaAttributeKeys.NUMBERING_VALUE);
  }

  getTitle(key = KEY_DitaTitle): string | undefined {
    const titleChildren = this.children(key);
    if (titleChildren.length === 1) {
      const text = titleChildren[0].text();
      return text;
    } else {
      return undefined;
    }
  }

  getTitleElements() {
    return this.children(KEY_DitaTitle);
  }

  override allChildren(): Dita[] {
    if (!this.cacheChildren) {
      // it is possible that all raw children returns undefined. We cancel the execution and return an empty array
      if (!Array.isArray(this.allRawChildren())) return [];
      this.cacheChildren = this.allRawChildren().map((childJson) => {
        return new Dita(
          childJson,
          {
            parent: this,
          },
          true
        );
      });
    }
    return this.cacheChildren;
  }

  override children<T extends Dita>(key: string | undefined): Dita[] | T[] {
    return key ? this.allChildren().filter((child) => child.key === key) : [];
  }
  rawChildren(key: string | undefined) {
    return this.children(key);
  }

  /**
   * Stitches text fragments from formatted child elements together. Adds HTML
   * tags used for formatting.
   * @returns accumulated text from formatted elements.
   */
  override text(): string {
    // Text will never change once it has been cached because content is static
    if (this.cachedText) return this.cachedText;
    this.cachedText = this.allChildren()
      .map((child) => {
        if (child.isTextNode()) return (child.json as TextObject)[TEXT_KEY];
        if (child.needsHTMLTag())
          return `<${child.key}>${child.text()}</${child.key}>`;
        return child.text();
      })
      .join('')
      .replace('<?fontoxml-text-placeholder></?fontoxml-text-placeholder>', '');
    return this.cachedText;
  }

  /**
   * Elements not present in the dita class map should be wrapped in HTML tags.
   *
   * @returns `true` if the element needs surrounding HTML tags
   */
  protected needsHTMLTag() {
    return !Object.keys(SmartViewContent.CLASS_MAP).includes(this.key);
  }

  /**
   * Override methode and force Dita key to use it in Angular HTML.
   * @param key the key of DitaClass
   * @returns first child with same key or else undefined
   */
  override getFirstChild(key: string | undefined): Dita | undefined {
    return super.getFirstChild(key) as Dita;
  }

  /**
   * Returns the element's index if it has a parent element.
   *
   * Should only be called after instantiation of all elements (i.e. NOT in
   * constructor)
   *
   * @returns index of the element
   */
  getElementIndex() {
    if (this.index) return this.index;

    // don't count elements that have no parent
    // return -1 to mimick [].indexOf() if element is not found
    if (!this.parent || this.parent.allChildren().length <= 1) {
      return (this.index = -1);
    }
    this.parent
      ?.allChildren()
      .filter((ele) => ele.attr('numbering') !== 'skip-numbering')
      .forEach((ele, index) => {
        ele.index = index;
      });
    return this.index;
  }

  containsHeroImage() {
    return !!this.findFirstChild<DitaDiv>(
      (item) =>
        item.key === DITA_DIV_KEY &&
        item.getDivTemplate() === DitaDivTemplate.HERO
    );
  }
}
