import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('lceExplanation')
export class DitaLceExplanation extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLceExplanation.xmlKey });
  }
  static cast(data: Dita) {
    return data as DitaLceExplanation;
  }
}
