import { Component, Input } from '@angular/core';
import { DitaLearningOverview, DitaLearningOverviewBody } from '@mono/dita';

@Component({
  selector: 'mono-learning-overview',
  templateUrl: './learning-overview.component.html',
  styleUrls: ['./learning-overview.component.scss'],
})
export class LearningOverviewComponent {
  DitaLearningOverviewBody = DitaLearningOverviewBody;
  @Input() data!: DitaLearningOverview;
}
