import { Action, createReducer, on } from '@ngrx/store';
import { overlayActions } from './overlay.actions';

export const OVERLAY_FEATURE_KEY = 'overlay';

export enum OverlayStatus {
  OPENED,
  CLOSED,
  OPENING,
  CLOSING,
}
export interface OverlayState {
  overlayStatus: OverlayStatus;
}

export interface OverlayPartialState {
  readonly [OVERLAY_FEATURE_KEY]: OverlayState;
}

export const initialOverlayState: OverlayState = {
  overlayStatus: OverlayStatus.CLOSED,
};

const reducer = createReducer(
  initialOverlayState,
  on(overlayActions.opened, (state) => ({
    ...state,
    overlayStatus: OverlayStatus.OPENED,
  })),
  on(overlayActions.closed, (state) => ({
    ...state,
    overlayStatus: OverlayStatus.CLOSED,
  })),
  on(overlayActions.closing, (state) => ({
    ...state,
    overlayStatus: OverlayStatus.CLOSING,
  })),
  on(overlayActions.openImageViewer, (state) => ({
    ...state,
    overlayStatus: OverlayStatus.OPENING,
  }))
);

export function overlayReducer(
  state: OverlayState | undefined,
  action: Action
) {
  return reducer(state, action);
}
