import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { DebugComponent } from './debug.component';
import { DebugFacade } from './ngrx/debug.facade';
import * as fromDebug from './ngrx/debug.reducer';

@NgModule({
  declarations: [DebugComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    StoreModule.forFeature(fromDebug.DEBUG_FEATURE_KEY, fromDebug.debugReducer),
  ],
  providers: [DebugFacade],
  exports: [DebugComponent],
})
export class DebugModule {}
