import { Component, Input } from '@angular/core';
import { DitaEquationBlock } from '@mono/dita';

@Component({
  selector: 'mono-equation-block',
  templateUrl: './equation-block.component.html',
  styleUrls: ['./equation-block.component.scss'],
})
export class EquationBlockComponent {
  @Input() data!: DitaEquationBlock;
}
