import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('learningOverviewbody')
export class DitaLearningOverviewBody extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLearningOverviewBody.xmlKey });
  }

  static cast(data: Dita) {
    return data as DitaLearningOverviewBody;
  }
}
