import { Component, Input } from '@angular/core';
import {
  DitaDiv,
  DitaDivHero,
  DitaDivImagegroup,
  DitaDivTemplate,
  SliderTemplate,
  FloatTemplate,
} from '@mono/dita';

@Component({
  selector: 'mono-div',
  templateUrl: './div.component.html',
  styleUrls: ['./div.component.scss'],
})
export class DivComponent {
  DitaDiv = DitaDiv;
  FloatTemplate = FloatTemplate;
  SliderTemplate = SliderTemplate;
  DitaDivImagegroup = DitaDivImagegroup;
  DitaDivHero = DitaDivHero;
  DitaDivTemplate = DitaDivTemplate;
  @Input() data!: DitaDiv;
}
