import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaTableStyle } from './dita-table-style';
import { DITA_TABLE_KEY } from './dita-table.models';
import { DitaTGroup } from './tgroup/dita-tgroup';

@RegisterClass(DITA_TABLE_KEY)
export class DitaTable extends DitaTableStyle {
  readonly tableGroup: DitaTGroup | undefined;
  readonly rowheader: string | undefined;
  static readonly ATTRIBUTE_KEYS = {
    ROWHEADER: 'rowheader',
  };
  static readonly ATTRIBUTE_VALUES = {
    OTHERPROPS: {
      TEMPLATE: 'template',
      MATH_TABLE: 'mathtable',
    },
    ROWHEADER: {
      FIRSTCOL: 'firstcol',
      HEADERS: 'headers',
      NO_ROW_HEADER: 'norowheader',
    },
  };

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaTable.xmlKey });
    this.tableGroup = this.getFirstChildByClass<DitaTGroup>(DitaTGroup);
    this.rowheader = this.attr(DitaTable.ATTRIBUTE_KEYS.ROWHEADER);
  }

  static cast(data: Dita) {
    return data as DitaTable;
  }
}
