<ng-container *ngFor="let element of getElements()">
  <ng-container [ngSwitch]="element.key">
    <div
      [class]="[
        'wrapper',
        isHeroImage(element) ? 'hero' : '',
        withFlex ? 'with-flex' : ''
      ]"
    >
      <div *ngIf="isTopLevel" class="sidebar left"></div>
      <div *ngIf="numbering !== undefined" class="numbering">
        {{ getNumbering(element) }}
      </div>
      <ng-container *ngSwitchCase="DitaP.xmlKey">
        <mono-p [data]="DitaP.cast(element)"></mono-p>
      </ng-container>
      <ng-container *ngSwitchCase="DitaFig.xmlKey">
        <mono-fig [data]="DitaFig.cast(element)"></mono-fig>
      </ng-container>
      <ng-container *ngSwitchCase="DitaDiv.xmlKey">
        <ng-container [ngSwitch]="element.props">
          <mono-line-counter
            *ngSwitchCase="DitaLineCounterDiv.PROPS_VALUES.LINE_NUMBERING"
            [data]="DitaLineCounterDiv.cast(element)"
          >
          </mono-line-counter>
          <mono-div *ngSwitchDefault [data]="DitaDiv.cast(element)"></mono-div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="DitaBox.xmlKey">
        <mono-box
          [data]="DitaBox.cast(element)"
          monoShowDataClass
          [dataClass]="element"
        ></mono-box>
      </ng-container>
      <ng-container *ngSwitchCase="DitaForeignText.xmlKey">
        <mono-foreign-text
          [data]="DitaForeignText.cast(element)"
          monoShowDataClass
          [dataClass]="element"
        ></mono-foreign-text>
      </ng-container>
      <ng-container *ngSwitchCase="DitaUL.xmlKey">
        <mono-lists [data]="DitaUL.cast(element)"></mono-lists>
      </ng-container>
      <ng-container *ngSwitchCase="DitaOL.xmlKey">
        <mono-lists [data]="DitaOL.cast(element)"></mono-lists>
      </ng-container>
      <ng-container *ngSwitchCase="DitaDL.xmlKey">
        <mono-lists [data]="DitaDL.cast(element)"></mono-lists>
      </ng-container>
      <mono-equation-block
        *ngSwitchCase="DitaEquationBlock.xmlKey"
        [data]="DitaEquationBlock.cast(element)"
      ></mono-equation-block>
      <mono-equation-figure
        *ngSwitchCase="DitaEquationFigure.xmlKey"
        [data]="DitaEquationFigure.cast(element)"
        monoShowDataClass
        [dataClass]="element"
      ></mono-equation-figure>
      <mono-math-ml
        *ngSwitchCase="DitaMathMl.xmlKey"
        [data]="DitaMathMl.cast(element)"
      ></mono-math-ml>
      <mono-table
        *ngSwitchCase="DitaTable.xmlKey"
        [data]="DitaTable.cast(element)"
      ></mono-table>
      <div *ngSwitchCase="DitaNote.xmlKey">
        <mono-note
          [data]="DitaNote.cast(element)"
          monoShowDataClass
          [dataClass]="element"
        ></mono-note>
      </div>
      <mono-lc-intro
        *ngSwitchCase="DitaLcIntro.xmlKey"
        [data]="element"
      ></mono-lc-intro>
      <mono-lc-interaction
        *ngSwitchCase="DitaLcInteraction.xmlKey"
        [data]="element"
        [segment]="segment"
      ></mono-lc-interaction>
      <mono-learning-assessment-body
        *ngSwitchCase="DitaLearningAssessmentBody.xmlKey"
        [learningAssessmentBody]="element"
      >
      </mono-learning-assessment-body>
      <dita-topic-heading
        *ngSwitchCase="DitaTitle.xmlKey"
        [data]="element"
      ></dita-topic-heading>
      <mono-learning-content
        *ngSwitchCase="DitaLearningContent.xmlKey"
        [data]="DitaLearningContent.cast(element)"
      ></mono-learning-content>
      <mono-learning-content-body
        *ngSwitchCase="DitaLearningContentBody.xmlKey"
        [data]="DitaLearningContentBody.cast(element)"
      ></mono-learning-content-body>
      <mono-learning-overview
        *ngSwitchCase="DitaLearningOverview.xmlKey"
        [data]="DitaLearningOverview.cast(element)"
      ></mono-learning-overview>
      <mono-learning-overview-body
        *ngSwitchCase="DitaLearningOverviewBody.xmlKey"
        [data]="DitaLearningOverviewBody.cast(element)"
      ></mono-learning-overview-body>
      <ng-container *ngSwitchDefault>
        <span
          *ngIf="element.isTextNode(); else unrecognized"
          class="text-container"
          >{{ element.getTextNode() }}</span
        >
        <ng-template #unrecognized>
          <div
            *ngIf="isShowUnrecognizedItemsActive$ | async"
            class="debug-info-block"
          >
            Nicht erkanntes Element in <b>generic-body</b>:
            <textarea>{{ element.getFormatedJson() }}</textarea>
          </div>
        </ng-template>
      </ng-container>
      <div *ngIf="isTopLevel" class="sidebar right">
        <ng-container *ngIf="element.key !== DitaTitle.xmlKey">
          <button mat-flat-button *ngFor="let button of buttons">
            <mat-icon fontIcon="link"></mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
