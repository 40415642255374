import { Component, Input } from '@angular/core';
import { DitaDivHero, DitaFig, DitaP } from '@mono/dita';

@Component({
  selector: 'mono-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss'],
})
export class HeroImageComponent {
  DitaFig = DitaFig;
  DitaP = DitaP;
  @Input() data!: DitaDivHero;
}
