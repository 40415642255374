import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
import { DitaDT } from './dita-dt';
import { DitaDD } from './dita-dd';

export interface DLentry {
  dt: DitaDT;
  dd: DitaDD;
}

@RegisterClass('dl')
export class DitaDL extends Dita {
  static KEY_DLENTR = 'dlentry';
  entries: DLentry[] = [];
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDL.xmlKey });

    this.entries = this.allChildren()
      .filter((child) => child.key === DitaDL.KEY_DLENTR)
      .map((dlEntry) => ({
        dt: dlEntry.getFirstChildByClass(DitaDT),
        dd: dlEntry.getFirstChildByClass(DitaDD),
      }))
      .filter((dlEntry) => dlEntry.dd && dlEntry.dt) as DLentry[];
  }

  static cast(data: Dita) {
    return data as DitaDL;
  }
}
