<div
  id="content-body"
  *ngIf="getSelectedTopicgroup$ | async as topicgroup"
  [ngClass]="[
    getGlobalChapterClass(topicgroup.outputclass),
    getLayoutAreaClass(LayoutType.SIDE, topicgroup.outputclass)
  ]"
>
  <div id="content-area" *ngIf="selectedScrollElement$ | async as scroll">
    <div
      class="content-host"
      [ngClass]="
        getLayoutAreaClass(LayoutType.HEADLINE, topicgroup.outputclass)
      "
      monoShowDataClass
      [dataClass]="topicgroup"
    >
      <h1 *ngIf="sectionHeading$ | async as heading">
        {{ heading }}
      </h1>
    </div>
    <div
      id="{{ ID_SCROLL_CONTAINER }}"
      class="content-host product-hyphens"
      [ngClass]="getSemanticScrollClassName(scroll.semantic)"
      monoShowDataClass
      [dataClass]="scroll"
    >
      <dita-scroll-section
        [outputclass]="topicgroup.outputclass"
      ></dita-scroll-section>
    </div>
  </div>
</div>
