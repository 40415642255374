import {
  getRouterSelectors,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { ngrxRouterFeatureKey } from './ngrx-routing.model';

export const selectRouterState = (state: {
  [ngrxRouterFeatureKey]: SerializedRouterStateSnapshot;
}) => state[ngrxRouterFeatureKey];

export const selectRouterPath = createSelector(
  selectRouterState,
  (state) => state.root.pathFromRoot
);

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
  selectTitle,
} = getRouterSelectors();
