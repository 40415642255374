import { Component, Input } from '@angular/core';
import { DitaDiv } from '@mono/dita';

@Component({
  selector: 'mono-single-column',
  templateUrl: './single-column.component.html',
  styleUrls: ['./single-column.component.scss'],
})
export class SingleColumnComponent {
  @Input() data!: DitaDiv;
}
