import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ImageViewerComponent,
  ImageViewerPayload,
} from '../image-viewer/image-viewer.component';

@Injectable()
export class OverlayService {
  private overlayRef: OverlayRef | undefined;

  constructor(private readonly overlay: Overlay) {}

  openImageViewer(ImageViewerPayload: ImageViewerPayload) {
    if (this.overlayRef) return false;
    const { src, alt } = ImageViewerPayload;
    this.overlayRef = this.overlay.create({
      width: '100vw',
      height: '100vh',
      hasBackdrop: true,
      backdropClass: 'smartview-overlay-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
    const componentPortal = new ComponentPortal(ImageViewerComponent);
    const { instance } = this.overlayRef.attach(componentPortal);
    instance.src = src;
    instance.alt = alt;
    return true;
  }

  close() {
    if (!this.overlayRef) return false;
    this.overlayRef.detachBackdrop();
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.overlayRef = undefined;
    return true;
  }
}
