import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as PreviewSelectors from './preview.selectors';

@Injectable()
export class PreviewFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(PreviewSelectors.getPreviewLoaded));
  allPreview$ = this.store.pipe(select(PreviewSelectors.getAllPreview));
  selectedPreview$ = this.store.pipe(select(PreviewSelectors.getSelected));

  constructor(private readonly store: Store) {}
}
