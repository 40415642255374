import { FxpOutput } from '@mono/smart-view-content';
import { RegisterClass } from '@mono/smart-view-content';
import { DitaAttributeKeys, DitaChildrenOptions } from '../../dita.model';
import { DitaDiv, Templates } from '../../generic';
import { Dita } from '../../dita';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (i) => {
    const props = i.attr(DitaAttributeKeys.PROPS);
    return props ? Templates.FLOAT.test(props) : false;
  },
})
export class FloatTemplate extends DitaDiv {
  floatedDiv: DitaDiv | undefined;
  paragraphDiv: DitaDiv | undefined;
  widthInPercent: string;
  private static readonly FLOATED_PROP_VALUE = 'floated';

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: FloatTemplate.xmlKey });
    this.floatedDiv = this.getFloatedDiv();
    this.paragraphDiv = this.getParagraphDiv();
    this.widthInPercent = this.getWidthInPercent();
  }

  // TODO should be private to prevent access in frontend
  override getWidth() {
    if (!this.props) return DitaDiv.DEFAULT_WIDTH;
    const floatTemplateWidth = Templates.FLOAT.exec(this.props);
    return floatTemplateWidth
      ? Number(floatTemplateWidth[1])
      : DitaDiv.DEFAULT_WIDTH;
  }

  private getFloatedDiv() {
    return this.findFirstChild<DitaDiv>(
      (i) =>
        i.key === DitaDiv.xmlKey &&
        i.attr(DitaAttributeKeys.PROPS) === FloatTemplate.FLOATED_PROP_VALUE
    );
  }

  private getParagraphDiv() {
    return this.findFirstChild<DitaDiv>(
      (i) => i.key === DitaDiv.xmlKey && i.attr('props') === undefined
    );
  }

  private getWidthInPercent() {
    return `${this.getWidth()}%`;
  }

  static override cast(data: Dita | DitaDiv) {
    return data as FloatTemplate;
  }
}
