import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { HttpClientModule } from '@angular/common/http';
import { apiGatewayEndpoints } from '@mono/shared';
import { environment } from '../../../environments/environment';

const uri = [
  environment.backend.baseUrl.toString(),
  apiGatewayEndpoints.graphQl,
].join(''); // <-- add the URL of the GraphQL server here
export function createApollo(
  httpLink: HttpLink
): ApolloClientOptions<NormalizedCacheObject> {
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [HttpClientModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
