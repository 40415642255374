import { Component, Input } from '@angular/core';
import { DitaTitle } from '@mono/dita';
import {
  buildAwsResourceUrl,
  getThumborParams,
} from '../../../../../shared/util/cloudfront/buildAwsResourceUrl';

@Component({
  selector: 'dita-topic-heading',
  templateUrl: './topic-heading.component.html',
  styleUrls: ['./topic-heading.component.scss'],
})
export class TopicHeadingComponent {
  @Input() data!: DitaTitle;
  @Input() difficultySetIconId?: string;

  srcUrl(icon: string) {
    return buildAwsResourceUrl(icon.trim());
  }
  getSrcSet(
    icon: string,
    format: string | undefined = undefined,
    quality = 40
  ): string {
    return `${buildAwsResourceUrl(
      ...getThumborParams(format, quality, 40),
      icon.trim()
    )} ${40}w`;
  }
}
