import { Action, createReducer, on } from '@ngrx/store';

import { debugActions } from './debug.actions';
import { DebugInit, DebugState } from './debug.models';

export const DEBUG_FEATURE_KEY = 'debug';

export interface DebugPartialState {
  readonly [DEBUG_FEATURE_KEY]: DebugState;
}

export const initialDebugState: DebugState = DebugInit;

function deleteUndefinedAttributes(obj: {
  [key: string]: string | boolean | undefined;
}) {
  for (const key of Object.keys(obj)) {
    if (obj[key] === undefined) delete obj[key];
  }
  return obj;
}

const reducer = createReducer(
  initialDebugState,
  on(debugActions.setShowUnrecognizedItems, (state, { unrecognizedItems }) => ({
    ...state,
    unrecognizedItems: {
      ...state.unrecognizedItems,
      ...deleteUndefinedAttributes(unrecognizedItems),
    },
  })),
  on(debugActions.setShowAttributeValues, (state, { showAttributeValues }) => ({
    ...state,
    attributeValues: {
      ...state.attributeValues,
      ...deleteUndefinedAttributes(showAttributeValues),
    },
  }))
);

export function debugReducer(state: DebugState | undefined, action: Action) {
  return reducer(state, action);
}
