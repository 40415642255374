<mono-image *ngIf="data.image as img" [image]="img"></mono-image>
<div class="text-container" *ngIf="data.fig">
  <div #description>
    <div
      class="fig-desc"
      *ngIf="data.fig.descriptionPs && data.fig.descriptionPs.length > 0"
    >
      <span
        class="fig-desc-text"
        *ngFor="let desc of data.fig.descriptionPs"
        [innerHTML]="desc.text() | safe : 'html'"
      ></span>
    </div>
  </div>
  <div
    class="overlay"
    *ngIf="data.overlay"
    [style.bottom.px]="description.offsetHeight + 80"
  >
    <div
      class="overlay-line-container"
      *ngFor="let pData of data.overlay.overlayData"
    >
      <span
        class="overlay-line"
        [innerHTML]="pData.text() | safe : 'html'"
      ></span>
    </div>
  </div>
</div>
