import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Dita,
  DitaLearningAssessment,
  DitaLearningContent,
  DitaPhrase,
} from '@mono/dita';
import { Semantic } from '@mono/shared';
import { Subscription, take } from 'rxjs';
import { ConfigFacade } from '../../../../../ngrx/config/config.facade';
import { SectionEntity } from '../../../../../ngrx/section/section.models';
import { TopicEntity } from '../../../../../ngrx/topic/topic.models';
import { DifficultyClassService } from '../../../../services/difficulty-class.service';

@Component({
  selector: 'mono-segment-content-didactic-variants',
  templateUrl: './segment-content-didactic-variants.component.html',
  styleUrls: ['./segment-content-didactic-variants.component.scss'],
})
export class SegmentContentDidacticVariantsComponent
  implements OnInit, OnDestroy
{
  @Input() topics!: TopicEntity[];
  @Input() segment!: SectionEntity;

  DitaLearningAssessment = DitaLearningAssessment;
  DitaLearningContent = DitaLearningContent;

  private subscription = new Subscription();

  tabTopics: {
    content: TopicEntity;
    label?: Dita;
  }[] = [];
  topTopics: TopicEntity[] = [];
  bottomTopics: TopicEntity[] = [];
  configPhrases: Semantic[] = [];

  constructor(
    private readonly configFacade: ConfigFacade,
    public readonly difficultyClass: DifficultyClassService
  ) {}

  ngOnInit(): void {
    let sortToTop = true;
    for (const t of this.topics) {
      let addToTab = false;
      if (
        t.topic.key === DitaLearningContent.xmlKey ||
        t.topic.key === DitaLearningAssessment.xmlKey
      ) {
        sortToTop = false;
        addToTab = true;
      }
      if (addToTab)
        this.tabTopics.push({
          content: t,
          label: t.topic.findFirstChild((i) => i.key === 'lcInteractionLabel2'),
        });
      else if (sortToTop) this.topTopics.push(t);
      else this.bottomTopics.push(t);
    }
    this.subscription.add(
      this.configFacade.selectedConfigSemanticPhrases$
        .pipe(take(1))
        .subscribe((phrases) => {
          this.configPhrases = phrases;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getLabelBackgroundColor(label?: Dita) {
    const labelPhraseChild =
      label?.childrenByClass<DitaPhrase>(DitaPhrase) ?? [];
    if (labelPhraseChild.length > 0) {
      const config = this.configPhrases.find(
        (p) => p.name === labelPhraseChild[0].semantic
      );
      if (config) return config.styles[0].backgroundColor;
    }
    return undefined;
  }

  getTabTitleClasses(label?: Dita) {
    const classes: string[] = [];
    const labelPhraseChild =
      label?.childrenByClass<DitaPhrase>(DitaPhrase) ?? [];
    if (labelPhraseChild?.length > 0) {
      const className = labelPhraseChild[0].getClassName();
      if (className) classes.push(className);
    } else classes.push('tab-title-default');
    return classes;
  }

  getLabelText(label?: Dita): string {
    const labelPhraseChild =
      label?.childrenByClass<DitaPhrase>(DitaPhrase) ?? [];
    if (labelPhraseChild && labelPhraseChild.length > 0)
      return (
        label
          ?.allChildren()
          .map((c) =>
            c instanceof DitaPhrase ? (c as DitaPhrase).text(false) : c.text()
          )
          .join('') ?? ''
      );
    if (label) return label.text();
    return '';
  }
}
