<div
  class="wrapper"
  [ngClass]="{
    'top-level': isTopLevel && !isHideNumber && number !== undefined
  }"
>
  <div
    *ngIf="shouldNumberBeDisplayed()"
    [ngClass]="scrollNumberingClass()"
    class="number"
  >
    <div class="innerNumber" [ngClass]="getAddClasses()">
      {{ compositNumber }}
    </div>
  </div>
  <span class="content">
    <ng-content></ng-content>
  </span>
</div>
