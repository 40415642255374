import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, onErrorResumeNext } from 'rxjs';
import { OverlayService } from '../service/overlay.service';
import { overlayActions } from './overlay.actions';

@Injectable()
export class OverlayEffects {
  private actions$ = inject(Actions);

  constructor(private readonly overlayService: OverlayService) {}

  close$ = createEffect(() =>
    this.actions$.pipe(
      ofType(overlayActions.close),
      map(() => {
        this.overlayService.close();
        return overlayActions.closed();
      }),
      catchError(() => onErrorResumeNext())
    )
  );

  openImageViewer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(overlayActions.openImageViewer),
      map(({ image }) => {
        this.overlayService.openImageViewer(image);
        return overlayActions.opened();
      }),
      catchError(() => onErrorResumeNext())
    )
  );
}
