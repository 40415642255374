import { ATTRIBUTE_KEY, FxpOutput, TEXT_KEY } from '../fxp-output.type';
import { XMLParser } from 'fast-xml-parser';
/**
 * Create fast fxp mockupdata with TS and from XML.
 *
 * For Example:
 *   * XML (Use e.g. with export data):
 * ```TS
 *   xml2Json('<tag id="1">asd<b>blubb</b>42</tag>')
 * ```
 *   * Dynamic TS Code (better for use with tag & attribute variables):
 * ```TS
 *  tag('tag',{attr:{id:'1'},sub:[
 *    text('asd'),
 *    tag('b',{sub:[
 *      text('blubb')
 *    ]}),
 *    text(42)
 *  ]});
 * ```
 * **Booth are got the same result JSON Object (here as string):**
 * ```JSON
 * [
 *    {
 *        "tag": [
 *            {
 *                "#text": "asd"
 *            },
 *            {
 *                "b": [
 *                    {
 *                        "#text": "blubb"
 *                    }
 *                ]
 *            },
 *            {
 *                "#text": 42
 *            }
 *        ],
 *        ":@": {
 *          id: "1"
 *        }
 *    }
 * ]
 * ```
 */

export interface MockParameterAttributes {
  attr: { [key: string]: string };
  sub: FxpOutput[];
}

/**
 * Create a Text node. For fast-xml-parser text as node syntax.
 * @param text the text
 * @returns The text node as JSON Object
 */
export function text(text: string) {
  return { [TEXT_KEY]: text };
}

/**
 * Create mockups same as fast-xml-parser (preserveOrder) output.
 * This enables a simpler and shorter notation.
 * Use the `text` function for each text un `sub`.
 *
 * @param tagName the xml-tag name
 * @param param1 optinal attributes
 * @param param1.attr add Attributes
 * @param param1.sub add sorted sub tags or sub texts (see `text` function).
 * @returns a JSON Object of the tag and all depedencies
 */
export function tag(
  tagName: string,
  {
    attr: attributes = undefined,
    sub: subTags = [],
  }: Partial<MockParameterAttributes> = {
    sub: [],
  }
): FxpOutput {
  if (attributes)
    return {
      [tagName]: subTags,
      [ATTRIBUTE_KEY]: attributes,
    };
  else
    return {
      [tagName]: subTags,
    };
}

let _xmlParser: XMLParser | undefined = undefined;
const getXMLParser = (): XMLParser => {
  if (_xmlParser === undefined) {
    _xmlParser = new XMLParser({
      preserveOrder: true,
      ignoreAttributes: false,
      allowBooleanAttributes: true,
      attributeNamePrefix: '',
    });
  }
  return _xmlParser;
};

/**
 * Parse xml 2 JSON with fast-xml-parser like transformer for tests.
 * @param xml xml data
 * @returns a JSON Object of the tag and all depedencies
 */
export function xml2Json(xml: string): FxpOutput {
  const json = getXMLParser().parse(xml) as FxpOutput[];
  return json.length === 1 ? json[0] : {};
}
