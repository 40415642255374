<picture *ngIf="difficultySetIconId">
  <source [srcset]="getSrcSet(difficultySetIconId, 'webp')" type="image/webp" />
  <img [src]="srcUrl(difficultySetIconId)" alt="" />
</picture>
<mono-generic-body
  [data]="lcQuestion2"
  monoShowDataClass
  [dataClass]="lcQuestion2"
  [withFlex]="false"
></mono-generic-body>
