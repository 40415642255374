import { Injectable, inject } from '@angular/core';
import {
  DitaGenericSection,
  DitaMapRoot,
  DitaSegment,
  DitaTopicgroup,
} from '@mono/dita';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs';
import { loadPreviewSuccess } from '../../preview/ngrx/preview.actions';
import { findKeyObject } from '../../shared/ngrx-helper';
import { ditaMapActions } from '../dita-map/dita-map.actions';

import { enumValues } from '@mono/shared';
import * as SectionActions from './section.actions';
import { DitaSectionType, SectionEntity } from './section.models';

const customIdPrefix = 'id-defined-by-smartview';

@Injectable()
export class SectionEffects {
  private readonly actions$ = inject(Actions);
  private currentLastId = 0;

  onLoadPreviewData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreviewSuccess),
      switchMap(({ data, scrollID }) => {
        const ditaMap = new DitaMapRoot(findKeyObject(data.Map.json));
        const sections = ditaMap.allChildSections.map((s) =>
          this.convertGenericSectionToEntity(s)
        );
        const actionsToDispatch = [
          SectionActions.upsertSections({ sections }),
          SectionActions.parseSectionSuccess({ data }),
        ];
        if (scrollID) {
          return [
            ...actionsToDispatch,
            SectionActions.chooseSection({ sectionId: scrollID }),
          ];
        } else {
          return actionsToDispatch;
        }
      })
    )
  );

  onSelectDitaMapChooseScrollSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ditaMapActions.chooseDitaMap),
      filter(
        // see action definition, filter out chooseCascadeSection==false
        ({ ditaMapId, chooseCascadeSection }) =>
          ditaMapId !== undefined &&
          (chooseCascadeSection == undefined || chooseCascadeSection == true)
      ),
      map(({ ditaMapId }) =>
        SectionActions.chooseSection({
          sectionId: ditaMapId,
        })
      )
    )
  );

  /**
   *
   * @param section
   * @returns Section id or placeholder id, if Section Id is undefined
   */
  private getSectionId(id: string): string {
    if (id.length > 0) {
      return id;
    }
    this.currentLastId++;
    return `${customIdPrefix}-${this.currentLastId}`;
  }

  convertGenericSectionToEntity(dita: DitaGenericSection): SectionEntity {
    const type = enumValues(DitaSectionType)
      .filter((val) => val === dita.key)
      .find((val) => !!val) as DitaSectionType;
    const parent = dita.parent;
    let childTopicIds: string[] = [];
    if (DitaSectionType.Segment === type) {
      childTopicIds = (dita as DitaSegment).topicrefList
        .map((tr) => tr.href)
        .filter((href) => !!href) as string[];
    }
    let outputclass: string | undefined = undefined;
    let didactic: string | undefined = undefined;
    if (DitaSectionType.Topicgroup === type) {
      outputclass = (dita as DitaTopicgroup).outputclass;
    }
    if (DitaSectionType.Segment === type) {
      didactic = dita.attr('didactic');
    }

    return {
      id: this.getSectionId(dita.id),
      parentId: parent ? parent.id : '',
      navtitle: dita.navTitle,
      type: type as DitaSectionType,
      childTopicIds,
      childSectionIds: dita.childSections.map((s) => s.id),
      outputclass,
      didactic,
      variant: dita.variant,
      semantic: dita.semantic,
      otherprops: dita.otherprops,
      props: dita.props,
      product: dita.product,
      key: dita.key,
    };
  }
}
