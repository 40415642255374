import { Dita } from '../../../../dita';
import { DitaRow } from '../row/dita-row';
import {
  FxpOutput,
  SmartViewContentOptions,
  RegisterClass,
} from '@mono/smart-view-content';

@RegisterClass('tbody')
export class DitaTBody extends Dita {
  static readonly ATTRIBUTES = {
    BACKGROUND_COLOR: 'background-color',
    BORDER: 'border',
  };
  readonly tableRows: DitaRow[];

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, options);
    this.tableRows = this.childrenByClass<DitaRow>(DitaRow);
  }
}
