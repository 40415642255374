import { Component, Input } from '@angular/core';
import { DitaP, DitaTabularSpacingP } from '@mono/dita';

@Component({
  selector: 'mono-p',
  templateUrl: './p.component.html',
  styleUrls: ['./p.component.scss'],
})
export class PComponent {
  @Input() data!: DitaP;
  DitaTabularSpacingP = DitaTabularSpacingP;
}
