import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { DitaChildrenOptions } from '../dita.model';
import { DitaTopicgroup } from './sections/dita-topicgroup';
import { DitaTopicmetaData } from './topicmeta-data/dita-topicmeta-data';
import { DitaTitle } from '../generic';
import { DitaGenericSection } from './sections';

@RegisterClass('map')
export class DitaMapRoot extends Dita {
  topicmeta?: DitaTopicmetaData;
  topicgroupList: DitaTopicgroup[];
  title?: string;
  childSectionIds: string[] = [];
  allChildSections: DitaGenericSection[] = [];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaMapRoot.xmlKey });

    this.topicmeta = this.getFirstChildByClass(DitaTopicmetaData);
    this.topicgroupList = this.childrenByClass(DitaTopicgroup);
    this.title = this.getFirstChildByClass(DitaTitle)?.text();
    this.allChildSections = [
      ...this.topicgroupList,
      ...this.topicgroupList.map((s) => s.getAllChildrenSections()).flat(),
    ];

    this.childSectionIds = this.topicgroupList
      .map((tg) => tg.id)
      .filter((id): id is string => !!id);
  }

  static cast(data: Dita) {
    return data as DitaMapRoot;
  }
}
