<div *ngFor="let topicEntity of topics" class="segment">
  <div
    [ngSwitch]="topicEntity.topic.key"
    monoShowDataClass
    [dataClass]="topicEntity.topic"
  >
    <mono-learning-content
      *ngSwitchCase="DitaLearningContent.xmlKey"
      [data]="DitaLearningContent.cast(topicEntity.topic)"
    ></mono-learning-content>
    <mono-numbering
      *ngSwitchCase="DitaLearningAssessment.xmlKey"
      [data]="DitaLearningAssessment.cast(topicEntity.topic)"
      [forceNumber]="topicEntity.idxOfType + 1"
      [isHideNumber]="topics.length === 0"
      [isTopLevel]="true"
      [segment]="segment"
      [addClasses]="
        difficultyClass.getdifficultyClassesForElement(topicEntity.topic)
      "
    >
      <mono-learning-assessment
        [data]="DitaLearningAssessment.cast(topicEntity.topic)"
        [difficultySetIconId]="
          difficultyClass.getDifficultySetIdItem(topicEntity.topic)
        "
        [segment]="segment"
      ></mono-learning-assessment>
    </mono-numbering>
    <mono-learning-overview
      *ngSwitchCase="DitaLearningOverview.xmlKey"
      [data]="DitaLearningOverview.cast(topicEntity.topic)"
      [segment]="segment"
    ></mono-learning-overview>
  </div>
</div>
