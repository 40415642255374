import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { overlayActions } from './overlay.actions';

@Injectable()
export class OverlayFacade {
  private readonly store = inject(Store);

  openImageViewer(src: string, altText: string) {
    return this.store.dispatch(
      overlayActions.openImageViewer({ image: { src, alt: altText } })
    );
  }

  close() {
    return this.store.dispatch(overlayActions.close());
  }
}
