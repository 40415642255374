import { SmartViewContentOptions } from '@mono/smart-view-content';

export type DitaChildrenOptions = Omit<SmartViewContentOptions, 'key'>;

export enum ExportElementKeys {
  DitaMap = 'map',
  LearningContent = 'learningContent',
  LearningAssessment = 'learningAssessment',
  LearningOverview = 'learningOverview',
  Config = 'config',
}

export enum DitaAttributeKeys {
  PROPS = 'props',
  OTHERPROPS = 'otherprops',
  PRODUCT = 'product',
  SEMANTIC = 'semantic',
  VARIANT = 'variant',
  NUMBERING = 'numbering',
  NUMBERING_VALUE = 'numberingvalue',
  TYPE = 'type',
}

export const DitaAttributeValues = {
  NUMBERING: {
    MANUAL_NUMBERING: 'manual-numbering',
  },
};
export interface DitaDefaultAttributes {
  key: string;
  [DitaAttributeKeys.OTHERPROPS]?: string;
  [DitaAttributeKeys.PROPS]?: string;
  [DitaAttributeKeys.PRODUCT]?: string;
  [DitaAttributeKeys.SEMANTIC]?: string;
  [DitaAttributeKeys.VARIANT]?: string;
  [DitaAttributeKeys.NUMBERING]?: string;
  [DitaAttributeKeys.NUMBERING_VALUE]?: string;
  [DitaAttributeKeys.TYPE]?: string;
}

export enum DitaTopicType {
  LearningContent,
  LearningOverview,
  LearningAssessment,
}
