import { Component, Input } from '@angular/core';
import { DitaNote, DitaSpeechbubble, DitaThoughtbubble } from '@mono/dita';

@Component({
  selector: 'mono-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent {
  @Input() data!: DitaNote;
  DitaNote = DitaNote;
  DitaSpeechbubble = DitaSpeechbubble;
  DitaThoughtbubble = DitaThoughtbubble;
}
