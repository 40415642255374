import { FxpOutput, SmartViewContent } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { RegisterClass } from '@mono/smart-view-content';
import { DitaChildrenOptions, DitaAttributeKeys } from '../dita.model';
import { DitaDiv } from './dita-div';
import { DitaP } from './paragraph/dita-p';

export const propsDescCondition = 'desc';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (i) => i.attr(DitaAttributeKeys.PROPS) === propsDescCondition,
})
export class DitaDivDesc extends DitaDiv {
  readonly parargraphs: DitaP[];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDivDesc.xmlKey });
    this.parargraphs = this.children(DitaP.xmlKey).map((p) => DitaP.cast(p));
  }

  static override cast(data: Dita | DitaDiv | SmartViewContent) {
    return data as DitaDivDesc;
  }
}
