import { Component, Input } from '@angular/core';
import { DitaLcQuestion2 } from '@mono/dita';
import {
  buildAwsResourceUrl,
  getThumborParams,
} from '../../../../../shared/util/cloudfront/buildAwsResourceUrl';

@Component({
  selector: 'mono-lc-question2',
  templateUrl: './lc-question2.component.html',
  styleUrls: ['./lc-question2.component.scss'],
})
export class LcQuestion2Component {
  @Input() lcQuestion2!: DitaLcQuestion2;
  @Input() difficultySetIconId?: string;

  srcUrl(icon: string) {
    return buildAwsResourceUrl(icon.trim());
  }
  getSrcSet(
    icon: string,
    format: string | undefined = undefined,
    quality = 40
  ): string {
    return `${buildAwsResourceUrl(
      ...getThumborParams(format, quality, 40),
      icon.trim()
    )} ${40}w`;
  }
}
