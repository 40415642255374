import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

export enum Difficulty {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

@RegisterClass('learningAssessment')
export class DitaLearningAssessment extends Dita {
  static ATTR_DIFFICULTY = 'difficulty';

  readonly difficulty: Difficulty;

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLearningAssessment.xmlKey });
    this.difficulty = this.attr(
      DitaLearningAssessment.ATTR_DIFFICULTY
    ) as Difficulty;
  }

  static cast(data: Dita) {
    return data as DitaLearningAssessment;
  }
}
