import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
import { DitaSegment } from './dita-segment';
import { DitaGenericSection } from './dita-generic-section';

@RegisterClass('scroll')
export class DitaScroll extends DitaGenericSection {
  segmentList: DitaSegment[];
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaScroll.xmlKey });
    this.segmentList = this.childrenByClass(DitaSegment);
    this.childSections = this.segmentList;
  }

  static cast(data: Dita) {
    return data as DitaScroll;
  }
}
