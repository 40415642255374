import { Component, Input } from '@angular/core';
import { DitaEquationFigure } from '@mono/dita';

@Component({
  selector: 'mono-equation-figure',
  templateUrl: './equation-figure.component.html',
  styleUrls: ['./equation-figure.component.scss'],
})
export class EquationFigureComponent {
  @Input() data!: DitaEquationFigure;
}
