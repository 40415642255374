import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { configActions } from './config.actions';

import { ConfigModel } from '@mono/shared';
import { map, tap } from 'rxjs';
import { ConfigStyleElementService } from '../../content/config-style-element.service';
import { loadPreviewSuccess } from '../../preview/ngrx/preview.actions';

@Injectable()
export class ConfigEffects {
  private readonly actions$ = inject(Actions);
  private readonly configStyleElement = inject(ConfigStyleElementService);

  onPreviewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreviewSuccess),
      map(({ exportID, data }) =>
        configActions.loadSuccess({
          exportID,
          css: data.config_css,
          json: JSON.parse(data.config_json) as ConfigModel,
        })
      )
    )
  );

  onLoadConfigSuccessSetPageCss$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configActions.loadSuccess),
        tap(({ css }) => (this.configStyleElement.css = css))
      ),
    { dispatch: false }
  );
}
