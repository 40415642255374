<!-- Label -->
<div
  class="label"
  *ngIf="selectCurrentConfigSegment$ | async as configSegment"
  [ngClass]="
    getSegmentClass(configSegment.semantic.name, configSegment.style.variant)
  "
>
  {{ configSegment.semantic.labelText }}
</div>
<!-- Titel -->
<h2
  *ngIf="segment.navtitle"
  [id]="getHeadingId()"
  [innerHTML]="segment.navtitle | safe : 'html'"
></h2>
<!-- Content -->
<ng-container
  [ngSwitch]="segment.didactic"
  *ngIf="this.getTopicsBySegmentId(segment.id) | async as topics"
>
  <mono-segment-content-didactic-variants
    *ngSwitchCase="'variants'"
    [segment]="segment"
    [topics]="topics"
  >
  </mono-segment-content-didactic-variants>
  <mono-segment-content
    *ngSwitchDefault
    [segment]="segment"
    [topics]="topics"
  ></mono-segment-content>
</ng-container>
