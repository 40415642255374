import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  NavigationActionTiming,
  routerReducer,
  RouterState,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from '../app.component';
import { PreviewComponent } from '../preview/preview.component';
import { ngrxRouterFeatureKey } from './ngrx-routing.model';

export enum RoutePaths {
  ROOT = '',
  PREVIEW = 'preview/:exportID/scroll/:scrollID',
}

const routes: Routes = [
  {
    path: RoutePaths.ROOT,
    component: AppComponent,
  },
  {
    path: RoutePaths.PREVIEW,
    component: PreviewComponent,
  },
];

@NgModule({
  imports: [
    StoreModule.forFeature(ngrxRouterFeatureKey, routerReducer),
    RouterModule.forRoot(routes),
    StoreRouterConnectingModule.forRoot({
      stateKey: ngrxRouterFeatureKey,
      routerState: RouterState.Full,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
