export const DebugInit = {
  unrecognizedItems: { genericBody: false },
  attributeValues: {
    props: false,
    otherprops: false,
    product: false,
    semantic: false,
    variant: false,
  },
};
export type DebugState = typeof DebugInit;

type SecoundObjectValuesToString<Type> = {
  [First in keyof Type]: { [Secound in keyof Type[First]]: string };
};

export type DebugStateDescriptionsType =
  SecoundObjectValuesToString<DebugState>;
