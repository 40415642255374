import { DitaNote, DitaSpeechbubble, DitaThoughtbubble } from '@mono/dita';
import {
  ElementType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getSemanticClassName,
} from '@mono/shared';

export abstract class NoteComponentBase {
  data!: DitaNote | DitaThoughtbubble | DitaSpeechbubble;
  getSemanticClass() {
    return getSemanticClassName(
      ElementType.NOTE,
      this.data.semantic ?? SEMANTIC_DEFAULT,
      VARIANT_DEFAULT,
      SemanticStyleTarget.CONTENT,
      true
    );
  }
}
