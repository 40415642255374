import { Component, ElementRef, Input, OnInit, inject } from '@angular/core';
import { DitaThoughtbubble } from '@mono/dita';
import { enumValues } from '@mono/shared';
import { NoteComponentBase } from '../note.abstract.component';

export enum TailDirection {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  LEFT_BOTTOM = 'left-bottom',
  LEFT_TOP = 'left-top',
  RIGHT_BOTTOM = 'right-bottom',
  RIGHT_TOP = 'right-top',
}

@Component({
  selector: 'mono-thoughtbubble',
  templateUrl: './thoughtbubble.component.html',
  styleUrls: ['./thoughtbubble.component.scss'],
})
export class ThoughtbubbleComponent
  extends NoteComponentBase
  implements OnInit
{
  @Input() override data!: DitaThoughtbubble;
  private elementRef: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);

  ngOnInit(): void {
    this.elementRef.nativeElement.style.flexDirection = this.setFlexDirection();
  }

  getTailDirection() {
    return enumValues(TailDirection).find(
      (direction) => direction === this.data.otherprops
    );
  }

  setFlexDirection() {
    switch (this.getTailDirection() as TailDirection) {
      case TailDirection.BOTTOM_LEFT:
      case TailDirection.BOTTOM_RIGHT:
        return 'column';
      case TailDirection.TOP_LEFT:
      case TailDirection.TOP_RIGHT:
        return 'column-reverse';
      case TailDirection.LEFT_BOTTOM:
      case TailDirection.LEFT_TOP:
        return 'row-reverse';
      case TailDirection.RIGHT_BOTTOM:
      case TailDirection.RIGHT_TOP:
        return 'row';
    }
  }
}
