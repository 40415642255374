import { FxpOutput } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { RegisterClass } from '@mono/smart-view-content';
import { DitaChildrenOptions, DitaAttributeKeys } from '../dita.model';
import { DitaDiv } from './dita-div';
import { DitaP } from './paragraph/dita-p';

export const propsOverlay = 'overlay';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (i) => i.attr(DitaAttributeKeys.PROPS) === propsOverlay,
})
export class DitaDivOverlay extends DitaDiv {
  overlayData: DitaP[] = [];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDivOverlay.xmlKey });

    this.overlayData = this.children(DitaP.xmlKey).map((p) => DitaP.cast(p));
  }

  static override cast(data: Dita | DitaDiv) {
    return data as DitaDivOverlay;
  }
}
