import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { configActions } from './config.actions';
import { ConfigEntity } from './config.models';

export const CONFIG_FEATURE_KEY = 'config';

export interface ConfigState extends EntityState<ConfigEntity> {
  selectedId?: string;
}

export interface ConfigPartialState {
  readonly [CONFIG_FEATURE_KEY]: ConfigState;
}

export const configAdapter: EntityAdapter<ConfigEntity> =
  createEntityAdapter<ConfigEntity>({
    selectId: ({ exportID }) => exportID,
  });

export const initialConfigState: ConfigState = configAdapter.getInitialState();

const reducer = createReducer(
  initialConfigState,
  on(configActions.loadSuccess, (state, { exportID, css, json }) =>
    configAdapter.addOne(
      { exportID, css, json },
      { ...state, selectedId: exportID }
    )
  )
);

export function configReducer(state: ConfigState | undefined, action: Action) {
  return reducer(state, action);
}
