<div *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<img
  #image
  (load)="onImageLoad()"
  [src]="src"
  [alt]="alt"
  [ngClass]="getImageClass()"
/>
