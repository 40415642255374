import { RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';

@RegisterClass<Dita>('lq', {
  precondition: (i) =>
    i.semantic === DitaForeignText.SEMANTIC_VALUES.FOREIGN_TEXT,
})
export class DitaForeignText extends Dita {
  private static readonly SEMANTIC_VALUES = {
    FOREIGN_TEXT: 'foreigntext',
  };

  static cast(data: Dita) {
    return data as DitaForeignText;
  }
}
