import { Component, Input } from '@angular/core';
import { DitaMathMl } from '@mono/dita';

@Component({
  selector: 'mono-math-ml',
  templateUrl: './math-ml.component.html',
  styleUrls: ['./math-ml.component.scss'],
})
export class MathMlComponent {
  @Input() data!: DitaMathMl;
}
