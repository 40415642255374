import { createAction, props } from '@ngrx/store';
import { actionNameBuilder } from '../../shared/ngrx-helper';
import { PreviewEntity } from './preview.models';

const createActionName = actionNameBuilder('Preview');

export const loadPreviewData = createAction(
  createActionName('Load Preview Data'),
  props<{ exportID: string; scrollID?: string }>()
);

export const loadPreviewSuccess = createAction(
  createActionName('Load Preview Success'),
  props<{ data: PreviewEntity; scrollID?: string; exportID: string }>()
);

export const loadPreviewFailure = createAction(
  createActionName('Load Preview Failure'),
  props<{ error: any }>()
);
