import { Component, Input, OnInit } from '@angular/core';
import { DitaDiv } from '@mono/dita';

interface Column {
  width: string;
  div: DitaDiv;
}

@Component({
  selector: 'mono-two-columns',
  templateUrl: './two-columns.component.html',
  styleUrls: ['./two-columns.component.scss'],
})
export class TwoColumnsComponent implements OnInit {
  @Input() data!: DitaDiv;
  columns!: Column[];

  ngOnInit() {
    this.columns = this.getColumns();
  }

  getColumns(): Column[] {
    const divs = this.data.children(DitaDiv.xmlKey) as DitaDiv[];
    const leftColumnWidth = this.data.getWidth();
    return divs.map((div, i) => {
      const width = this.getColumnWidth(i, leftColumnWidth);
      return {
        div,
        width,
      };
    });
  }

  private getColumnWidth(index: number, leftColumnWidth: number) {
    const columnWidth = index === 1 ? 100 - leftColumnWidth : leftColumnWidth;
    return `${columnWidth}%`;
  }
}
