import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../../dita';
import { DitaP } from '../../paragraph/dita-p';
import { DitaDesc } from '../../dita-desc';

@RegisterClass('equation-figure')
export class DitaEquationFigure extends Dita {
  readonly descriptionPs: DitaP[];
  readonly title: string;

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaEquationFigure.xmlKey });

    this.descriptionPs = this.children(DitaDesc.xmlKey)
      .map((desc) => DitaDesc.cast(desc))
      .map((desc) => desc.parargraphs)
      .flat();

    this.title = this.getTitle() ?? '';
  }

  static cast(data: Dita) {
    return data as DitaEquationFigure;
  }
}
