import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { DitaAttributeKeys, DitaDefaultAttributes } from '@mono/dita';
import { DebugFacade } from '../../debug/ngrx/debug.facade';

@Directive({
  selector: '[monoShowDataClass]',
})
export class ShowDataClassDirective implements OnInit {
  static PREFIX = 'fonto';
  @Input() dataClass!: DitaDefaultAttributes;
  @HostBinding('class')
  get elementClass(): string {
    const pre = ShowDataClassDirective.PREFIX;
    const keyClass = `${pre}-key-${this.dataClass.key}`;
    const attributeClasses = Object.values(DitaAttributeKeys)
      .map((key) => [key, this.dataClass[key]])
      .filter(([, value]) => value)
      .map(([key, value]) => `${pre}-${key}-${value}`);
    return `${keyClass} ${attributeClasses.join(' ')} ${
      attributeClasses.length > 0 ? 'show-data-container' : ''
    }`;
  }

  elementNode!: HTMLElement;
  elementParentNode!: HTMLElement | null;
  newParent!: HTMLElement;
  child!: HTMLElement;
  key!: HTMLElement;

  withAttributes: DitaAttributeKeys[] = [];
  all = false;

  constructor(private el: ElementRef, private debugFacade: DebugFacade) {}
  ngOnInit(): void {
    this.debugFacade.selectedAttributeValues$.subscribe((data) => {
      const oldWithAttributesLength = this.withAttributes.length;
      this.updateAttributes(data.props, DitaAttributeKeys.PROPS);
      this.updateAttributes(data.otherprops, DitaAttributeKeys.OTHERPROPS);
      this.updateAttributes(data.semantic, DitaAttributeKeys.SEMANTIC);
      this.updateAttributes(data.product, DitaAttributeKeys.PRODUCT);
      this.updateAttributes(data.variant, DitaAttributeKeys.VARIANT);

      if (this.withAttributes.length > 0 && oldWithAttributesLength === 0)
        this.createNodes();
      if (this.withAttributes.length > 0 && oldWithAttributesLength > 0) {
        this.removeNodes();
        this.createNodes();
      }
      if (this.withAttributes.length === 0 && oldWithAttributesLength > 0)
        this.removeNodes();
    });
  }

  updateAttributes(shouldBeEnabled: boolean, key: DitaAttributeKeys) {
    if (shouldBeEnabled && !this.withAttributes.includes(key))
      this.withAttributes.push(key);
    if (!shouldBeEnabled && this.withAttributes.includes(key)) {
      const index = this.withAttributes.indexOf(key);
      this.withAttributes.splice(index, 1);
    }
  }

  createNodes(): void {
    const attributes = Object.values(DitaAttributeKeys)
      .map((key): [string, string] => [key, this.dataClass[key] as string])
      .filter(
        ([key, value]) =>
          key && this.withAttributes.includes(key as DitaAttributeKeys) && value
      );

    if (attributes.length > 0 || this.all) {
      this.child = document.createElement('div');

      this.key = document.createElement('span');
      this.key.innerHTML = `${this.dataClass.key}`;

      attributes.forEach(([key, value]) => {
        const attr = document.createElement('span');
        attr.className = key;
        attr.innerHTML = `${key}: ${value}`;
        this.child.appendChild(attr);
      });
      this.elementNode = this.el.nativeElement as HTMLElement;
      this.elementParentNode = this.elementNode.parentElement;
      this.newParent = document.createElement('div');

      this.elementParentNode?.appendChild(this.newParent);
      this.newParent.appendChild(this.elementNode);
      this.newParent.appendChild(this.child);
      this.newParent.appendChild(this.key);
      this.newParent.className = 'show-data-parent';
      this.key.className = 'show-data-key';
      this.child.className = 'show-data-class';
    }
  }

  removeNodes(): void {
    if (this.newParent && this.elementParentNode?.contains(this.newParent)) {
      this.elementParentNode?.removeChild(this.newParent);
      this.elementParentNode?.appendChild(this.elementNode);
    }
  }
}
