import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../../../../dita';
import { DitaTableStyle } from '../../../dita-table-style';

@RegisterClass('entry')
export class DitaEntry extends DitaTableStyle {
  static readonly ATTRIBUTES = {
    COLNAME: 'colname',
    NAMEST: 'namest',
    NAMEEND: 'nameend',
    MOREROWS: 'morerows',
  };
  static readonly REGEX_NAME = /column-(\d+)/;

  namest?: string;
  nameend?: string;
  morerows?: string;
  colname?: string;
  colspan?: number; // Table colspan value
  rowspan?: number; // Table rowspan value

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaEntry.xmlKey });

    this.namest = this.attr(DitaEntry.ATTRIBUTES.NAMEST);
    this.nameend = this.attr(DitaEntry.ATTRIBUTES.NAMEEND);
    this.colspan = this.getColspan(this.namest, this.nameend);
    this.colname = this.attr(DitaEntry.ATTRIBUTES.COLNAME);
    this.morerows = this.attr(DitaEntry.ATTRIBUTES.MOREROWS);
    this.rowspan = this.morerows ? Number(this.morerows) + 1 : undefined;
  }

  getColspan(namest?: string, nameend?: string) {
    if (!namest || !nameend) return undefined;
    const start = this.getColNameNumber(namest);
    const end = this.getColNameNumber(nameend);
    if (start === undefined || end === undefined) return undefined;
    return end - start + 1;
  }

  getColNameNumber(name: string) {
    const result = DitaEntry.REGEX_NAME.exec(name);
    if (!result || result.length <= 1) return undefined;
    return Number(result[1]);
  }

  static cast(data: Dita) {
    return data as DitaEntry;
  }
}
