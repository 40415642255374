import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
import { DitaLcIntro } from '../../generic';
import { DitaSection } from '../../generic/dita-section';

@RegisterClass('learningContentbody')
export class DitaLearningContentBody extends Dita {
  section?: DitaSection;
  lcIntro?: DitaLcIntro;

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLearningContentBody.xmlKey });
    this.section = this.getFirstChildByClass(DitaSection);
    this.lcIntro = this.getFirstChildByClass(DitaLcIntro);
  }

  static cast(data: Dita) {
    return data as DitaLearningContentBody;
  }
}
