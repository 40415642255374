import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ContentModule } from './content/content.module';
import { ConfigEffects } from './ngrx/config/config.effects';
import { ConfigFacade } from './ngrx/config/config.facade';
import { DitaMapEffects } from './ngrx/dita-map/dita-map.effects';
import { DitaMapFacade } from './ngrx/dita-map/dita-map.facade';
import { ngrxRootState } from './ngrx/ngrx-root-state';
import { SectionEffects } from './ngrx/section/section.effects';
import { SectionFacade } from './ngrx/section/section.facade';
import { TopicEffects } from './ngrx/topic/topic.effects';
import { TopicFacade } from './ngrx/topic/topic.facade';
import { PreviewModule } from './preview/preview.module';
import { AppRoutingModule } from './router/app-routing-module';
import { NGRXRouterEffects } from './router/ngrx-router.effects';
import { NgrxRouterFacade } from './router/ngrx-router.facade';
import { GraphQLModule } from './shared/graphql/graphql.module';
import { OverlayModule } from './user-interface/overlay/overlay.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot(ngrxRootState),
    EffectsModule.forRoot([
      DitaMapEffects,
      SectionEffects,
      TopicEffects,
      ConfigEffects,
      NGRXRouterEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 100,
      logOnly: environment.production,
      autoPause: true,
    }),
    AppRoutingModule,
    ContentModule,
    OverlayModule,
    PreviewModule,
    GraphQLModule,
  ],
  providers: [
    DitaMapFacade,
    SectionFacade,
    TopicFacade,
    ConfigFacade,
    NgrxRouterFacade,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
