import { createActionGroup, props } from '@ngrx/store';
import { DitaMapEntity } from './dita-map.models';

export const ditaMapActions = createActionGroup({
  source: 'DitaMap',
  events: {
    chooseDitaMap: props<{
      ditaMapId: string;
      chooseCascadeSection?: boolean;
    }>(), // chooseCascadeSection == undefined is same as true (default value)
    upsertDitaMap: props<{ ditaMap: DitaMapEntity }>(),
    error: props<{ msg: string }>(),
  },
});
