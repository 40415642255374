<mono-segment-content [topics]="topTopics"></mono-segment-content>
<mono-tabs tabsType="segment">
  <mono-tab
    *ngFor="let topicEntity of tabTopics"
    [addClasses]="getTabTitleClasses(topicEntity.label)"
    [activeColor]="getLabelBackgroundColor(topicEntity.label)"
  >
    <ng-template #title *ngIf="topicEntity.label">
      <span
        [innerHTML]="getLabelText(topicEntity.label) | safe : 'html'"
      ></span>
    </ng-template>
    <ng-template #content>
      <div
        [ngSwitch]="topicEntity.content.topic.key"
        monoShowDataClass
        [dataClass]="topicEntity.content.topic"
      >
        <mono-learning-content
          *ngSwitchCase="DitaLearningContent.xmlKey"
          [data]="DitaLearningContent.cast(topicEntity.content.topic)"
        ></mono-learning-content>
        <mono-numbering
          *ngSwitchCase="DitaLearningAssessment.xmlKey"
          [data]="DitaLearningAssessment.cast(topicEntity.content.topic)"
          [forceNumber]="topicEntity.content.idxOfType + 1"
          [isHideNumber]="tabTopics.length === 0"
          [isTopLevel]="true"
          [segment]="segment"
          [addClasses]="
            difficultyClass.getdifficultyClassesForElement(
              topicEntity.content.topic
            )
          "
        >
          <mono-learning-assessment
            [data]="DitaLearningAssessment.cast(topicEntity.content.topic)"
            [segment]="segment"
          ></mono-learning-assessment>
        </mono-numbering>
      </div>
    </ng-template>
  </mono-tab>
</mono-tabs>
<mono-segment-content [topics]="bottomTopics"></mono-segment-content>
