import { Component, Input } from '@angular/core';
import {
  Dita,
  DitaAttributeValues,
  DitaLcInteraction,
  DitaLcOpenQuestion2,
} from '@mono/dita';
import { SectionEntity } from '../../../../../ngrx/section/section.models';
import { DifficultyClassService } from '../../../../services/difficulty-class.service';

@Component({
  selector: 'mono-lc-interaction',
  templateUrl: './lc-interaction.component.html',
  styleUrls: ['./lc-interaction.component.scss'],
})
export class LcInteractionComponent {
  DitaLcOpenQuestion2 = DitaLcOpenQuestion2;
  DitaAttributeValues = DitaAttributeValues;

  @Input() data!: DitaLcInteraction;
  @Input() segment!: SectionEntity;

  constructor(public readonly difficultyClass: DifficultyClassService) {}

  getForceNumber(lcOpenQuestion2: Dita): string | undefined {
    if (
      lcOpenQuestion2.numbering !==
      DitaAttributeValues.NUMBERING.MANUAL_NUMBERING
    )
      return undefined;
    return lcOpenQuestion2.numberingValue ?? '';
  }
}
