import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ElementType,
  LayoutType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getGlobalChapterColorsClass,
  getProductOrChapterColorsClass,
  getSemanticClassName,
} from '@mono/shared';
import { Observable, Subscription } from 'rxjs';
import { SectionFacade } from '../ngrx/section/section.facade';
import { SectionEntity } from '../ngrx/section/section.models';
import { NgrxRouterFacade } from '../router/ngrx-router.facade';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, AfterViewChecked, OnDestroy {
  readonly ID_SCROLL_CONTAINER = 'scroll-container';
  getGlobalChapterColorsClass = getGlobalChapterColorsClass;
  LayoutType = LayoutType;
  private anchorScrolledIntoView = false;
  private subscription = new Subscription();
  private routeFragment!: string | undefined;

  private sectionFacade = inject(SectionFacade);
  private ngrxRouterFacade = inject(NgrxRouterFacade);

  getSelectedTopicgroup$!: Observable<SectionEntity | undefined>;
  sectionHeading$!: Observable<string | undefined>;
  selectedScrollElement$!: Observable<SectionEntity | undefined>;

  ngOnInit(): void {
    this.getSelectedTopicgroup$ = this.sectionFacade.getSelectedTopicgroup$;
    this.sectionHeading$ = this.sectionFacade.getScrollSectionHeading$;
    this.selectedScrollElement$ = this.sectionFacade.selectedSection$;
    this.subscription.add(
      this.ngrxRouterFacade.fragment$.subscribe((fragment) => {
        this.routeFragment = fragment;
      })
    );
  }

  // called in `afterViewChecked` because the dom tree is not fully built at `afterViewInit`
  ngAfterViewChecked(): void {
    this.scrollAnchorTargetElementIntoView();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private scrollAnchorTargetElementIntoView() {
    if (!this.routeFragment || this.anchorScrolledIntoView) return;
    const anchorTargetElement = document.getElementById(this.routeFragment);
    if (!anchorTargetElement) return;
    anchorTargetElement.scrollIntoView();
    this.anchorScrolledIntoView = true;
  }

  getGlobalChapterClass(outputclass?: string) {
    if (!outputclass) return '';
    return getGlobalChapterColorsClass(outputclass, true);
  }

  getLayoutAreaClass(layoutType: LayoutType, outputclass?: string) {
    return getProductOrChapterColorsClass(layoutType, outputclass);
  }

  getSemanticScrollClassName(semanticValue?: string) {
    return getSemanticClassName(
      ElementType.SCROLL,
      semanticValue || SEMANTIC_DEFAULT,
      VARIANT_DEFAULT,
      SemanticStyleTarget.CONTENT,
      true
    );
  }
}
