import { Component, Input } from '@angular/core';
import { Dita } from '@mono/dita';

@Component({
  selector: 'li[mono-list-item]',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  @Input() data!: Dita;
}
