import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ROUTER_NAVIGATED,
  RouterNavigatedAction,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { filter, map, switchMap } from 'rxjs';
import { GraphQlService } from '../../shared/graphql/graphql.service';

import { RoutePaths } from '../../router/app-routing-module';
import * as PreviewActions from './preview.actions';

@Injectable()
export class PreviewEffects {
  onNavigationPreviewWithExportId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter(
        (action: RouterNavigatedAction<SerializedRouterStateSnapshot>) =>
          action.payload.routerState.root.firstChild?.routeConfig?.path ===
          RoutePaths.PREVIEW
      ),
      map((action: RouterNavigatedAction<SerializedRouterStateSnapshot>) => {
        const exportID =
          action.payload.routerState.root.firstChild?.params['exportID'];
        const scrollID =
          action.payload.routerState.root.firstChild?.params['scrollID'];
        return PreviewActions.loadPreviewData({ exportID, scrollID });
      })
    )
  );

  loadPreviewDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreviewActions.loadPreviewData),
      switchMap(({ exportID, scrollID }) =>
        this.gqlService
          .getImport(exportID)
          .valueChanges.pipe(
            map((queryRes) => ({ queryRes, scrollID, exportID }))
          )
      ),
      map(({ queryRes, scrollID, exportID }) => {
        return PreviewActions.loadPreviewSuccess({
          data: queryRes.data.import,
          scrollID,
          exportID,
        });
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly gqlService: GraphQlService
  ) {}
}
