import { SmartViewContent } from './smart-view-content';
import { CheckFunction } from './smart-view-content.models';

export interface RegisterClassOptions<T extends SmartViewContent> {
  precondition?: CheckFunction<T>;
}

export interface ClassContainer<T extends SmartViewContent>
  extends RegisterClassOptions<T> {
  class: typeof SmartViewContent;
  requiredProps: RegisterClassOptions<T>;
}

export type ClassMap<T extends SmartViewContent> = {
  [key: string]: ClassContainer<T>[];
};

export const SmartViewContentClassMap: ClassMap<SmartViewContent> = {};

/**
 * Sort class with checkFunction to top and without to bottom.
 * E.g.: DitaDiv (without checkFunction) should be checked at last and with match attribute DitaDivImagegroup first.
 * @param a Class Container
 * @param b Class Container
 * @returns negative number when a got checkFunction and b not, else positive number and when equal 0
 */
export function sortRegisterClass(
  a: ClassContainer<SmartViewContent>,
  b: ClassContainer<SmartViewContent>
) {
  if (!a.precondition && b.precondition) return 1;
  if (a.precondition && !b.precondition) return -1;
  return 0;
}

export function RegisterClass<T extends SmartViewContent>(
  key: string,
  options: RegisterClassOptions<T> = {}
): (constructor: typeof SmartViewContent) => void {
  return function (constructor: typeof SmartViewContent) {
    constructor.xmlKey = key;
    if (options.precondition)
      constructor.precondition =
        options.precondition as CheckFunction<SmartViewContent>;
    if (!(key in SmartViewContentClassMap)) {
      SmartViewContentClassMap[key] = [];
    }
    SmartViewContentClassMap[key].push({
      class: constructor,
      requiredProps: options as RegisterClassOptions<SmartViewContent>,
      precondition: options.precondition as CheckFunction<SmartViewContent>,
    });
    SmartViewContentClassMap[key].sort(sortRegisterClass);
  };
}
