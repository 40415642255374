import { Injectable, inject } from '@angular/core';
import { DitaMapRoot } from '@mono/dita';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap } from 'rxjs/operators';
import { loadPreviewSuccess } from '../../preview/ngrx/preview.actions';
import { findKeyObject } from '../../shared/ngrx-helper';

import { FxpOutput } from '@mono/smart-view-content';
import { ditaMapActions } from './dita-map.actions';
import { DitaMapEntity } from './dita-map.models';

@Injectable()
export class DitaMapEffects {
  private readonly actions$ = inject(Actions);

  onLoadPreviewData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreviewSuccess),
      filter(({ data }) => !!data && !!data.Map.json),
      map(({ data, scrollID }) => ({
        ditaMap: new DitaMapRoot(findKeyObject(data.Map.json) as FxpOutput),
        scrollID: scrollID as string,
      })),
      filter(
        ({ ditaMap }) =>
          !!ditaMap.id && !!ditaMap.title && !!ditaMap.childSectionIds
      ),
      mergeMap(({ ditaMap, scrollID }) => {
        if (!ditaMap.id)
          return [
            ditaMapActions.error({ msg: 'The ditamap id is not defined.' }),
          ];
        return [
          ditaMapActions.upsertDitaMap({
            ditaMap: this.convertDitaMapRootToDitaMapEntity(ditaMap),
          }),
          ditaMapActions.chooseDitaMap({
            ditaMapId: ditaMap.id,
            chooseCascadeSection: scrollID ? false : true,
          }),
        ];
      })
    )
  );

  private convertDitaMapRootToDitaMapEntity(map: DitaMapRoot): DitaMapEntity {
    return {
      id: map.id,
      title: map.title ? map.title : '',
      childSectionIds: map.childSectionIds,
    };
  }
}
