import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('data')
export class DitaData extends Dita {
  static ATTR_NAME = 'name';
  name?: string;
  value: string;
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaData.xmlKey });
    this.name = this.attr(DitaData.ATTR_NAME);
    this.value = this.text();
  }

  static cast(data: Dita) {
    return data as DitaData;
  }
}
