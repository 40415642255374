import { Component, Input } from '@angular/core';
import {
  DitaLcInteraction,
  DitaLcIntro,
  DitaLearningAssessmentBody,
} from '@mono/dita';
import { SectionEntity } from '../../../../../ngrx/section/section.models';

@Component({
  selector: 'mono-learning-assessment-body',
  templateUrl: './learning-assessment-body.component.html',
  styleUrls: ['./learning-assessment-body.component.scss'],
})
export class LearningAssessmentBodyComponent {
  DitaLcIntro = DitaLcIntro;
  DitaLcInteraction = DitaLcInteraction;
  @Input() learningAssessmentBody!: DitaLearningAssessmentBody;
  @Input() segment!: SectionEntity;
}
