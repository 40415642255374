import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('lcQuestion2')
export class DitaLcQuestion2 extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLcQuestion2.xmlKey });
  }
  static cast(data: Dita) {
    return data as DitaLcQuestion2;
  }
}
