import { Component, Input } from '@angular/core';
import { DitaFig } from '@mono/dita';

@Component({
  selector: 'mono-fig',
  templateUrl: './fig.component.html',
  styleUrls: ['./fig.component.scss'],
})
export class FigComponent {
  @Input() data!: DitaFig;
  @Input() index!: number;

  hideImageInMobileView() {
    return this.data.getImages().some((img) => img.isDecoration);
  }
}
