<picture
  [ngClass]="{
    clickable: image.isClickable,
    imagegroup: isImagegroup(),
    'with-width': isImagegroup() && isImagegroupDivFigWithWidth(),
    'without-width': isImagegroup() && !isImagegroupDivFigWithWidth()
  }"
  monoShowDataClass
  [dataClass]="image"
>
  <source [srcset]="getSrcSet('webp')" type="image/webp" />
  <img
    [src]="srcUrl()"
    [width]="image.width"
    [height]="image.height"
    [style.height]="getHeight()"
    alt=""
  />
</picture>
