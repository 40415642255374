import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
@RegisterClass('learningOverview')
export class DitaLearningOverview extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLearningOverview.xmlKey });
  }

  static cast(data: Dita) {
    return data as DitaLearningOverview;
  }
}
