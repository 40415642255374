import { Component, Input, OnInit } from '@angular/core';
import { DitaDL, DitaLI, DitaOL, DitaUL } from '@mono/dita';
import {
  ElementType,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getSemanticClassName,
} from '@mono/shared';

@Component({
  selector: 'mono-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss'],
})
export class ListsComponent implements OnInit {
  DitaDL = DitaDL;
  DitaOL = DitaOL;
  DitaUL = DitaUL;
  DitaLI = DitaLI;

  @Input() data!: DitaUL | DitaOL | DitaDL;
  semanticULClass = '';
  props = '';

  ngOnInit() {
    this.props = this.data.props ?? '';
    this.semanticULClass = getSemanticClassName(
      ElementType.UL,
      this.data.props ?? VARIANT_DEFAULT,
      VARIANT_DEFAULT,
      SemanticStyleTarget.CONTENT,
      true
    );
  }

  getUlClasses() {
    const classesList = [];
    if (this.data.props === DitaUL.PROPS_VALUE.CUSTOM1)
      classesList.push(this.getULSemanticClass());
    if (this.data.props) classesList.push(this.data.props);
    return classesList;
  }
  getUlLiClass() {
    return this.data.props === DitaUL.PROPS_VALUE.CUSTOM2
      ? this.getULSemanticClass()
      : '';
  }

  getULSemanticClass() {
    return getSemanticClassName(
      ElementType.UL,
      this.data.props ?? VARIANT_DEFAULT,
      VARIANT_DEFAULT,
      SemanticStyleTarget.CONTENT,
      true
    );
  }
}
