import { Component, Input, OnInit } from '@angular/core';
import { DitaLineCounterDiv, DitaLineCounterP } from '@mono/dita';

@Component({
  selector: 'mono-line-counter',
  templateUrl: './line-counter.component.html',
  styleUrls: ['./line-counter.component.scss'],
})
export class LineCounterComponent implements OnInit {
  DitaLineCounterP = DitaLineCounterP;
  @Input() data!: DitaLineCounterDiv;
  counter!: string;

  ngOnInit(): void {
    this.counter = `item ${Number(this.data.otherprops) - 1}`;
  }
}
