import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../dita';

@RegisterClass('p')
export class DitaP extends Dita {
  readonly isMonospace: boolean;
  static readonly PROPS_VALUES = {
    TABULAR_SPACING: 'tabular-spacing',
    // TODO: legacy value used to mark tabular spacing p-elements.
    // remove when all old data is transformed to new format
    MONOSPACEFONT: 'monospacefont',
  };

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { key: DitaP.xmlKey, ...options });
    this.isMonospace = [
      DitaP.PROPS_VALUES.TABULAR_SPACING,
      DitaP.PROPS_VALUES.MONOSPACEFONT,
    ].includes(this.props ?? '');
  }

  /**
   * This overrides {@link Dita.needsHTMLTag}. `p` elemnts are registered in
   * DitaClassMap, so we do not need to check if it is present.
   *
   * Rules for `p` elements are different from other elemnts that may be
   * present in raw HTML output. Two cases apply to `p` elements:
   *
   * 1. They are the host element for some text and rendered by angular. Here
   * we **do not need HTML tags** because they are added by angular.
   *
   * 2. They are a child inside a raw HTML string and are rendered by the
   * browser. Here we **need HTML tags** to maintain correct formatting
   *
   * @returns `true` if the element needs surrounding HTML tags
   */
  override needsHTMLTag() {
    return this.parent?.key === 'ph';
  }

  static cast(data: Dita) {
    return data as DitaP;
  }
}
