import { DitaDiv } from '../../dita-div';
import { Dita } from '../../../dita';
import { RegisterClass } from '@mono/smart-view-content';

@RegisterClass<Dita>(DitaDiv.xmlKey, {
  precondition: (i) =>
    i.props === DitaLineCounterDiv.PROPS_VALUES.LINE_NUMBERING,
})
export class DitaLineCounterDiv extends DitaDiv {
  static readonly PROPS_VALUES = {
    LINE_NUMBERING: 'linenumbering',
  };

  static override cast(data: Dita): DitaLineCounterDiv {
    return new DitaLineCounterDiv(data.json);
  }
}
