import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './preview.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPreview from './ngrx/preview.reducer';
import { PreviewEffects } from './ngrx/preview.effects';
import { PreviewFacade } from './ngrx/preview.facade';
import { ContentModule } from '../content/content.module';
import { environment } from '../../environments/environment';
import { DebugModule } from '../debug/debug.module';

// PreviewReducer (Data) only in dev mode
const additionalImportList = [];
if (!environment.production) {
  additionalImportList.push(
    StoreModule.forFeature(
      fromPreview.PREVIEW_FEATURE_KEY,
      fromPreview.previewReducer
    )
  );
}

@NgModule({
  declarations: [PreviewComponent],
  imports: [
    CommonModule,
    ContentModule,
    EffectsModule.forFeature([PreviewEffects]),
    ...additionalImportList,
    DebugModule,
  ],
  providers: [PreviewFacade],
})
export class PreviewModule {}
