import { Component, Input } from '@angular/core';
import { DitaDL } from '@mono/dita';

@Component({
  selector: 'dl[mono-dl-entry]',
  templateUrl: './dl-entry.component.html',
  styleUrls: ['./dl-entry.component.scss'],
})
export class DLEntryComponent {
  @Input() data!: DitaDL;
}
