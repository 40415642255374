<div class="imagegroup">
  <div class="group">
    <div
      class="element"
      *ngFor="let fig of data.figs"
      [style.flex-basis]="fig.ditaDivFig?.width"
      [ngClass]="!!fig.ditaDivFig?.width ? 'with-width' : 'without-width'"
    >
      <mono-fig [data]="fig" rootType="imagegroup"></mono-fig>
    </div>
  </div>
  <mono-generic-body
    *ngFor="let desc of data.descriptions"
    [data]="desc"
  ></mono-generic-body>
</div>
