import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { SlideFormat } from '@mono/dita';
import Memo from 'memo-decorator';
import { buildAwsWebPResourceUrl } from '../../../../shared/util/cloudfront/buildAwsResourceUrl';
import { OverlayFacade } from '../../../../user-interface/overlay/ngrx/overlay.facade';
import { Slide } from '../slider.component';

@Component({
  selector: 'mono-slider-image',
  templateUrl: './slider-image.component.html',
  styleUrls: ['./slider-image.component.scss'],
})
export class SliderImageComponent implements AfterViewInit {
  @Input() data!: Slide;
  @Input() forcedAspectRatio: number | undefined = undefined;
  @Input() slideFormat!: SlideFormat;
  @Input() placeholder!: boolean;
  @ViewChild('image') imgElement!: ElementRef<HTMLImageElement>;

  constructor(
    private overlayFacade: OverlayFacade,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    // ViewChild is defined after view init. We have to trigger CD manually.
    this.changeDetector.detectChanges();
  }

  // #1 code smell
  @HostListener('window:resize')
  onResize() {
    // detect changes if width is below break point
    // resize breakpoint could be extraced as global constant if needed
    if (window.innerWidth <= 980 && this.slideFormat === 'sameheight') {
      this.changeDetector.detectChanges();
    }
  }

  @HostListener('click')
  onClick() {
    return this.overlayFacade.openImageViewer(this.getSrc(), '');
  }

  // slow connections may recieve the image too late for the component to set the
  // height. Therefore change detection must be triggered when content is loaded
  onLoad() {
    this.changeDetector.detectChanges();
  }

  getSrc() {
    return buildAwsWebPResourceUrl(this.data.image.getResourceName());
  }

  getWidth() {
    const width = this.data.image.width;
    if (Number.isNaN(width)) {
      return undefined;
    }
    return width;
  }

  getHeight() {
    const height = this.data.image.height;
    if (Number.isNaN(height)) {
      return undefined;
    }
    return height;
  }

  // chrome and firefox reevaluate this method every time we navigate to the next slide
  // this leads to flickering but can be mitigated by using a memoization function
  // Due to the structure of the slider, we cannot use the memo decorator directly and
  // have to fake a unique parameter.
  // TODO LZ replace this with something that does not depend on lodash (commonJS/AMD)
  @Memo({
    resolver: (
      ...args: (number | undefined | ElementRef<HTMLImageElement> | string)[]
    ) => JSON.stringify(args),
  })
  getHeightStyle(
    aspectRatio: number | undefined,
    imgEle: ElementRef<HTMLImageElement>,
    id: string
  ) {
    // we must check .clientWidth to be a non zero value. While images are
    // loaded, it is 0 which breaks the styling in firefox and chrome. Safari
    // handles it just fine
    if (aspectRatio && imgEle && imgEle.nativeElement.clientWidth) {
      return imgEle.nativeElement.clientWidth / aspectRatio;
    }
    // let default and samewidth set their styles independently
    return undefined;
  }
}
