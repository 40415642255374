export enum LayoutType {
  SIDE = 'side',
  HEADLINE = 'headline',
  MAIN = 'main',
}

export enum Hyphens {
  AUTO = 'auto',
  NONE = 'none',
}

export interface DifficultySet {
  name: string; // id
  alt?: string;
  description?: string;
  iconId?: string;
  color?: string;
}

export enum DifficultySystem {
  ICON = 'icon',
  COLOR = 'color',
}
export interface Icon {
  name: string;
  alt?: string;
  description?: string;
  url?: string;
}
export interface Chapter {
  name: string;
  fileName?: string;
}

export enum NumberingType {
  DECIMAL = '#decimal#',
  LOWER_ALPHA = '#lowerAlpha#',
  UPPER_ALPHA = '#upperAlpha#',
  LOWER_ROMAN = '#lowerRoman#',
  UPPER_ROMAN = '#upperRoman#',
  EMPTY = '##',
}

export enum ElementType {
  SCROLL = 'scroll',
  SEGMENT = 'segment',
  BOX = 'box',
  NOTE = 'note',
  PHARSE = 'ph',
  TABLE = 'table',
  UL = 'ul',

  TOPICGROUP = 'topicgroup', // never used in config, only placeholder
}
export interface SemanticStyles extends SemanticCSSStyles {
  variant?: string;
}

export const SemanticCSSStylesList = [
  'backgroundColor',
  'borderColor',
  'borderRadius',
  'borderSpacing',
  'borderStyle',
  'borderWidth',
  'color',
  'font',
  'fontSize',
  'fontStyle',
  'hyphens',
  'labelColor',
];

export interface SemanticCSSStyles {
  backgroundColor?: string;
  labelColor?: string;
  labelFont?: string;
  color?: string;
  font?: string;
  fontStyle?: string;
  fontSize?: string;
  fontWeight?: string;
  borderColor?: string;
  borderStyle?: string;
  borderWidth?: string;
  borderRadius?: string;
  borderSpacing?: string;
  textDecorationColor?: string;
  textDecorationStyle?: string;
  textDecorationThickness?: string;
  textDecorationLine?: string;
  numberingColor?: string;
  hyphens?: string;
}

export interface Semantic {
  name: string;
  element: ElementType;
  description?: string;
  icon?: string;
  labelText?: string;
  numberingType?: NumberingType;
  styles: SemanticStyles[];
}

export interface ConfigModel {
  difficultyset: DifficultySet[];
  difficultySystem?: DifficultySystem;
  iconset: Icon[];
  chapter: Chapter[];
  semantic: Semantic[];
}

export enum SemanticStyleTarget {
  CONTENT = 'content',
  LABEL = 'label',
  NUMBERING = 'numbering',
  DEFAULT = 'default',
  ASSIGNMENT = 'assignment',
  SOURCE = 'source',
  SKILLS = 'skills',
  GRAMMAR = 'grammar',
}

export const CSS_INITIAL = 'initial';
export const SEMANTIC_KEY = 'semantic';
export const VARIANT_DEFAULT = 'default';
export const SEMANTIC_DEFAULT = 'default';
export const PRODUCT_TYPE_DEFAULT = 'default';
export const SEMANTIC_STYLE_DEFAULT = 'default';
