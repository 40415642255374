import { Injectable } from '@angular/core';
import { Dita } from '@mono/dita';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs';

import * as TopicSelectors from './topic.selectors';

@Injectable()
export class TopicFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(TopicSelectors.getTopicLoaded));
  allTopic$ = this.store.pipe(select(TopicSelectors.getAllTopics));
  selectedTopics$ = this.store.pipe(
    select(TopicSelectors.getSelectedTopics),
    // Transform Data from readonly- (ngrx-state) to rw-object.
    map((topicEntity) =>
      topicEntity.map((entity) => ({
        ...entity,
        topic: new Dita(entity.topic.json, { key: entity.topic.key }),
      }))
    )
  ); // Currently show all given topics from dita-map

  constructor(private readonly store: Store) {}
}
