import { Component, Input } from '@angular/core';
import {
  DitaLearningAssessment,
  DitaLearningContent,
  DitaLearningOverview,
} from '@mono/dita';
import { SectionEntity } from '../../../../../ngrx/section/section.models';
import { TopicEntity } from '../../../../../ngrx/topic/topic.models';
import { DifficultyClassService } from '../../../../services/difficulty-class.service';

@Component({
  selector: 'mono-segment-content',
  templateUrl: './segment-content.component.html',
  styleUrls: ['./segment-content.component.scss'],
})
export class SegmentContentComponent {
  @Input() topics!: TopicEntity[];
  @Input() segment!: SectionEntity;

  DitaLearningAssessment = DitaLearningAssessment;
  DitaLearningOverview = DitaLearningOverview;
  DitaLearningContent = DitaLearningContent;

  constructor(public readonly difficultyClass: DifficultyClassService) {}
}
