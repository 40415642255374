import { FxpOutput, RegisterClass, tag } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaAttributeKeys, DitaChildrenOptions } from '../../dita.model';
import { DitaDesc, DitaDiv, DitaImage, Templates } from '../../generic';

export type SlideFormat = 'sameheight' | 'samewidth';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (i) => {
    let props = i.attr(DitaAttributeKeys.PROPS);
    props = props ? props : '';
    return Templates.SLIDER.regex.test(props);
  },
})
export class SliderTemplate extends DitaDiv {
  readonly slideFormatList: SlideFormat[] = ['sameheight', 'samewidth'];
  slides;
  slideFormat: SlideFormat;
  aspectRatio: number | undefined;
  private static readonly defaultSliderFormat: SlideFormat = 'sameheight';

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: SliderTemplate.xmlKey });
    this.slides = this.getSlides() ?? [];
    // sameheight is the expected default type
    this.slideFormat = this.getSlideFormat();
    this.aspectRatio = this.getForcedAspectRatio();
  }

  static override cast(data: Dita | DitaDiv) {
    return data as SliderTemplate;
  }

  getSlides() {
    const slides = this.allChildren().find(
      (child) => child.attr('props') === 'slides'
    );
    if (!slides) return;
    return slides.allChildren().map((dita) => {
      const key = DitaImage.xmlKey;
      const desc = dita.findFirstChild<DitaDesc>(
        (i) => i.key === DitaDesc.xmlKey
      );
      const slideBase = {
        dita,
        desc,
      };
      const image = dita.findFirstChild<DitaImage>((i) => i.key === key);
      if (!image) {
        // gracefully fail and create empty image
        return {
          ...slideBase,
          image: new DitaImage(
            tag(key, {
              attr: { href: '', otherprops: '', product: '' },
            })
          ),
        };
      }
      return {
        ...slideBase,
        image,
      };
    });
  }

  /**
   * Opinionated method that returns the SlideFormat string.
   * If format is malformed, `sameheight` is returned
   * @returns format string
   */
  getSlideFormat() {
    const props = this.props;
    if (!props) return SliderTemplate.defaultSliderFormat;
    const format = Templates.SLIDER.regex.exec(props);
    if (!format) return SliderTemplate.defaultSliderFormat;
    const slideFormatString = format[Templates.SLIDER.slideFormatIndex];
    // make sure that format is of type SlideFormat;
    // otherwise stick to `sameheight` format
    if (
      !slideFormatString &&
      !this.slideFormatList.some((frmt) => frmt === slideFormatString)
    )
      return SliderTemplate.defaultSliderFormat;
    return slideFormatString as SlideFormat;
  }

  getForcedAspectRatio() {
    const aspectRatios = this.getAspectRatio();
    switch (this.slideFormat) {
      case 'samewidth':
        return Math.min(...aspectRatios);
      default:
        return Math.max(...aspectRatios);
    }
  }

  private getAspectRatio() {
    return this.slides
      .map((slide) => slide.image?.aspectRatio ?? 0)
      .filter((ratio) => !Number.isNaN(ratio));
  }
}
