import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../dita';
import { DITA_DIV_KEY, DitaDivTemplate } from './dita-div-templates';

export const Templates = {
  SINGLE_COLUMN: 'template-1col',
  TWO_COLUMN: {
    re: /^template-2col(-(\d{1,2}))?$/,
    default: 50,
  },
  FLOAT: /^template-floatbox-(\d{2})/,
  SLIDER: {
    regex: /^template-slider((-)(.*))?/,
    slideFormatIndex: 3, // index of the format string in array returned by regex.exec()
  },
  IMAGE_GROUP: 'template-imagegroup',
  HERO: 'template-hero',
};

@RegisterClass(DITA_DIV_KEY)
export class DitaDiv extends Dita {
  private cacheTemplate: DitaDivTemplate | undefined;
  protected static readonly DEFAULT_WIDTH = 50;

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaDiv.xmlKey });
  }

  getDivs(): DitaDiv[] {
    return this.children(DitaDiv.xmlKey) as DitaDiv[];
  }

  getDivTemplate(): DitaDivTemplate {
    if (!this.cacheTemplate) {
      this.cacheTemplate = DitaDivTemplate.NO_TEMPLATE;
      if (this.props) {
        if (this.props === Templates.SINGLE_COLUMN)
          this.cacheTemplate = DitaDivTemplate.SINGLE_COLUMN;
        else if (Templates.TWO_COLUMN.re.test(this.props))
          this.cacheTemplate = DitaDivTemplate.TWO_COLUMN;
        else if (Templates.FLOAT.test(this.props))
          this.cacheTemplate = DitaDivTemplate.FLOAT;
        else if (Templates.SLIDER.regex.test(this.props))
          this.cacheTemplate = DitaDivTemplate.SLIDER;
        else if (this.props === Templates.IMAGE_GROUP)
          this.cacheTemplate = DitaDivTemplate.IMAGE_GROUP;
        else if (this.props === Templates.HERO)
          this.cacheTemplate = DitaDivTemplate.HERO;
        else this.cacheTemplate = DitaDivTemplate.NO_TEMPLATE;
      } else this.cacheTemplate = DitaDivTemplate.NO_TEMPLATE;
    }
    return this.cacheTemplate;
  }

  getWidth(): number {
    if (!this.props) return DitaDiv.DEFAULT_WIDTH;
    switch (this.getDivTemplate()) {
      case DitaDivTemplate.TWO_COLUMN: {
        const propsReData = Templates.TWO_COLUMN.re.exec(this.props);
        if (
          propsReData &&
          propsReData.length >= 3 &&
          typeof propsReData[2] === 'string'
        ) {
          return parseInt(propsReData[2]);
        } else {
          return Templates.TWO_COLUMN.default;
        }
      }
      default: {
        return DitaDiv.DEFAULT_WIDTH;
      }
    }
  }

  static cast(data: Dita) {
    return data as unknown as DitaDiv;
  }
}
