import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Dictionary,
} from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as SectionActions from './section.actions';
import { DitaSectionType, SectionEntity } from './section.models';

export const SECTION_FEATURE_KEY = 'section';

export interface SectionState extends EntityState<SectionEntity> {
  selectedId?: string; // which Section record has been selected
  loaded: boolean; // has the Section list been loaded
  sectionSelectedComplete: boolean; // is the selectedId the id of an scroll-element
  error?: string | null; // last known error (if any)
}

export interface SectionPartialState {
  readonly [SECTION_FEATURE_KEY]: SectionState;
}

export const sectionAdapter: EntityAdapter<SectionEntity> =
  createEntityAdapter<SectionEntity>({
    selectId: (entity) => entity.id,
  });
export const initialSectionState: SectionState = sectionAdapter.getInitialState(
  {
    // set initial required properties
    loaded: false,
    sectionSelectedComplete: false,
  }
);

function findLastSectionNode(
  currentSection: SectionEntity,
  sections: Dictionary<SectionEntity>
): SectionEntity {
  if (
    currentSection.childSectionIds &&
    currentSection.childSectionIds.length > 0
  ) {
    for (const nextSectionId of currentSection.childSectionIds) {
      if (nextSectionId in sections && sections[nextSectionId] !== undefined) {
        return findLastSectionNode(
          sections[nextSectionId] as SectionEntity,
          sections
        );
      }
    }
  }
  return currentSection;
}

function findSectionParentByType(
  currentSection: SectionEntity,
  sections: Dictionary<SectionEntity>,
  sectionType: DitaSectionType
): SectionEntity | undefined {
  if (currentSection?.type === sectionType) {
    return currentSection;
  } else if (
    currentSection.parentId in sections &&
    sections[currentSection.parentId]
  ) {
    const parentSection = sections[currentSection.parentId];
    if (!parentSection) return undefined;
    return findSectionParentByType(parentSection, sections, sectionType);
  } else {
    return undefined;
  }
}

/**
 * @param {SectionEntity} currentSection
 * @param {Dictionary<SectionEntity>} sections Haystack to search through
 * @returns {SectionEntity | undefined} If Scroll element cannot be found, `undefined` is returned
 */
function findScrollParent(
  currentSection: SectionEntity,
  sections: Dictionary<SectionEntity>
): SectionEntity | undefined {
  const lastSectionNode = findLastSectionNode(currentSection, sections);
  return findSectionParentByType(
    lastSectionNode,
    sections,
    DitaSectionType.Scroll
  );
}

/**
 * @param {SectionEntity} currentSection Entity whose parent Scroll element is unknown
 * @param {Dictionary<SectionEntity>} sections Haystack to search through
 * @returns {Partial<SectionState>} State with the parent Scroll element selected
 */
function selectScrollParent(
  currentSection: SectionEntity,
  sections: Dictionary<SectionEntity>
): Partial<SectionState> {
  const scrollParentNode = findScrollParent(currentSection, sections);
  if (scrollParentNode) {
    return {
      selectedId: scrollParentNode.id,
      sectionSelectedComplete: true,
    };
  } else {
    console.warn('Could not finde Scroll element close to:', currentSection);
    return {
      selectedId: currentSection.id,
      sectionSelectedComplete: false,
    };
  }
}

const reducer = createReducer(
  initialSectionState,
  on(SectionActions.upsertSection, (state, { section }) =>
    sectionAdapter.upsertOne(section, { ...state, loaded: true })
  ),
  on(SectionActions.upsertSections, (state, { sections }) => {
    if (!state.sectionSelectedComplete && state.selectedId) {
      for (const section of sections) {
        if (section.id === state.selectedId) {
          if (section.type === DitaSectionType.Scroll) {
            return sectionAdapter.upsertMany(sections, {
              ...state,
              sectionSelectedComplete: true,
              loaded: true,
            });
          } else {
            // Section is in entities but is not of type Scroll => search encapsulating Scroll element
            return {
              ...state,
              ...selectScrollParent(section, state.entities),
            };
          }
        }
      }
    }
    return sectionAdapter.upsertMany(sections, { ...state, loaded: true });
  }),
  on(SectionActions.chooseSection, (state, { sectionId }) => {
    if (sectionId in state.entities && state.entities[sectionId]) {
      const section = state.entities[sectionId] as SectionEntity;
      if (state.entities[sectionId]?.type === DitaSectionType.Scroll) {
        return {
          ...state,
          selectedId: sectionId,
          sectionSelectedComplete: true,
        };
      } else {
        return {
          ...state,
          ...selectScrollParent(section, state.entities),
        };
      }
    } else {
      const parentIds = state.ids
        .map((id) => state.entities[id])
        .filter((entity) => entity?.parentId === sectionId);
      if (parentIds.length > 0 && parentIds[0]) {
        const scrollParentNode = findScrollParent(parentIds[0], state.entities);
        if (scrollParentNode) {
          return {
            ...state,
            selectedId: scrollParentNode.id,
            sectionSelectedComplete: true,
          };
        }
      }
    }
    return {
      ...state,
      selectedId: sectionId,
      sectionSelectedComplete: false,
    };
  })
);

export function sectionReducer(
  state: SectionState | undefined,
  action: Action
) {
  return reducer(state, action);
}
