import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { debugActions } from './debug.actions';
import { DebugState } from './debug.models';
import * as DebugSelectors from './debug.selectors';

@Injectable()
export class DebugFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  all$ = this.store.pipe(select(DebugSelectors.selectDebugState));
  selectedAttributeValues$ = this.store.pipe(
    select(DebugSelectors.selectDebugAttributeValues)
  );
  selectedUnrecognizedItems$ = this.store.pipe(
    select(DebugSelectors.selectDebugUnrecognizedItems)
  );
  selectDebugUnrecognizedItemsGenericBody$ = this.store.pipe(
    select(DebugSelectors.selectDebugUnrecognizedItemsGenericBody)
  );

  setNewState(newState: DebugState) {
    this.store.dispatch(debugActions.setState({ state: newState }));
  }

  /**
   * Set any attribute of attribute values, when it is not defined dont override the state.
   * @param showAttributeValues the attribute values
   */
  setAttributeValues(
    showAttributeValues: Partial<DebugState['attributeValues']>
  ) {
    this.store.dispatch(
      debugActions.setShowAttributeValues({ showAttributeValues })
    );
  }
  /**
   * Set any attribute of attribute values, when it is not defined dont override the state.
   * @param unrecognizedItems the unrecognized items
   */
  setUnrecognizedItems(
    unrecognizedItems: Partial<DebugState['unrecognizedItems']>
  ) {
    this.store.dispatch(
      debugActions.setShowUnrecognizedItems({ unrecognizedItems })
    );
  }
}
