import { gql } from '@apollo/client';

export const GET_IMPORT = gql`
  query ($exportID: String!) {
    import(export_id: $exportID) {
      id
      export_id
      createdAt
      updatedAt
      Map {
        id
        sf_id
        json
      }
      Topic {
        id
        sf_id
        json
      }
      config_css
      config_json
    }
  }
`;

export const GET_IMPORTS = gql`
  {
    imports {
      id
      export_id
      createdAt
      updatedAt
    }
  }
`;

export const GET_IMPORTS_WITH_CONTENT = gql`
  {
    imports {
      id
      export_id
      createdAt
      updatedAt
      Map {
        id
        sf_id
        createdAt
        updatedAt
      }
      Topic {
        id
        sf_id
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TOPIC_BY_SF_ID = gql`
  query TopicBySfId($sf_id: String!) {
    topic(sf_id: $sf_id) {
      json
      id
      sf_id
    }
  }
`;
