import { Component, Input } from '@angular/core';
import { DitaLineCounterDiv, DitaForeignText } from '@mono/dita';

@Component({
  selector: 'mono-foreign-text',
  templateUrl: './foreign-text.component.html',
  styleUrls: ['./foreign-text.component.scss'],
})
export class ForeignTextComponent {
  DitaLineCounterDiv = DitaLineCounterDiv;
  @Input() data!: DitaForeignText;
}
