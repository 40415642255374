import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
import { Difficulty, DitaLearningAssessment } from './dita-learning-assessment';

@RegisterClass('lcOpenQuestion2')
export class DitaLcOpenQuestion2 extends Dita {
  readonly difficulty: Difficulty;

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaLcOpenQuestion2.xmlKey });
    this.difficulty = this.attr(
      DitaLearningAssessment.ATTR_DIFFICULTY
    ) as Difficulty;
  }

  static cast(data: Dita) {
    return data as DitaLcOpenQuestion2;
  }
}
