import { Component, Input } from '@angular/core';
import { DitaLearningContentBody } from '@mono/dita';

@Component({
  selector: 'mono-learning-content-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class LearningContentBodyComponent {
  @Input() data!: DitaLearningContentBody;
}
