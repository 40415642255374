import { Component, Input } from '@angular/core';
import { DitaLearningContent, DitaLearningContentBody } from '@mono/dita';

@Component({
  selector: 'mono-learning-content',
  templateUrl: './learning-content.component.html',
  styleUrls: ['./learning-content.component.scss'],
})
export class LearningContentComponent {
  DitaLearningContentBody = DitaLearningContentBody;
  @Input() data!: DitaLearningContent;
}
