import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromOverlay from './ngrx/overlay.reducer';
import { OverlayEffects } from './ngrx/overlay.effects';
import { OverlayFacade } from './ngrx/overlay.facade';
import { OverlayService } from './service/overlay.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromOverlay.OVERLAY_FEATURE_KEY,
      fromOverlay.overlayReducer
    ),
    EffectsModule.forFeature([OverlayEffects]),
  ],
  providers: [OverlayFacade, OverlayService],
})
export class OverlayModule {}
