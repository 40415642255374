import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';
import { DitaTopicref } from './dita-topicref';
import { DitaGenericSection } from './dita-generic-section';
import {
  ElementType,
  SEMANTIC_DEFAULT,
  SemanticStyleTarget,
  VARIANT_DEFAULT,
  getSemanticClassNameFN,
} from '@mono/shared';

@RegisterClass('segment')
export class DitaSegment extends DitaGenericSection {
  static ATTR_DIDACTIC = 'didactic';
  topicrefList: DitaTopicref[];
  didactic?: string;
  getClassName: (target: SemanticStyleTarget) => string;
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaSegment.xmlKey });
    this.topicrefList = this.childrenByClass(DitaTopicref);
    this.didactic = this.attr(DitaSegment.ATTR_DIDACTIC);
    this.getClassName = getSemanticClassNameFN(
      ElementType.SEGMENT,
      SEMANTIC_DEFAULT,
      this.variant || VARIANT_DEFAULT
    );
  }

  static cast(data: Dita) {
    return data as DitaSegment;
  }
}
