import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DebugFacade } from './ngrx/debug.facade';
import {
  DebugInit,
  DebugState,
  DebugStateDescriptionsType,
} from './ngrx/debug.models';
import { MatCheckboxChange } from '@angular/material/checkbox';

export const DebugStateDescriptions: DebugStateDescriptionsType = {
  unrecognizedItems: {
    genericBody: 'Nicht erkannte generic-body Elemente anzeigen',
  },
  attributeValues: {
    props: 'Zeige die props Attributwerte an.',
    semantic: 'Zeige die Semantic Attributwerte an.',
    variant: 'Zeige die variant Attributwerte an.',
    otherprops: 'Zeige die otherprops Attributwerte an.',
    product: 'Zeige die product Attributwerte an.',
  },
};

@Component({
  selector: 'mono-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent {
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  debugState = JSON.parse(JSON.stringify(DebugInit)); // make it writeable
  DebugStateDescriptions = DebugStateDescriptions;
  attributeMenuKeys = Object.keys(DebugInit.attributeValues);
  unrecognizedMenuKeys = Object.keys(DebugInit.unrecognizedItems);

  constructor(private debugFacade: DebugFacade) {}

  onClickUnrecognizedItems(event: MatCheckboxChange) {
    const val: Partial<DebugState['unrecognizedItems']> = {};
    val[event.source.name as keyof DebugState['unrecognizedItems']] =
      event.checked;
    this.debugFacade.setUnrecognizedItems(val);
  }

  onClickAttributeValues(event: MatCheckboxChange) {
    const val: Partial<DebugState['attributeValues']> = {};
    val[event.source.name as keyof DebugState['attributeValues']] =
      event.checked;
    this.debugFacade.setAttributeValues(val);
  }

  getDescription(subset: keyof DebugStateDescriptionsType, key: string) {
    return (DebugStateDescriptions[subset] as Record<string, string>)[key];
  }
}
