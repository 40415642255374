import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DEBUG_FEATURE_KEY } from './debug.reducer';
import { DebugState } from './debug.models';

export const selectDebugState =
  createFeatureSelector<DebugState>(DEBUG_FEATURE_KEY);

export const selectDebugAttributeValues = createSelector(
  selectDebugState,
  (state) => state.attributeValues
);

export const selectDebugUnrecognizedItems = createSelector(
  selectDebugState,
  (state) => state.unrecognizedItems
);

export const selectDebugUnrecognizedItemsGenericBody = createSelector(
  selectDebugState,
  (state) => state.unrecognizedItems.genericBody
);
