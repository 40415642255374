<mono-generic-body
  *ngFor="
    let element of [
      learningAssessmentBody.children(DitaLcIntro.xmlKey),
      learningAssessmentBody.children(DitaLcInteraction.xmlKey)
    ].flat()
  "
  [data]="[element]"
  [segment]="segment"
>
</mono-generic-body>
