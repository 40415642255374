import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('ol')
export class DitaOL extends Dita {
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaOL.xmlKey });
  }

  static cast(data: Dita) {
    return data as DitaOL;
  }
}
