import { createAction, props } from '@ngrx/store';
import { PreviewEntity } from '../../preview/ngrx/preview.models';
import { actionNameBuilder } from '../../shared/ngrx-helper';
import { SectionEntity } from './section.models';

const createActionName = actionNameBuilder('Section');

export const chooseSection = createAction(
  createActionName('Choose section'),
  props<{ sectionId: string }>()
);

export const upsertSection = createAction(
  createActionName('Upsert section'),
  props<{ section: SectionEntity }>()
);
export const upsertSections = createAction(
  createActionName('Upsert sections'),
  props<{ sections: SectionEntity[] }>()
);
export const parseSectionSuccess = createAction(
  createActionName('Parsed sections successfully'),
  props<{ data: PreviewEntity }>()
);
