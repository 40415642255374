<div
  #wrapper
  class="wrapper"
  (scroll)="onScroll()"
  [ngClass]="{ scrollable: data.tableGroup?.isScrollable }"
>
  <table
    monoShowDataClass
    [dataClass]="data"
    [ngClass]="[
      getSemanticClass(),
      getTableClass(),
      data.tableGroup?.isScrollable ? 'scrollable' : ''
    ]"
    [ngStyle]="data.style"
    [id]="data.id"
  >
    <thead
      *ngIf="data.tableGroup?.tableHead as tableHead"
      monoShowDataClass
      [dataClass]="tableHead"
    >
      <tr
        *ngFor="let row of data.tableGroup?.tableHead?.tableRows"
        monoShowDataClass
        [dataClass]="row"
        [ngStyle]="row.style"
      >
        <th
          *ngFor="let cell of row.entries; index as idx"
          monoShowDataClass
          [dataClass]="cell"
          [ngStyle]="cell.style"
          [style.width.%]="getCellWidth(cell, idx)"
          [attr.colspan]="cell.colspan"
          [attr.rowspan]="cell.rowspan"
        >
          <mono-generic-body [data]="cell"></mono-generic-body>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of data.tableGroup?.tableBody?.tableRows"
        monoShowDataClass
        [dataClass]="row"
        [ngStyle]="row.style"
      >
        <td
          *ngFor="let cell of row.entries; index as idx"
          monoShowDataClass
          [class]="getCellClass(cell)"
          [dataClass]="cell"
          [ngStyle]="cell.style"
          [style.width.%]="getCellWidth(cell, idx)"
          [attr.colspan]="cell.colspan"
          [attr.rowspan]="cell.rowspan"
        >
          <mono-generic-body [data]="cell"></mono-generic-body>
        </td>
      </tr>
    </tbody>
  </table>
</div>
