import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as PreviewActions from './preview.actions';
import { PreviewEntity } from './preview.models';

export const PREVIEW_FEATURE_KEY = 'preview';

export interface PreviewState extends EntityState<PreviewEntity> {
  selectedId?: string | number; // which Preview record has been selected
  loaded: boolean; // has the Preview list been loaded
  error?: string | null; // last known error (if any)
}

export interface PreviewPartialState {
  readonly [PREVIEW_FEATURE_KEY]: PreviewState;
}

export const previewAdapter: EntityAdapter<PreviewEntity> =
  createEntityAdapter<PreviewEntity>({
    selectId: (entity) => entity.export_id,
  });

export const initialPreviewState: PreviewState = previewAdapter.getInitialState(
  {
    // set initial required properties
    loaded: false,
  }
);

const reducer = createReducer(
  initialPreviewState,
  on(PreviewActions.loadPreviewData, (state, { exportID }) => ({
    ...state,
    loaded: false,
    error: null,
    selectedId: exportID,
  })),
  on(PreviewActions.loadPreviewSuccess, (state, { data }) =>
    previewAdapter.upsertOne(data, { ...state, loaded: true })
  ),
  on(PreviewActions.loadPreviewFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function previewReducer(
  state: PreviewState | undefined,
  action: Action
) {
  return reducer(state, action);
}
