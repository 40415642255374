import { FxpOutput } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { RegisterClass } from '@mono/smart-view-content';
import { DitaChildrenOptions, DitaAttributeKeys } from '../dita.model';
import { DitaDiv } from './dita-div';
import { DitaFig } from './dita-fig';

export const propFigCondition = 'fig';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (item) =>
    item.attr(DitaAttributeKeys.PROPS) === propFigCondition,
})
export class DitaDivFig extends DitaDiv {
  readonly width?: string;
  readonly figs: DitaFig[];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDivFig.xmlKey });
    this.width = this.otherprops;
    this.figs = this.children(DitaFig.xmlKey).map((fig) => DitaFig.cast(fig));
  }

  static override cast(data: Dita | DitaDiv) {
    return data as DitaDivFig;
  }
}
