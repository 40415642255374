import { FxpOutput } from '@mono/smart-view-content';

export function actionNameBuilder(actionGroupName: string) {
  return function (actionName: string) {
    return `[${actionGroupName}] ${actionName}`;
  };
}

export function findKeyObject(data: string): FxpOutput {
  const obj = JSON.parse(data);
  for (const child of obj) {
    const key = Object.keys(child)[0];
    if (key.substring(0, 1) !== '?') return child;
  }
  throw new Error(`Could not find key in ${JSON.stringify(obj, null, 2)}`);
}
