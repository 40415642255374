import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PREVIEW_FEATURE_KEY,
  PreviewState,
  previewAdapter,
} from './preview.reducer';

// Lookup the 'Preview' feature state managed by NgRx
export const getPreviewState =
  createFeatureSelector<PreviewState>(PREVIEW_FEATURE_KEY);

const { selectAll, selectEntities } = previewAdapter.getSelectors();

export const getPreviewLoaded = createSelector(
  getPreviewState,
  (state: PreviewState) => state.loaded
);

export const getPreviewError = createSelector(
  getPreviewState,
  (state: PreviewState) => state.error
);

export const getAllPreview = createSelector(
  getPreviewState,
  (state: PreviewState) => selectAll(state)
);

export const getPreviewEntities = createSelector(
  getPreviewState,
  (state: PreviewState) => {
    return selectEntities(state);
  }
);

export const getSelectedId = createSelector(
  getPreviewState,
  (state: PreviewState) => state.selectedId
);

export const getSelected = createSelector(
  getPreviewEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
