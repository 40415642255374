import {
  FxpOutput,
  RegisterClass,
  SmartViewContentOptions,
} from '@mono/smart-view-content';
import { Dita } from '../../../../dita';
import { DitaTableStyle } from '../../dita-table-style';
import { DitaEntry } from './entry/dita-entry';

@RegisterClass('row')
export class DitaRow extends DitaTableStyle {
  readonly entries: DitaEntry[];

  constructor(json: FxpOutput, options: SmartViewContentOptions = {}) {
    super(json, { ...options, key: DitaRow.xmlKey });
    this.entries = this.childrenByClass<DitaEntry>(DitaEntry);
  }

  static cast(data: Dita) {
    return data as DitaRow;
  }
}
