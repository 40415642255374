import { Component, Input } from '@angular/core';
import { DitaSpeechbubble } from '@mono/dita';
import { NoteComponentBase } from '../note.abstract.component';

@Component({
  selector: 'mono-speechbubble',
  templateUrl: './speechbubble.component.html',
  styleUrls: ['./speechbubble.component.scss'],
})
export class SpeechbubbleComponent extends NoteComponentBase {
  @Input() override data!: DitaSpeechbubble;
}
