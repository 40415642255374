import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { DitaChildrenOptions } from '../dita.model';
import { DitaDesc } from './dita-desc';
import { DitaDivFig } from './dita-div-fig';
import { DitaDivGroup } from './dita-div-group';
import { DitaImage } from './dita-image';
import { DitaP } from './paragraph/dita-p';

const skipNumberingValues = ['skip-numbering', 'skip'];

@RegisterClass('fig')
export class DitaFig extends Dita {
  readonly imageGroup?: DitaDivGroup;
  readonly ditaDivFig?: DitaDivFig;
  readonly desc?: DitaDesc;
  readonly isCaptionAvailable: boolean;

  descriptionPs: DitaP[];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaFig.xmlKey });
    this.desc = this.children(DitaDesc.xmlKey)
      .map((c) => DitaDesc.cast(c))
      .filter((c) => c.parargraphs.length > 0)
      .find((c) => c !== undefined);

    this.descriptionPs = this.children(DitaDesc.xmlKey)
      .map((desc) => DitaDesc.cast(desc))
      .map((desc) => desc.parargraphs)
      .flat();

    // Imagegroup
    this.imageGroup = this.findFirstParent(
      DitaDivGroup.precondition
    ) as DitaDivGroup;
    this.ditaDivFig = this.findFirstParent(
      DitaDivFig.precondition
    ) as DitaDivFig;

    this.isCaptionAvailable = !!this.desc && this.desc?.parargraphs.length > 0;
  }

  skipNumbering(): boolean {
    return this.props ? skipNumberingValues.includes(this.props) : false;
  }

  getImages() {
    return this.children(DitaImage.xmlKey) as DitaImage[];
  }

  static cast(data: Dita) {
    return data as unknown as DitaFig;
  }
}
