import { FxpOutput, RegisterClass } from '@mono/smart-view-content';
import { Dita } from '../../dita';
import { DitaChildrenOptions } from '../../dita.model';

@RegisterClass('topicref')
export class DitaTopicref extends Dita {
  static ATTR = {
    BASE: 'base',
    HREF: 'href',
  };
  base?: string;
  href?: string;
  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaTopicref.xmlKey });

    this.base = this.attr(DitaTopicref.ATTR.BASE);
    this.href = this.attr(DitaTopicref.ATTR.HREF);
  }

  static cast(data: Dita) {
    return data as DitaTopicref;
  }
}
