import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DitaBox, DitaP } from '@mono/dita';
import { ConfigFacade } from '../../../ngrx/config/config.facade';
import { Observable, Subscription, from, map, mergeMap } from 'rxjs';
import { Semantic, SemanticStyleTarget } from '@mono/shared';
import { buildAwsResourceUrl } from '../../../shared/util/cloudfront/buildAwsResourceUrl';
import { getThumborParams } from '../../../shared/util/cloudfront/buildAwsResourceUrl';

@Component({
  selector: 'mono-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit, OnDestroy {
  SemanticStyleTarget = SemanticStyleTarget;
  subscription: Subscription = new Subscription();
  DitaP = DitaP;
  @Input() data!: DitaBox;
  @Input() index!: number;
  configBox$!: Observable<Semantic | undefined>;
  label = '';
  icon = '';
  allPossibleBoxes: string[] = [];

  resolutions = [1440, 780, 323];

  constructor(private configFacade: ConfigFacade) {}
  ngOnInit(): void {
    this.subscription.add(
      this.configFacade.selectedConfigSemanticBoxes$
        .pipe(
          mergeMap((boxes) => from(boxes)),
          map((box) => box.name)
        )
        .subscribe((boxNames) => {
          this.allPossibleBoxes.push(boxNames);
        })
    );
    this.configBox$ = this.configFacade.getSmanticBoxWithName(
      this.data.boxSemantic
    );

    this.subscription.add(
      this.configBox$.subscribe((data) => {
        this.label = data?.labelText || '';
        this.icon = data?.icon || '';
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  srcUrl() {
    return buildAwsResourceUrl(this.icon);
  }
  getSrcSet(format: string | undefined = undefined, quality = 80): string {
    return this.resolutions
      .map(
        (res) =>
          `${buildAwsResourceUrl(
            ...getThumborParams(format, quality, res),
            this.icon
          )} ${res}w`
      )
      .join(',');
  }
}
