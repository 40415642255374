import { Component, Input } from '@angular/core';
import { DitaDiv, DitaLcOpenAnswer2 } from '@mono/dita';

@Component({
  selector: 'mono-lc-open-answer2',
  templateUrl: './lc-open-answer2.component.html',
  styleUrls: ['./lc-open-answer2.component.scss'],
})
export class LcOpenAnswer2Component {
  DitaDiv = DitaDiv;
  @Input() lcOpenAnswer2!: DitaLcOpenAnswer2;
}
