<figure>
  <img
    #image
    [id]="data.image.id"
    [class]="slideFormat"
    [src]="getSrc()"
    [width]="getWidth()"
    [height]="getHeight()"
    [style.height.px]="
      getHeightStyle(forcedAspectRatio, imgElement, data.image.id)
    "
    (load)="onLoad()"
    loading="eager"
    fetchPriority="high"
  />
  <figcaption *ngIf="data.desc as desc">
    <p [innerHTML]="desc.text() | safe : 'html'"></p>
  </figcaption>
</figure>
