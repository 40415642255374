import { FxpOutput } from '@mono/smart-view-content';
import { Dita } from '../dita';
import { RegisterClass } from '@mono/smart-view-content';
import { DitaChildrenOptions, DitaAttributeKeys } from '../dita.model';
import { DitaDiv } from './dita-div';
import { DitaDivFig } from './dita-div-fig';

export const propsGroupCondition = 'group';

@RegisterClass(DitaDiv.xmlKey, {
  precondition: (item) =>
    item.attr(DitaAttributeKeys.PROPS) === propsGroupCondition,
})
export class DitaDivGroup extends DitaDiv {
  readonly divFigs: DitaDivFig[];

  constructor(json: FxpOutput, options: DitaChildrenOptions = {}) {
    super(json, { ...options, key: DitaDivGroup.xmlKey });
    this.divFigs = this.children(DitaDivFig.xmlKey)
      .filter((fig) => DitaDivFig.precondition(fig))
      .map((fig) => DitaDivFig.cast(fig));
  }

  static override cast(data: Dita | DitaDiv) {
    return data as DitaDivGroup;
  }
}
