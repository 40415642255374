<mono-section
  *ngIf="data.section as section"
  [data]="section"
  monoShowDataClass
  [dataClass]="section"
></mono-section>
<mono-lc-intro
  *ngIf="data.lcIntro as lcIntro"
  [data]="lcIntro"
  monoShowDataClass
  [dataClass]="data"
></mono-lc-intro>
