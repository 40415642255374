<ng-container [ngSwitch]="data.isMonospace">
  <mono-tabular-spacing
    *ngSwitchCase="true"
    [data]="DitaTabularSpacingP.cast(data)"
  ></mono-tabular-spacing>
  <p
    *ngSwitchDefault
    monoShowDataClass
    [dataClass]="data"
    [innerHTML]="data.text() | safe : 'html'"
  ></p>
</ng-container>
